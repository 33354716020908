import type { ButtonProps } from '@twelvelabs/tds'

import * as Intercom from '@intercom/messenger-js-sdk'
import BookIcon from 'public/icons/book.svg'
import ContactSupportIcon from 'public/icons/contact-support.svg'
import DiscordOutlineIcon from 'public/icons/discord-outline.svg'
import HelpOutlineIcon from 'public/icons/help-outline.svg'

export type NavbarButtonType = {
	Icon: React.FC<React.SVGProps<SVGSVGElement>>
	name?: string
	label?: string
} & Omit<ButtonProps, 'type' | 'children' | 'onClick'> &
	(
		| {
				link: string
				onClick?: ButtonProps['onClick']
				menus?: never
		  }
		| {
				link?: never
				onClick: ButtonProps['onClick']
				menus?: never
		  }
		| {
				link?: never
				onClick?: ButtonProps['onClick']
				menus: NavbarButtonType[]
		  }
	)

const buttons: NavbarButtonType[] = [
	{
		Icon: BookIcon,
		label: 'API docs',
		link: process.env.NEXT_PUBLIC_DOCS_URL
	},
	{
		Icon: HelpOutlineIcon,
		name: 'help',
		label: 'Help',
		menus: [
			{
				Icon: ContactSupportIcon,
				name: 'intercom',
				label: 'Contact support',
				onClick: () => Intercom.show()
			},
			{
				Icon: DiscordOutlineIcon,
				label: 'Ask the community',
				link: process.env.NEXT_PUBLIC_DISCORD_URL
			}
		]
	}
]

export default buttons
