'use client'

import type { UsageProps } from '.'

import Skeleton from '@mui/material/Skeleton'
import { useSuspenseQuery } from '@tanstack/react-query'
import { usageOptions } from 'apis/billing'
import clsx from 'clsx'
import ProgressBar from 'components/ProgressBar'
import Tooltip from 'components/Tooltip'
import { capitalizeFirstLetter } from 'utils/helpers'
import withBoundary from 'utils/hocs/withBoundary'

import NumericUsage from './NumericUsage'

const indexLimitUsageTooltip: Record<string, string> = {
	'Free plan':
		'On the Free plan, you can index up to 10 hours of video. Deleting videos or indexes will not reduce your usage against this limit.',
	'Developer plan':
		'On the Developer plan, you can index up to 1,000 hours of video, which is a soft limit. Deleting videos or indexes will not reduce your usage against this limit.'
}

const UsageVertical = ({ warningPercentage, className, ...props }: UsageProps): JSX.Element => {
	const { data: usage } = useSuspenseQuery({
		...usageOptions(),
		select: ({ data }) => ({ ...data, plan_name: capitalizeFirstLetter(data.plan_name) })
	})

	return (
		<div className={clsx(className, 'flex flex-col gap-y-2')} {...props}>
			<div className={clsx('flex items-center gap-x-1')}>
				<p className={clsx('text-subtitle2')}>{usage.plan_name}</p>
				<Tooltip content={indexLimitUsageTooltip[usage.plan_name]} name="user_menu_plan" title={usage.plan_name} />
				<p className={clsx('text-body2 !text-grey-700', 'ml-auto')}>Used</p>
				<NumericUsage warningPercentage={warningPercentage} />
			</div>
			<ProgressBar data={usage.total_infra_usage_used} unit="seconds" limit={usage.infra_usage_limit} />
		</div>
	)
}

export default withBoundary(
	UsageVertical,
	{ fallbackRender: () => <p className={clsx('text-subtitle2')}>Unknown plan</p> },
	{
		fallbackRender: ({ className, ...props }) => (
			<div className={clsx(className, 'flex flex-col gap-y-2')} {...props}>
				<Skeleton variant={'subtitle2' as 'text'} width={80} />
				<Skeleton variant="rectangular" width="100%" height={32} />
			</div>
		)
	}
)
