import type { InputProps } from '@mui/material/Input'

import * as Intercom from '@intercom/messenger-js-sdk'
import FormControlLabel from '@mui/material/FormControlLabel'
import Input from '@mui/material/Input'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { Link } from '@twelvelabs/tds'
import clsx from 'clsx'
import { forwardRef, useEffect, useState } from 'react'

type DescriptionProps = {
	setDescription: (reason: string) => void
}

const Container = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={clsx(className, 'flex flex-col gap-y-6')} {...props} />
)

const MultilineInput = forwardRef<HTMLInputElement, InputProps>(({ className, ...props }, ref) => (
	<Input
		ref={ref}
		className={clsx(className, 'min-h-[220px] p-3', 'rounded border border-grey-400', 'items-start')}
		multiline
		fullWidth
		disableUnderline
		maxRows={4}
		{...props}
	/>
))

const CancelReasonBetterSolution = ({ setDescription }: DescriptionProps): JSX.Element => (
	<Container>
		<MultilineInput
			placeholder="Please tell us the name of products"
			onChange={(e): void => {
				setDescription(e.target.value)
			}}
		/>
	</Container>
)

const CancelReasonUseCase = ({ setDescription }: DescriptionProps): JSX.Element => (
	<Container>
		<p>
			We might have a way to support your use case. Please write in the box or{' '}
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
			<Link className={clsx('underline')} onClick={(): void => Intercom.showNewMessage('')}>
				contact us
			</Link>
			.
		</p>
		<MultilineInput
			placeholder="Please tell us the name of products"
			name="description"
			onChange={(e): void => {
				setDescription(e.target.value)
			}}
		/>
	</Container>
)

const CancelReasonFeaturesMissing = ({ setDescription }: DescriptionProps): JSX.Element => (
	<Container>
		<p>If you have other feature requests please let us know :) We&apos;ll let you know once the features are built.</p>
		<MultilineInput
			placeholder="Please explain features you would like to use"
			onChange={(e): void => {
				setDescription(e.target.value)
			}}
		/>
	</Container>
)

const reasonOptions = ['Indexing', 'Search', 'Classify', 'Other'] as const

const CancelReasonDifficultToUse = ({ setDescription }: DescriptionProps): JSX.Element => {
	const [reasonOption, setReasonOption] = useState<(typeof reasonOptions)[number] | null>(null)
	const [reasonDescription, setReasonDescription] = useState<string>('')

	useEffect(() => {
		setDescription(`${reasonOption}: ${reasonDescription}`)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reasonOption, reasonDescription])

	return (
		<Container>
			<RadioGroup
				row
				sx={{
					display: 'flex',
					justifyContent: 'space-between'
				}}
				onChange={(e): void => {
					setReasonOption(e.target.value as (typeof reasonOptions)[number] | null)
				}}
			>
				{reasonOptions.map((reason) => (
					<FormControlLabel key={reason} value={reason} control={<Radio />} label={reason} />
				))}
			</RadioGroup>
			<MultilineInput
				placeholder="Please specify"
				onChange={(e): void => {
					setReasonDescription(e.target.value)
				}}
			/>
		</Container>
	)
}

const CancelReasonOther = ({ setDescription }: DescriptionProps): JSX.Element => (
	<Container>
		<MultilineInput
			placeholder="Please specify"
			onChange={(e): void => {
				setDescription(e.target.value)
			}}
		/>
	</Container>
)

export {
	CancelReasonBetterSolution,
	CancelReasonUseCase,
	CancelReasonFeaturesMissing,
	CancelReasonDifficultToUse,
	CancelReasonOther
}
