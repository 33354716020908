// eslint-disable-next-line max-classes-per-file
import type {
	QueryKey,
	UseInfiniteQueryOptions,
	UseQueryOptions,
	UseSuspenseInfiniteQueryOptions,
	UseSuspenseQueryOptions
} from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import type { StatusCodes } from 'http-status-codes'

export interface DefaultResponse {
	_id: string
	created_at: string // Date format
	updated_at: string // Date format
}

export interface PageResponseFormat<Data, Type = string> {
	data: Data[]
	page_info: {
		limit_per_page: number
		page: number
		total_page: number
		total_results: number
	}
	type?: Type
}

export interface CachedPageResponseFormat<Data> {
	data: Data[]
	page_info: {
		limit_per_page: number
		prev_page_token?: string
		next_page_token?: string
		page_expired_at: string
		total_results: number
	}
}

export interface TraceId {
	trace_id: string // datadog trace id from header
}

export interface Sample {
	sample?: boolean // populate when data is from sample endpoint
}

export interface ExtraOptions {
	/**
	 * For debugging threshold
	 */
	extra_options?: string
}

export type UseCommonQueryOptions<
	TQueryFnData,
	TError = TwelveLabsApiError,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey
> = UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> &
	UseSuspenseQueryOptions<TQueryFnData, TError, TData, TQueryKey>

export type UseCommonInfiniteQueryOptions<
	TQueryFnData,
	TError = TwelveLabsApiError,
	TData = TQueryFnData,
	TQueryData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
	TPageParam = unknown
> = UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey, TPageParam> &
	UseSuspenseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey, TPageParam>

export type TwelveLabsApiErrorData<ErrorCode = never> = {
	code: ErrorCode | GeneralErrorCode
	message: string
}

export type TwelveLabsApiError<ErrorCode = never> = AxiosError<TwelveLabsApiErrorData<ErrorCode>>

export type IndexStatus = 'ready' | 'indexing' | 'validating' | 'queued' | 'pending' | 'failed'

export type Confidence = 'high' | 'medium' | 'low' | 'none'

export class FetchError extends Error {
	readonly code: GeneralErrorCode

	readonly status: StatusCodes

	// eslint-disable-next-line class-methods-use-this
	get isFetchError() {
		return true
	}

	constructor({ code, message, status }: { code: GeneralErrorCode; message: string; status: StatusCodes }) {
		super(message)
		this.code = code
		this.status = status
	}
}

export enum GeneralErrorCode {
	ApiKeyInvalid = 'api_key_invalid',
	ParameterInvalid = 'parameter_invalid',
	ParameterNotProvided = 'parameter_not_provided',
	ParameterUnknown = 'parameter_unknown',
	TagsNotAllowed = 'tags_not_allowed',
	EndpointNotExists = 'endpoint_not_exists',
	ResourceNotExists = 'resource_not_exists',
	ApiUpgradeRequired = 'api_upgrade_required',
	UnexpectedError = 'unexpected_error' // Internal server error with 500
}

export enum IndexErrorCode {
	IndexCannotBeDeleted = 'index_cannot_be_deleted',
	IndexDeletionInProgress = 'index_deletion_in_progress',
	IndexDuplicateEngineFamilyNotAllowed = 'index_duplicate_engine_family_not_allowed',
	IndexEngineCannotBeChanged = 'index_engine_cannot_be_changed',
	IndexEngineNotAllowed = 'index_engine_not_allowed',
	IndexNameAlreadyExists = 'index_name_already_exists',
	IndexOptionCannotBeChanged = 'index_option_cannot_be_changed',
	IndexOptionRequired = 'index_option_required'
}

export enum TaskErrorCode {
	TaskCannotBeDeleted = 'task_cannot_be_deleted',
	TranscriptionNotSupported = 'transcription_not_supported',
	TranscriptionUrlNotAccessible = 'transcription_url_not_accessible',
	TranscriptionUrlNotExists = 'transcription_url_not_exists',
	TooManyRequests = 'too_many_requests',
	UsageLimitExceeded = 'usage_limit_exceeded',
	VideoAudioTrackNotExists = 'video_audio_track_not_exists',
	VideoDurationTooLong = 'video_duration_too_long',
	VideoDurationTooShort = 'video_duration_too_short',
	VideoFileBroken = 'video_file_broken',
	VideoResolutionTooHigh = 'video_resolution_too_high',
	VideoResolutionTooLow = 'video_resolution_too_low',
	// external video error code (like Youtube)
	ExternalVideoDomainUnsupported = 'external_video_domain_unsupported',
	ExternalVideoInvalid = 'external_video_invalid',
	ExternalVideoLiveUnsupported = 'external_video_live_unsupported',
	ExternalVideoNotEmbeddable = 'external_video_not_embeddable',
	ExternalVideoUnavailable = 'external_video_unavailable',
	// client-side error code
	Canceled = 'canceled'
}

export enum SearchErrorCode {
	IndexNotSupportedSearch = 'index_not_supported_for_search',
	SearchFilterInvalid = 'search_filter_invalid',
	SearchOptionCombinationNotSupported = 'search_option_combination_not_supported',
	SearchOptionNotSupported = 'search_option_not_supported',
	SearchPageTokenExpired = 'search_page_token_expired',
	SearchParameterNotAllowed = 'search_parameter_not_allowed',
	SearchVideoDurationTooLong = 'search_video_duration_too_long',
	SearchVideoNotInSameIndex = 'search_video_not_in_same_index'
}

export enum ClassifyErrorCode {
	ClassifyNotSupportedEngine = 'classify_not_supported_engine',
	ClassifyOptionCombinationNotSupported = 'classify_option_combination_not_supported',
	ClassifyPageTokenExpired = 'classify_page_token_expired',
	ClassifyPageTokenInvalid = 'classify_page_token_invalid',
	ClassifyPromptsLimitExceeded = 'classify_prompts_limit_exceeded',
	IndexNotSupportedClassify = 'index_not_supported_for_classify'
}

export enum GenerateErrorCode {
	IndexNotSupportedGenerate = 'index_not_supported_for_generate',
	MaxOutputTokensLimit = 'max_output_tokens_limit'
}
