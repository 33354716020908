import type { TaskStatusAccordionProps } from './TaskStatusAccordion'
import type { TaskResponse, UploadTask } from 'apis/tasks'
import type { CompletedTask } from 'apis/tasks/store/store'
import type { IndexStatus } from 'utils/response'

import { useInfiniteQuery } from '@tanstack/react-query'
import { useWindowVirtualizer } from '@tanstack/react-virtual'
import { UploadStatus, infiniteTasksOptions, useCompletedTasks, useUploadTasks } from 'apis/tasks'
import clsx from 'clsx'
import { useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import withBoundary, { ErrorFallback } from 'utils/hocs/withBoundary'

import { IndexingTaskStatusItem, TaskStatusItemSkeleton, UploadingTaskStatusItem } from './TaskStatusItem'

const useTasksInOrder = (indexId: string) => {
	const uploadTasksByStatus = useUploadTasks((state) =>
		state
			.filter((task) => task.indexId === indexId)
			.reduce<Record<UploadStatus, UploadTask[]>>(
				(acc, task) => {
					acc[task.status].push(task)
					return acc
				},
				{
					[UploadStatus.Failed]: [],
					[UploadStatus.Done]: [],
					[UploadStatus.Queued]: [],
					[UploadStatus.Uploading]: []
				}
			)
	)

	const completedTasksByStatus = useCompletedTasks(
		(state) =>
			state[indexId]?.reduce<Record<Extract<IndexStatus, 'ready' | 'failed'>, CompletedTask[]>>(
				(acc, task) => {
					acc[task.status].push(task)
					return acc
				},
				{ ready: [], failed: [] }
			) || { ready: [], failed: [] }
	)

	const validatingTasksRes = useInfiniteQuery({
		...infiniteTasksOptions({ index_id: indexId, page_limit: 12, status: 'validating' }),
		select: (res) => res.pages.flatMap(({ data }) => data.data),
		throwOnError: true
	})
	const queuedTasksRes = useInfiniteQuery({
		...infiniteTasksOptions({ index_id: indexId, page_limit: 12, status: 'queued' }),
		select: (res) => res.pages.flatMap(({ data }) => data.data),
		enabled: validatingTasksRes.error != null || !validatingTasksRes.hasNextPage,
		throwOnError: true
	})
	const pendingTasksRes = useInfiniteQuery({
		...infiniteTasksOptions({ index_id: indexId, page_limit: 12, status: 'pending' }),
		select: (res) => res.pages.flatMap(({ data }) => data.data),
		enabled: queuedTasksRes.error != null || !queuedTasksRes.hasNextPage,
		throwOnError: true
	})
	const indexingTasksRes = useInfiniteQuery({
		...infiniteTasksOptions({ index_id: indexId, page_limit: 12, status: 'indexing' }),
		select: (res) => res.pages.flatMap(({ data }) => data.data),
		enabled: pendingTasksRes.error != null || !pendingTasksRes.hasNextPage,
		throwOnError: true
	})

	const hasNextPage =
		validatingTasksRes.hasNextPage ||
		queuedTasksRes.hasNextPage ||
		pendingTasksRes.hasNextPage ||
		indexingTasksRes.hasNextPage

	const fetchNextPage = useCallback(() => {
		if (validatingTasksRes.hasNextPage) validatingTasksRes.fetchNextPage()
		else if (queuedTasksRes.hasNextPage) queuedTasksRes.fetchNextPage()
		else if (pendingTasksRes.hasNextPage) pendingTasksRes.fetchNextPage()
		else if (indexingTasksRes.hasNextPage) indexingTasksRes.fetchNextPage()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		indexingTasksRes.hasNextPage,
		pendingTasksRes.hasNextPage,
		queuedTasksRes.hasNextPage,
		validatingTasksRes.hasNextPage
	])

	const { ref: loadingRef } = useInView({
		rootMargin: '200px',
		onChange(inView) {
			if (!inView) return
			fetchNextPage()
		}
	})

	return {
		loadingRef,
		hasNextPage,
		tasks: [
			...uploadTasksByStatus[UploadStatus.Failed],
			...completedTasksByStatus.failed,
			...uploadTasksByStatus[UploadStatus.Queued],
			...uploadTasksByStatus[UploadStatus.Uploading],
			...(validatingTasksRes.data ?? []),
			...(queuedTasksRes.data ?? []),
			...(pendingTasksRes.data ?? []),
			...(indexingTasksRes.data ?? []),
			...(!hasNextPage ? completedTasksByStatus.ready : [])
		]
	}
}

const getTaskId = (task: UploadTask | TaskResponse | CompletedTask) => {
	if ('taskId' in task) return task.taskId
	if ('_id' in task) return task._id
	return task.id
}

const TaskStatusAccordionContent = ({ indexId }: TaskStatusAccordionProps) => {
	const { loadingRef, hasNextPage, tasks } = useTasksInOrder(indexId)

	const windowVirtualizer = useWindowVirtualizer({
		count: tasks.length,
		overscan: 3,
		estimateSize: () => 32,
		gap: 12
	})

	return (
		<div className={clsx('w-full py-6', 'border-b border-grey-200')}>
			<div
				className={clsx('w-full px-6', 'flex flex-col gap-y-3', 'relative')}
				style={{ height: `${windowVirtualizer.getTotalSize()}px` }}
			>
				{windowVirtualizer.getVirtualItems().map((row) => {
					const task = tasks[row.index]
					return (
						<div
							ref={windowVirtualizer.measureElement}
							key={getTaskId(task)}
							data-index={row.index}
							className={clsx('w-full')}
						>
							{'id' in task ? (
								<UploadingTaskStatusItem {...task} />
							) : (
								<IndexingTaskStatusItem taskId={getTaskId(task)} />
							)}
						</div>
					)
				})}
			</div>
			{hasNextPage && (
				<div ref={loadingRef} className={clsx('w-full px-6', 'flex flex-col gap-y-3', tasks.length > 0 && 'mt-3')}>
					<TaskStatusItemSkeleton />
				</div>
			)}
		</div>
	)
}

export default withBoundary(TaskStatusAccordionContent, {
	fallbackRender: ({ error, resetErrorBoundary }) => (
		<ErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} className={clsx('!h-fit')} />
	)
})
