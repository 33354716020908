import Container from '@mui/material/Container'
import clsx from 'clsx'

interface Props {
	children?: React.ReactNode
}

const ContentLayout = ({ children }: Props): JSX.Element => (
	<Container className={clsx('flex flex-col gap-y-4', '!px-5 !py-6')} maxWidth="xl">
		{children}
	</Container>
)

export default ContentLayout
