import { StatusCodes } from 'http-status-codes'
import ErrorPage from 'pages/_error'
import useAuth0 from 'utils/hooks/useAuth0'

const UnauthorizedErrorPage = () => {
	const { logout } = useAuth0()

	return (
		<ErrorPage
			statusCode={StatusCodes.UNAUTHORIZED}
			title="Your account is invalid"
			message="To use the Twelve Labs Playground, please check your account is valid or login with different account."
			action={{
				label: 'Logout',
				onClick: () => logout({ returnTo: window.location.origin })
			}}
		/>
	)
}

export default UnauthorizedErrorPage
