import muiSvg from './muiSvg'

const VisualSound = muiSvg((props) => (
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_860_9396)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.3007 11.4626L14.2998 11.4626C14.28 9.39325 12.5864 7.7218 10.5 7.7218C8.41922 7.7218 6.72906 9.38429 6.70038 11.4459L6.69852 11.4459C6.6995 11.4636 6.7 11.4814 6.7 11.4993C6.7 11.5381 6.69765 11.5765 6.69308 11.6142L6.70173 11.6143C6.76291 13.6473 8.44003 15.2767 10.5 15.2767C12.5534 15.2767 14.2265 13.6576 14.2976 11.6336L14.3095 11.6336C14.3032 11.5897 14.3 11.5448 14.3 11.4992C14.3 11.487 14.3002 11.4748 14.3007 11.4626ZM10.6377 20C13.4676 20 15.813 18.7316 17.4486 17.0493L14 14.0003L19.5111 9.12773C18.3286 6.15452 15.08 3 10.6377 3C4.99275 3 1 8.25283 1 11.4364C1 11.4371 1.00064 11.4378 1.00192 11.4385C1.00065 11.4586 1 11.4789 1 11.4993V11.5007V11.5007C1 14.6551 4.99275 20 10.6377 20ZM19.9155 10.5213L19.9155 10.5212C19.8991 10.5064 19.8826 10.4915 19.8659 10.4768L18.7273 11.4835L18.7273 11.4835L19.8659 10.4768C19.8826 10.4916 19.8991 10.5064 19.9155 10.5213ZM10.5 13.388C11.5493 13.388 12.4 12.5424 12.4 11.4993C12.4 10.4561 11.5493 9.61053 10.5 9.61053C9.45066 9.61053 8.6 10.4561 8.6 11.4993C8.6 12.5424 9.45066 13.388 10.5 13.388ZM15.8807 14.0003L15.8806 14.0003L17.5886 12.4902C17.7003 12.5889 17.7987 12.6949 17.884 12.8063C17.9345 12.8724 17.9805 12.9405 18.0218 13.0101C17.9109 12.8231 17.7665 12.6475 17.5886 12.4903L15.8807 14.0003ZM20.6295 9.90909L21.6583 9C24.7806 11.7636 24.7806 16.2409 21.6583 19L20.6295 18.0909C23.186 15.8318 23.186 12.1682 20.6295 9.90909ZM16 14L17.5432 12.6364C18.397 13.3909 18.397 14.6136 17.5432 15.3636L16 14ZM18.5719 11.7273L19.6007 10.8182C21.5862 12.5773 21.5862 15.4273 19.6007 17.1818L18.5719 16.2727C19.9916 15.0182 19.9916 12.9818 18.5719 11.7273Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_860_9396">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
))

export default VisualSound
