import Add from '@mui/icons-material/Add'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Button } from '@twelvelabs/tds'
import clsx from 'clsx'
import React from 'react'

interface Props {
	disabled: boolean
	onCreateIndexClick: () => void
}

const CreateIndexCTAButton = ({ onCreateIndexClick, disabled }: Props) => (
	<>
		<Button
			className={clsx(
				'h-9 w-full tablet:w-[200px]',
				'justify-between bg-white',
				'!px-2',
				'border',
				disabled ? '!border-grey-300' : '!border-red-500'
			)}
			type="button"
			appearance="secondary"
			onClick={onCreateIndexClick}
		>
			<div className="flex gap-1">
				<Add fontSize="small" /> Create index
			</div>
			<ArrowDropDownIcon />
		</Button>
		{!disabled && <p className="text-body3 !text-red-500">Please create an index first</p>}
	</>
)

export default CreateIndexCTAButton
