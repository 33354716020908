import clsx from 'clsx'
import CancelIcon from 'public/icons/cancel.svg'
import React from 'react'

const StatusBadgeWithClearButton = ({
	badge,
	cancel,
	className
}: {
	badge:
		| JSX.Element
		| { icon: React.FC<React.SVGProps<SVGSVGElement>>; label: string; appearance?: 'error' | 'default' }
	cancel?: { label: string; onClick: () => void }
	className?: string
}) => (
	<>
		{'icon' in badge ? (
			<div
				className={clsx(
					'px-2 py-1',
					'flex items-center gap-x-1',
					'rounded',
					badge.appearance === 'error' ? 'bg-red-50' : 'bg-grey-100',
					cancel != null && 'group-hover/task-status:hidden',
					className
				)}
			>
				<badge.icon className={clsx('h-5 w-5', badge.appearance === 'error' ? 'text-red-500' : 'text-grey-600')} />
				<p
					className={clsx(
						'whitespace-nowrap text-body3 !font-medium',
						badge.appearance === 'error' ? '!text-red-700' : '!text-grey-700'
					)}
				>
					{badge.label}
				</p>
			</div>
		) : (
			React.cloneElement(badge, {
				className: clsx('group-hover/task-status:hidden', badge.props.className, className)
			})
		)}
		{cancel != null && (
			<button
				type="button"
				onClick={cancel.onClick}
				className={clsx('py-1 pl-2', 'hidden items-center gap-x-1 group-hover/task-status:flex', className)}
			>
				<p className={clsx('whitespace-nowrap text-body2 !text-grey-700')}>{cancel.label}</p>
				<CancelIcon className={clsx('h-6 w-6 text-grey-500')} />
			</button>
		)}
	</>
)

export default StatusBadgeWithClearButton
