import { Engine, EngineFamily, type EngineData } from 'apis/indexes'

// eslint-disable-next-line import/prefer-default-export
export const getCanUpload = (engines: EngineData[]): boolean => {
	const engineNames = engines.map((e) => e.engine_name).join(',')

	// Block uploading for Pegasus family + Marengo2.5 combo index
	const shouldBlock = engineNames.includes(EngineFamily.PEGASUS) && engineNames.includes(Engine.MARENGO_2_5)

	return !shouldBlock
}
