import muiSvg from './muiSvg'

const CheckedRounded = muiSvg((props) => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM8.5 14.0957L15.6783 7.46961L14.3217 6L8.5 11.3739L5.67828 8.76923L4.32172 10.2388L8.5 14.0957Z"
			fill="currentColor"
		/>
	</svg>
))

export default CheckedRounded
