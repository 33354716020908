import type { Engine, EngineData, EngineFamily } from 'apis/indexes'

import { isPegasus } from 'apis/indexes'
import Marengo from 'components/svg/Marengo'
import Pegasus from 'components/svg/Pegasus'

interface Props extends React.SVGProps<SVGSVGElement> {
	engine_name: Engine | EngineFamily
}

const EngineLogo = ({ engine_name, ...props }: Props): JSX.Element =>
	isPegasus({ engine_name } as EngineData) ? <Pegasus {...props} /> : <Marengo {...props} />

export default EngineLogo
