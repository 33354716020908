import type { AxiosAdapter, AxiosInstance, AxiosRequestConfig } from 'axios'

import axios from 'axios'
import { throttleAdapterEnhancer } from 'axios-extensions'

import { authorizationInterceptor } from './interceptors'

axios.defaults.withCredentials = true

function createInstance(
	options: Omit<AxiosRequestConfig, 'adapter'>,
	{ authorization = true }: { authorization?: boolean } = {}
): AxiosInstance {
	const instance = axios.create({
		...options,
		adapter: throttleAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, { threshold: 500 })
	})

	if (authorization) authorizationInterceptor(instance)

	return instance
}

export enum ApiType {
	TWELVE_LABS = 'twelvelabs',
	INTERNAL = 'internal',
	ROUTE = 'route',
	PLAYGROUND = 'playground',
	SAMPLE = 'sample'
}

const instanceConfigs: Record<ApiType, Parameters<typeof createInstance>> = {
	[ApiType.TWELVE_LABS]: [
		{
			baseURL: `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_VERSION}`,
			headers: { 'tl-origin': 'playground' }
		}
	],
	[ApiType.INTERNAL]: [{ baseURL: `${process.env.NEXT_PUBLIC_API_BASE_URL}/tl` }],
	[ApiType.ROUTE]: [{ baseURL: '/api' }, { authorization: false }],
	[ApiType.PLAYGROUND]: [
		{
			baseURL: `${process.env.NEXT_PUBLIC_API_BASE_URL}/tl/playground`,
			headers: { 'tl-origin': 'playground' }
		}
	],
	[ApiType.SAMPLE]: [
		{
			baseURL: `${process.env.NEXT_PUBLIC_API_BASE_URL}/tl/playground/samples/${process.env.NEXT_PUBLIC_API_VERSION}`,
			headers: { 'tl-origin': 'playground' }
		}
	]
}

const instances = {} as Record<ApiType, AxiosInstance | undefined>
export const getInstance = (key: ApiType): AxiosInstance => {
	if (!instances[key]) {
		instances[key] = createInstance(...instanceConfigs[key])
	}
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return instances[key]!
}
