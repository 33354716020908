import { Skeleton } from '@mui/material'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Button } from '@twelvelabs/tds'
import { usageOptions } from 'apis/billing'
import clsx from 'clsx'
import ReplayIcon from 'components/svg/Replay'
import { USAGE_WARNING_THRESHOLD, calcUsageNumbers } from 'utils/billing'
import withBoundary from 'utils/hocs/withBoundary'

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
	warningPercentage?: number
}

const NumericUsage = ({ warningPercentage = USAGE_WARNING_THRESHOLD, className, ...props }: Props): JSX.Element => {
	const { data: usage } = useSuspenseQuery(usageOptions())

	const { limitInMin, usedInMin, usedInPercentage } = calcUsageNumbers(usage)

	return (
		<p className={clsx(className, 'text-body2')} {...props}>
			<span
				className={clsx(
					'font-medium',
					// eslint-disable-next-line no-nested-ternary
					usedInPercentage >= warningPercentage
						? usage.is_hard_limit
							? 'text-red-500'
							: 'text-secondary'
						: 'text-grey-1000'
				)}
			>
				{usedInMin} min
			</span>
			&nbsp;/&nbsp;{limitInMin} min
		</p>
	)
}

export default withBoundary(
	NumericUsage,
	{
		fallbackRender: ({ resetErrorBoundary }): JSX.Element => (
			<div className={clsx('flex items-center gap-x-2')}>
				<p className={clsx('text-body2')}>Something wrong with get usage :/</p>
				<Button appearance="secondary" onClick={resetErrorBoundary}>
					<ReplayIcon color="inherit" />
					retry
				</Button>
			</div>
		)
	},
	{
		fallbackRender: ({ className }) => <Skeleton variant={'body2' as 'text'} className={className} />
	}
)
