import clsx from 'clsx'
import Tooltip, { type TooltipProps } from 'components/Tooltip'
import React from 'react'

interface FormLabelProps extends React.HTMLAttributes<HTMLDivElement> {
	className?: string
	children: React.ReactNode
	label: string
	tooltip?: TooltipProps
}

const FormLabel = ({ label, children, className, tooltip, ...props }: FormLabelProps): JSX.Element => (
	<div className={clsx(className, 'flex flex-col')} {...props}>
		<div className={clsx('flex items-center', 'gap-x-2', 'mb-3')}>
			<p className={clsx('my-0 text-subtitle2 font-medium text-grey-1100')}>{label}</p>
			{tooltip && <Tooltip title={label} {...tooltip} />}
		</div>
		{children}
	</div>
)

export default FormLabel
