import type { QueuedUploadingTask, UploadingTask } from 'apis/tasks'

import Close from '@mui/icons-material/Close'
import { UploadStatus } from 'apis/tasks'
import clsx from 'clsx'

import { DynamicButton, UploadProgress } from '../common'

const UploadingStatus = ({ task }: { task: QueuedUploadingTask | UploadingTask }) => (
	<>
		{/* desktop */}
		<DynamicButton className="hidden md:block" onClick={task.cancel}>
			<UploadProgress value={task.status === UploadStatus.Uploading ? task.progress : undefined} />
		</DynamicButton>

		{/* mobile */}
		<div className="flex items-center gap-x-2 md:hidden">
			<UploadProgress value={task.status === UploadStatus.Uploading ? task.progress : undefined} />
			<button type="button" onClick={task.cancel} className={clsx('text-grey-600')}>
				<Close fontSize="small" color="inherit" />
			</button>
		</div>
	</>
)

export default UploadingStatus
