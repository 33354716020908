import type { UploadTasksStore } from './store'
import type { DoneTask, FailedUploadingTask, QueuedUploadingTask, UploadTask, UploadingTask } from '../types'

import { UploadStatus } from '../types'
import { isFileType } from '../utils'

function getBatchSize<T extends UploadTask>(
	tasks: T[],
	concurrencyNumberLimit: number,
	concurrencyFileSizeLimit: number
) {
	let [batchSize, totalFileSize] = [0, 0]

	// eslint-disable-next-line no-restricted-syntax
	for (const task of tasks) {
		if (isFileType(task)) {
			totalFileSize += task.file.size
			if (totalFileSize > concurrencyFileSizeLimit) {
				break
			}
		}
		batchSize += 1
		if (batchSize >= concurrencyNumberLimit) {
			break
		}
	}

	return batchSize
}

export function selectQueuedTasks(
	{ uploadTasks: data }: UploadTasksStore,
	{
		concurrencyNumberLimit,
		concurrencyFileSizeLimit
	}: { concurrencyNumberLimit: number; concurrencyFileSizeLimit: number }
): QueuedUploadingTask[] {
	const tasksByStatus = data.reduce(
		(acc, task) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			acc[task.status].unshift(task as any)
			return acc
		},
		{
			[UploadStatus.Queued]: [] as QueuedUploadingTask[],
			[UploadStatus.Uploading]: [] as UploadingTask[],
			[UploadStatus.Done]: [] as DoneTask[],
			[UploadStatus.Failed]: [] as FailedUploadingTask[]
		}
	)

	const totalUploadingFileSize = tasksByStatus[UploadStatus.Uploading].reduce(
		(acc, task) => acc + (isFileType(task) ? task.file.size : 0),
		0
	)

	const batchSize = getBatchSize(
		tasksByStatus[UploadStatus.Queued],
		concurrencyNumberLimit - tasksByStatus[UploadStatus.Uploading].length,
		concurrencyFileSizeLimit - totalUploadingFileSize
	)

	return tasksByStatus[UploadStatus.Queued].slice(0, batchSize)
}

export const readSettledDataWithFallback = <T>(data: PromiseSettledResult<T>, fallback: T) =>
	data.status === 'fulfilled' ? data.value : fallback
