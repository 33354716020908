import React from 'react'

import PageLayoutContent from './PageLayoutContent'
import PageLayoutSide from './PageLayoutSide'
import PageLayoutTop from './PageLayoutTop'

const PageLayout = ({ children }: { children: React.ReactNode }) => (
	<main id="page-layout" className="flex flex-col tablet:flex-row">
		{children}
	</main>
)

PageLayout.Side = PageLayoutSide
PageLayout.Top = PageLayoutTop
PageLayout.Content = PageLayoutContent

export default PageLayout
