import clsx from 'clsx'

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
	label: React.ReactNode
	widget?: React.ReactNode
	children?: React.ReactNode
}

const Section = ({ label: labelProp, widget, className, children, ...props }: SectionProps): JSX.Element => (
	<div className={clsx(className, 'h-full w-full')} {...props}>
		<div className={clsx('flex items-center justify-between', 'mb-5')}>
			{typeof labelProp === 'string' ? <p className="text-heading7 !text-grey-900">{labelProp}</p> : labelProp}
			{widget}
		</div>
		{children}
	</div>
)

export default Section
