import muiSvg from './muiSvg'

const Close = muiSvg(
	(props): JSX.Element => (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 13.4142L18.7929 20.2071L20.2071 18.7929L13.4142 12L20.2071 5.20711L18.7929 3.79289L12 10.5858L5.20711 3.79289L3.79289 5.20711L10.5858 12L3.79291 18.7929L5.20712 20.2071L12 13.4142Z"
				fill="currentColor"
			/>
		</svg>
	)
)

export default Close
