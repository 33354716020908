'use client'

import clsx from 'clsx'
import { pathName } from 'constants/paths'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import TWLogoIcon from 'public/icons/tw-logo.svg'

interface Props {
	expanded: boolean
}

const LogoButtonWithBeta = ({ expanded }: Props) => {
	const onLogoClick = () => {
		mixpanel.track('click', { type: 'link', element: 'navbar_logo', url: pathName.OVERVIEW })
	}

	return (
		<div className="flex items-center gap-x-2">
			<Link
				className={clsx('transition-all duration-300', expanded ? 'pl-0' : 'pl-[6px]')}
				href={pathName.OVERVIEW}
				onClick={onLogoClick}
			>
				<TWLogoIcon className="h-7 w-7" />
			</Link>
			<div
				className={clsx(
					'px-1 py-0.5',
					'bg-green-50',
					'opacity-0 transition-opacity duration-300',
					expanded && 'opacity-100'
				)}
			>
				<p className={clsx('text-subtitle3 !text-green-900')}>Beta</p>
			</div>
		</div>
	)
}

export default LogoButtonWithBeta
