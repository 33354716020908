'use client'

import { UserProvider as Auth0UserProvider } from '@auth0/nextjs-auth0/client'
import axios, { type AxiosAdapter } from 'axios'
import { throttleAdapterEnhancer } from 'axios-extensions'
import React from 'react'

const axiosInstance = axios.create({
	adapter: throttleAdapterEnhancer(axios.defaults.adapter as AxiosAdapter)
})

const UserProvider = ({ children }: { children: React.ReactNode }): JSX.Element => (
	<Auth0UserProvider fetcher={(url) => axiosInstance.get(url).then((res) => res.data)}>{children}</Auth0UserProvider>
)

export default UserProvider
