import Close from '@mui/icons-material/Close'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Link } from '@twelvelabs/tds'
import { usageOptions } from 'apis/billing'
import { useUpdateUserMetadata } from 'apis/user'
import clsx from 'clsx'
import mixpanel from 'mixpanel-browser'
import { useState } from 'react'
import withBoundary from 'utils/hocs/withBoundary'
import useAuth0 from 'utils/hooks/useAuth0'

const HARD_LIMIT_ALERT_FLAG = 'view_hard_limit_alert'

const pricingURL = `${process.env.NEXT_PUBLIC_COMPANY_SITE_URL}/pricing`

const FreePlanAlert = ({
	className,
	...props
}: Omit<React.HTMLAttributes<HTMLDivElement>, 'sx'>): JSX.Element | null => {
	const updateUserMetadata = useUpdateUserMetadata()
	const { userMetadata, checkSession } = useAuth0()

	const didUserViewAlert = userMetadata ? Boolean(userMetadata[HARD_LIMIT_ALERT_FLAG]) : false

	const [hideAlert, setHideAlert] = useState<boolean>(didUserViewAlert)
	const { data: isHardLimit } = useSuspenseQuery({ ...usageOptions(), select: (res) => res.data.is_hard_limit })

	const closeAlert = async () => {
		setHideAlert(true)
		await updateUserMetadata.mutateAsync({ [HARD_LIMIT_ALERT_FLAG]: true })
		mixpanel.track('click', { type: 'button', element: 'free_plan_alert', value: 'close' })
		checkSession()
	}

	if (!isHardLimit || hideAlert) return null

	return (
		<div
			className={clsx(className, 'flex tablet:items-center', 'px-3 py-2.5', 'border border-green-500 bg-green-50')}
			{...props}
		>
			<InfoOutlined fontSize="small" color="inherit" className={clsx('text-primary')} />
			<div className={clsx('flex flex-col justify-between tablet:flex-row tablet:items-center', 'w-full', 'ml-2')}>
				<p className={clsx('ml-1 mr-auto', 'text-body2 text-grey-1000')}>
					You are currently on the Free Plan, which means that your index will expire 90 days after it was created.
				</p>
				<Link
					target="_blank"
					href={pricingURL}
					onClick={() => mixpanel.track('link', { type: 'button', element: 'free_plan_alert', url: pricingURL })}
					className={clsx('text-subtitle3', 'mr-1')}
				>
					Learn more
				</Link>
			</div>
			<IconButton size="small" color="secondary" onClick={closeAlert}>
				<Close fontSize="inherit" />
			</IconButton>
		</div>
	)
}

export default withBoundary(FreePlanAlert)
