import type { UsageProps } from '.'

import Skeleton from '@mui/material/Skeleton'
import { useSuspenseQuery } from '@tanstack/react-query'
import { usageOptions } from 'apis/billing'
import clsx from 'clsx'
import ProgressBar from 'components/ProgressBar'
import Routes from 'constants/routes'
import Link from 'next/link'
import { capitalizeFirstLetter } from 'utils/helpers'
import withBoundary from 'utils/hocs/withBoundary'

import NumericUsage from './NumericUsage'

const UsageHorizontal = ({ warningPercentage = 80, className, ...props }: UsageProps): JSX.Element | null => {
	const { data: usage } = useSuspenseQuery({
		...usageOptions(),
		select: ({ data }) => ({ ...data, plan_name: capitalizeFirstLetter(data.plan_name) })
	})

	const percentage = Math.min(100, Math.floor((usage.total_infra_usage_used / usage.infra_usage_limit) * 100))

	return (
		<div className={clsx('flex items-center gap-x-2', className)} {...props}>
			<p className={clsx('text-subtitle2')}>{usage.plan_name}</p>
			<NumericUsage warningPercentage={warningPercentage} />
			<ProgressBar
				data={usage.total_infra_usage_used}
				unit="seconds"
				limit={usage.infra_usage_limit}
				className={clsx('w-[200px]')}
			/>
			<p className={clsx('text-body2')}>{percentage}%</p>
			{percentage >= warningPercentage && (
				<Link className={clsx('text-subtitle3')} href={Routes.BILLING} rel="noreferrer">
					Upgrade
				</Link>
			)}
		</div>
	)
}

export default withBoundary(
	UsageHorizontal,
	{ fallbackRender: ({ className }) => <p className={clsx('text-subtitle2', className)}>Unknown Plan</p> },
	{
		fallbackRender: ({ className, ...props }) => (
			<div className={clsx('flex flex-col gap-y-2', className)} {...props}>
				<Skeleton variant={'subtitle2' as 'text'} width={80} />
				<Skeleton variant="rectangular" width={240} height={20} />
			</div>
		)
	}
)
