import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

export type IndexType = 'my' | 'sample'
const indexTypes: IndexType[] = ['my', 'sample']

interface IndexTypeTabsProps extends React.HTMLAttributes<HTMLDivElement> {
	type: IndexType
	onTypeChange: (indexType: IndexType) => void
}

const IndexTypeTabs = ({ type, onTypeChange, className, ...props }: IndexTypeTabsProps): JSX.Element => {
	const [activeTabIndex, setActiveTabIndex] = useState(0)

	useEffect(() => setActiveTabIndex(indexTypes.findIndex((v) => v === type)), [type])

	const tabRefs = useRef<HTMLButtonElement[]>([])
	const [activeTabPos, setActiveTabPos] = useState<{ width: number; left: number }>({ width: 0, left: 0 })
	useEffect(() => {
		const setTabPosition = () => {
			const activeTab = tabRefs.current[activeTabIndex]
			setActiveTabPos({ width: activeTab.clientWidth, left: activeTab.offsetLeft })
		}
		setTabPosition()
		window.addEventListener('resize', setTabPosition)

		return () => window.removeEventListener('resize', setTabPosition)
	}, [activeTabIndex])

	return (
		<div className={clsx(className, 'relative', 'flex items-start gap-x-5')} {...props}>
			{indexTypes.map((indexType, index) => {
				const isSelected = activeTabIndex === index
				return (
					<button
						key={indexType}
						ref={(e) => {
							if (e == null) return
							tabRefs.current[index] = e
						}}
						type="button"
						disabled={isSelected}
						onClick={() => onTypeChange(indexType)}
						className={clsx(
							'text-subtitle2 !font-bold',
							'pb-2',
							isSelected ? 'text-grey-1100' : 'text-grey-500 hover:text-grey-800'
						)}
					>
						<span className={clsx('capitalize')}>{indexType}</span> videos
					</button>
				)
			})}
			<div
				className={clsx('absolute bottom-0 left-0', 'w-24', 'border-b-4 border-primary', 'transition-all duration-300')}
				style={{ width: activeTabPos.width, left: activeTabPos.left }}
			/>
		</div>
	)
}

export default IndexTypeTabs
