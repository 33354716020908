import type { TaskErrorCode, TwelveLabsApiErrorData } from 'utils/response'

import ErrorRounded from '@mui/icons-material/ErrorRounded'
import { errorByCode } from 'apis/tasks'
import clsx from 'clsx'
import Tooltip from 'components/Tooltip'

import CapitalizedLabel from './CapitalizedLabel'

const UploadErrorMessage = ({ error }: { error: TwelveLabsApiErrorData<TaskErrorCode> }) => (
	<Tooltip content={error.message} title={error.code} placement="top">
		<div className={clsx('flex items-center')}>
			<CapitalizedLabel>{errorByCode(error.code)}</CapitalizedLabel>
			<ErrorRounded fontSize="small" color="error" />
		</div>
	</Tooltip>
)

export default UploadErrorMessage
