import Close from '@mui/icons-material/Close'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { IconButton } from '@mui/material'
import Snackbar, { snackbarClasses } from '@mui/material/Snackbar'
import { Button } from '@twelvelabs/tds'
import { UploadStatus, useClearUploadTasks, useUploadTasks } from 'apis/tasks'
import clsx from 'clsx'
import noop from 'lodash/noop'
import { useState } from 'react'

import CancelAllUploadDialog from './CancelAllUploadDialog'
import UploadProgressContainer from './UploadProgressContainer'
import UploadProgressItem from './UploadProgressItem'

const UploadProgressSnackbarDesktop = () => {
	const uploadTasks = useUploadTasks()
	const clearUploadTasks = useClearUploadTasks()

	const [expand, setExpand] = useState(true)
	const toggleExpand = () => setExpand((prev) => !prev)

	const [isCancelAllDialogOpen, setOpenCancelAllDialog] = useState(false)
	const openCancelAllDialog = () => setOpenCancelAllDialog(true)
	const closeCancelAllDialog = () => setOpenCancelAllDialog(false)

	const close = () => {
		clearUploadTasks({ statuses: [UploadStatus.Done, UploadStatus.Failed] })
	}

	const uploadTasksInProgress = uploadTasks.filter((task) =>
		[UploadStatus.Queued, UploadStatus.Uploading].includes(task.status)
	)
	const hasUploadTasksInProgress = uploadTasksInProgress.length > 0

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				open={!isCancelAllDialogOpen && uploadTasks.length > 0}
				onClose={noop}
				sx={{
					[`&.${snackbarClasses.root}`]: {
						bottom: '84px'
					}
				}}
			>
				<UploadProgressContainer>
					<div className={clsx('flex items-center justify-between')}>
						<p className={clsx('text-subtitle2')}>
							{hasUploadTasksInProgress
								? `Uploading ${uploadTasksInProgress.length} video${uploadTasksInProgress.length > 1 ? 's' : ''}`
								: 'Upload complete'}
						</p>
						<IconButton
							onClick={hasUploadTasksInProgress ? toggleExpand : close}
							className={clsx('-mr-[14px] p-1 text-grey-500')}
						>
							{hasUploadTasksInProgress ? (
								<KeyboardArrowDown
									fontSize="medium"
									color="inherit"
									className={clsx(expand ? 'rotate-180' : 'rotate-0', 'transition-transform')}
								/>
							) : (
								<Close fontSize="medium" color="inherit" />
							)}
						</IconButton>
					</div>
					{(!hasUploadTasksInProgress || expand) && (
						<>
							{hasUploadTasksInProgress && (
								<Button type="button" className={clsx('mt-4 w-full')} onClick={openCancelAllDialog}>
									Cancel all
								</Button>
							)}
							<div className={clsx('mt-6 max-h-[168px] overflow-y-auto', 'flex flex-col gap-y-2')}>
								{uploadTasks.map((task) => (
									<UploadProgressItem key={task.id} task={task} />
								))}
							</div>
						</>
					)}
				</UploadProgressContainer>
			</Snackbar>
			<CancelAllUploadDialog open={isCancelAllDialogOpen} onClose={closeCancelAllDialog} />
		</>
	)
}

export default UploadProgressSnackbarDesktop
