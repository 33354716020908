import muiSvg from './muiSvg'

const SearchLeft = muiSvg(
	(props): JSX.Element => (
		<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 11C20 7.13401 16.866 4 13 4C9.13401 4 6 7.13401 6 11C6 14.866 9.13401 18 13 18C16.866 18 20 14.866 20 11ZM13 2C17.9706 2 22 6.02944 22 11C22 15.9706 17.9706 20 13 20C10.2611 20 7.8079 18.7765 6.1572 16.8463L2.66347 19.9444L1.33653 18.448L5.02571 15.1766C4.37062 13.9284 4 12.5075 4 11C4 6.02944 8.02944 2 13 2Z"
				fill="currentColor"
			/>
		</svg>
	)
)

export default SearchLeft
