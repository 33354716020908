import useIsMobile from 'utils/hooks/useIsMobile'
import { UploadProgressSnackbarDesktop } from 'widgets/UploadProgressSnackbar'

import IndexTaskCreateDialog from './IndexTaskCreateDialog'
import UsageLimitAlertDialog from './UsageLimitAlertDialog'

const IndexTaskCreate = (): JSX.Element => {
	const isMobile = useIsMobile()

	return (
		<>
			<IndexTaskCreateDialog />
			<UsageLimitAlertDialog />
			{!isMobile && <UploadProgressSnackbarDesktop />}
		</>
	)
}

export default IndexTaskCreate
