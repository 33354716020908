import type { AnimationProps } from 'framer-motion'

import { createDomMotionComponent, AnimatePresence } from 'framer-motion'
import React, { useMemo } from 'react'

interface CollapseProps extends Pick<AnimationProps, 'transition'> {
	as?: Parameters<typeof createDomMotionComponent>[0]
	className?: string
	children: React.ReactNode
	open: boolean
}

const Collapse = ({ as = 'div', open, className, children, transition }: CollapseProps): JSX.Element => {
	const MotionComponent = useMemo(() => createDomMotionComponent(as), [as])

	return (
		<AnimatePresence initial={false}>
			{open && (
				<MotionComponent
					className={className}
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={{
						open: { opacity: 1, height: 'auto' },
						collapsed: { opacity: 0, height: 0 }
					}}
					transition={{ duration: 0.2, ease: 'easeOut', ...transition }}
				>
					{children}
				</MotionComponent>
			)}
		</AnimatePresence>
	)
}

export default Collapse
