export const NAVBAR_ID = 'navbar'

// Intercom's checklist targets
export const CREATE_INDEX_CARD = 'create-index-card'
export const INDEX_NAME_INPUT = 'index-name-input'
export const INDEX_MODAL_SELECT = 'index-model-select'
export const INDEX_CREATE_BUTTON = 'index-create-button'
export const INDEX_SEARCH_BUTTON = 'index-search-button'
export const INDEX_CLASSIFY_BUTTON = 'index-classify-button'
export const SEARCH_BAR_INPUT = 'search-bar-input'
export const CLASSIFY_CLASS_GROUP_SELECT = 'classify-class-group-select'
export const CLASSIFY_RUN_BUTTON = 'classify-run-button'
