import type { BoxProps } from '@mui/material/Box'
import type { IconButtonProps } from '@mui/material/IconButton'
import type { TextFieldProps } from '@mui/material/TextField'
import type { PathParams } from 'constants/paths'
import type { KeyboardEventHandler } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import ErrorIcon from '@mui/icons-material/Error'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useDeleteIndex, useUpdateIndex } from 'apis/indexes'
import { taskStatusOptions } from 'apis/tasks'
import { clsx } from 'clsx'
import Dialog from 'components/Dialog'
import FormLabel from 'components/FormLabel'
import { pathName } from 'constants/paths'
import mixpanel from 'mixpanel-browser'
import { useParams } from 'next/navigation'
import CopyIcon from 'public/icons/copy.svg'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm, useFormState } from 'react-hook-form'
import { useCopyToClipboard } from 'react-use'
import withBoundary from 'utils/hocs/withBoundary'
import useRouter from 'utils/hooks/useRouter'
import * as yup from 'yup'

interface IndexMenuButtonProps extends Omit<BoxProps, 'onClick'> {
	indexId: string
	indexName: string
}

interface IndexMenuActionProps extends IndexMenuButtonProps {
	onClose: () => void
}

const MoreIconButton = styled((props: IconButtonProps) => (
	<IconButton aria-label="more" size="medium" {...props}>
		<MoreVertIcon fontSize="inherit" />
	</IconButton>
))(({ theme }) => ({
	padding: theme.spacing(2)
}))

const MAX_LENGTH = 100

const IndexNameField = styled(
	forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => {
		const inputRef = useRef<HTMLInputElement>()

		const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback((e) => {
			switch (e.key) {
				case 'Esc':
				case 'Escape':
					break
				default:
					e.stopPropagation()
			}
		}, [])

		useEffect(() => {
			inputRef.current?.select()
		}, [])

		return (
			<TextField
				ref={ref}
				inputRef={inputRef}
				variant="filled"
				onKeyDown={onKeyDown}
				inputProps={{ maxLength: 150 }}
				{...props}
			/>
		)
	})
)(({ theme }) => ({
	'& input': {
		paddingTop: theme.spacing(16),
		paddingBottom: theme.spacing(15)
	}
}))

const indexUpdateSchema = yup.object({
	index_name: yup
		.string()
		.required('Please enter name of the index')
		.max(MAX_LENGTH, `The number of letters is limited to ${MAX_LENGTH}`)
})

type IndexUpdateSchema = yup.InferType<typeof indexUpdateSchema>
const RenameIndexMenu = ({ indexId, indexName, onClose: onCloseMenu }: IndexMenuActionProps): JSX.Element => {
	const updateIndex = useUpdateIndex()
	const {
		control,
		formState: { errors },
		handleSubmit
	} = useForm<IndexUpdateSchema>({
		resolver: yupResolver(indexUpdateSchema),
		defaultValues: { index_name: indexName },
		mode: 'onChange',
		reValidateMode: 'onChange'
	})
	const { isSubmitting } = useFormState({ control })
	const [open, setOpen] = useState(false)

	const disableSubmit = Object.values(errors).length > 0 || isSubmitting

	const onOpen = useCallback(() => {
		setOpen(true)
	}, [])

	const onClose = useCallback(() => {
		setOpen(false)
		onCloseMenu()
	}, [onCloseMenu])

	const onSubmit = useCallback(
		async ({ index_name }: IndexUpdateSchema) => {
			await updateIndex.mutateAsync({ index_id: indexId, index_name }).then(() => {
				onClose()
			})
		},
		[indexId, onClose, updateIndex]
	)

	return (
		<>
			<MenuItem onClick={onOpen}>
				<ListItemIcon>
					<EditIcon fontSize="small" className="!text-grey-900" />
				</ListItemIcon>
				<Typography variant="body2" color="text.primary">
					Rename index
				</Typography>
			</MenuItem>
			<Dialog
				maxWidth="sm"
				title="Rename index"
				open={open}
				onClose={onClose}
				onSubmit={handleSubmit(onSubmit)}
				action={{ label: 'Save', disabled: disableSubmit, loading: updateIndex.isPending }}
			>
				<FormLabel label="Index name">
					<Controller
						name="index_name"
						control={control}
						render={({ field }): JSX.Element => (
							<>
								<IndexNameField
									error={Object.values(errors).length > 0}
									autoFocus
									placeholder="Name your index"
									{...field}
								/>
								{errors.index_name && (
									<Typography mt={4} component="p" variant="caption" color="error">
										{errors.index_name?.message}
									</Typography>
								)}
							</>
						)}
					/>
				</FormLabel>
			</Dialog>
		</>
	)
}

const DeleteIndexMenu = ({ indexId, indexName, onClose: onCloseMenu }: IndexMenuActionProps): JSX.Element => {
	const router = useRouter()
	const pathParams = useParams<PathParams>()
	const deleteIndex = useDeleteIndex()
	const [open, setOpen] = useState(false)

	const onOpen = useCallback(() => {
		setOpen(true)
	}, [])

	const onClose = useCallback(() => {
		setOpen(false)
		onCloseMenu()
	}, [onCloseMenu])

	const onDelete = useCallback(() => {
		deleteIndex.mutateAsync({ index_id: indexId }).then(() => {
			if (pathParams?.index_id != null) {
				router.replace({ pathname: pathName.OVERVIEW })
			}
			onClose()
		})
	}, [deleteIndex, indexId, onClose, pathParams?.index_id, router])

	return (
		<>
			<MenuItem onClick={onOpen}>
				<ListItemIcon>
					<DeleteForeverIcon fontSize="small" className="!text-grey-900" />
				</ListItemIcon>
				<Typography variant="body2" color="text.primary">
					Delete index
				</Typography>
			</MenuItem>
			<Dialog
				maxWidth="sm"
				open={open}
				onClose={onClose}
				title={`Delete ${indexName}?`}
				action={{ label: 'Delete', appearance: 'danger', loading: deleteIndex.isPending, onClick: onDelete }}
				sx={{
					'* > h2': {
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}
				}}
			>
				<Typography variant="body1" color="grey.700" whiteSpace="pre-line">
					Are you sure you want to delete index {indexName} and all related information? This action cannot be undone.
				</Typography>
			</Dialog>
		</>
	)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CopyIndexMenu = ({ indexId, indexName, onClose: onCloseMenu }: IndexMenuActionProps): JSX.Element => {
	const [, copyToClipboard] = useCopyToClipboard()
	const [title, setTitle] = useState<string>('')

	const initiateCopy = useCallback(async () => {
		await copyToClipboard(indexId)
		setTitle('Copied!')
	}, [copyToClipboard, indexId])

	return (
		<MenuItem onClick={initiateCopy}>
			<ListItemIcon>
				<CopyIcon className="h-5 w-5 !text-grey-900" />
			</ListItemIcon>
			<Tooltip title={title} onOpen={() => setTitle('')}>
				<Typography variant="body2" color="text.primary">
					Copy index id
				</Typography>
			</Tooltip>
		</MenuItem>
	)
}

const IndexMenuButton = ({
	indexId,
	indexName,
	expired,
	...props
}: IndexMenuButtonProps & {
	expired?: boolean
}): JSX.Element => {
	const { data: hasNoIndexingVideo } = useSuspenseQuery({
		...taskStatusOptions(indexId),
		select: ({ data: { total_result, ready, failed } }) => total_result - ready - failed === 0
	})

	const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)
	const open = Boolean(anchor)

	const onClickButton = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchor(event.currentTarget)
		mixpanel.track('click', { type: 'button', element: 'index_menu' })
	}, [])

	const onClose = useCallback(() => {
		setAnchor(null)
	}, [])

	return (
		<Box onClick={(e): void => e.stopPropagation()} {...props}>
			<MoreIconButton color="inherit" onClick={onClickButton} />
			<Menu anchorEl={anchor} open={open} onClose={onClose}>
				{!expired && <RenameIndexMenu indexId={indexId} indexName={indexName} onClose={onClose} />}
				{hasNoIndexingVideo && <DeleteIndexMenu indexId={indexId} indexName={indexName} onClose={onClose} />}
				<CopyIndexMenu indexId={indexId} indexName={indexName} onClose={onClose} />
			</Menu>
		</Box>
	)
}

export default withBoundary(
	IndexMenuButton,
	{
		fallbackRender: ({ className, resetErrorBoundary }) => (
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
			<div className={clsx(className)} onClick={(e): void => e.stopPropagation()}>
				<IconButton aria-label="error" size="medium" onClick={resetErrorBoundary}>
					<ErrorIcon color="error" fontSize="inherit" />
				</IconButton>
			</div>
		)
	},
	{ fallback: null }
)
