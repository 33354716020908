'use client'

import { useUser } from '@auth0/nextjs-auth0/client'
import mixpanel from 'mixpanel-browser'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

const useInitializeMixpanel = () => {
	const [isInitialized, setIsInitialized] = useState(false)

	useEffect(() => {
		mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN, {
			debug: process.env.NODE_ENV !== 'production',
			test: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'io',
			api_host: process.env.NEXT_PUBLIC_MIXPANEL_URL,
			autotrack: true,
			ignore_dnt: true
		})
		setIsInitialized(true)
	}, [])

	return isInitialized
}

const useIdentifyMixpanel = (isInitialized: boolean) => {
	const { user } = useUser()

	useEffect(() => {
		if (!isInitialized || user?.sub == null) return
		mixpanel.identify(user.sub)
	}, [isInitialized, user?.sub])
}

const useTrackPageView = (isInitialized: boolean) => {
	const pathname = usePathname()

	useEffect(() => {
		if (!isInitialized || !pathname) return
		mixpanel.track_pageview()
	}, [pathname, isInitialized])
}

const MixpanelWizard = (): null => {
	const isInitialized = useInitializeMixpanel()

	useIdentifyMixpanel(isInitialized)
	useTrackPageView(isInitialized)

	return null
}

export default MixpanelWizard
