'use client'

import type { TooltipProps } from 'components/Tooltip'

import clsx from 'clsx'
import Tooltip from 'components/Tooltip'
import React from 'react'

export interface InfoWithLabelProps extends React.HTMLAttributes<HTMLDivElement> {
	label: string
	tooltip?: string | TooltipProps
	labelClassName?: string
}

const InfoWithLabel = ({
	label,
	tooltip,
	children,
	labelClassName,
	className,
	...props
}: InfoWithLabelProps): JSX.Element => (
	<div className={clsx(className, 'w-full', 'flex flex-col gap-y-2')} {...props}>
		<div className={clsx('flex items-center gap-x-1')}>
			<p className={clsx(labelClassName, 'text-body2 text-grey-700', 'whitespace-nowrap')}>{label}</p>
			{tooltip &&
				(typeof tooltip === 'string' ? (
					<Tooltip placement="right" content={tooltip} />
				) : (
					<Tooltip placement="right" {...tooltip} />
				))}
		</div>
		{children &&
			(React.isValidElement(children) ? (
				children
			) : (
				<p className={clsx('whitespace-nowrap text-body1 text-grey-1000')}>{children}</p>
			))}
	</div>
)

export default InfoWithLabel
