import type { AxiosAdapter, AxiosInstance } from 'axios'

import { getAccessToken as getAccessTokenFromAuth0 } from '@auth0/nextjs-auth0'
import { isServer } from '@tanstack/react-query'
import axios from 'axios'
import { Cache, cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'

const cachedRouteAPI = axios.create({
	baseURL: '/api',
	adapter: throttleAdapterEnhancer(
		cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
			defaultCache: new Cache({
				ttl: 1000 * 60 * 15 /* 15 min */,
				max: 10
			})
		}),
		{
			threshold: 500
		}
	)
})

async function getAccessTokenFromRoute() {
	return cachedRouteAPI.get<{ accessToken: string }>('/access-token').then(({ data }) => data)
}

export function authorizationInterceptor(instance: AxiosInstance) {
	return instance.interceptors.request.use(async (config) => {
		const { accessToken } = await (isServer ? getAccessTokenFromAuth0() : getAccessTokenFromRoute())
		return { ...config, headers: { ...config.headers, Authorization: `Bearer ${accessToken}` } }
	})
}
