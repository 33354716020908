import type { DefaultResponse, GeneralErrorCode, IndexStatus, TaskErrorCode } from 'utils/response'

export interface CreateTaskParams {
	index_id: string
	video_file?: File
	video_url?: string
	transcription_file?: File
	transcription_url?: string
	language: 'en'
	disable_video_stream?: false
}

export interface CreateTaskResponse {
	type: 'create_task'
	_id: string
	message: string
}

export interface TaskParams {
	_id?: string
	index_id?: string
	filename?: string
	duration?: number
	width?: number
	height?: number
	created_at?: string
	updated_at?: string
	estimated_time?: string
	deleted?: boolean
	status?: IndexStatus
	/**
	 * @default created_at
	 */
	sort_by?: 'created_at' | 'updated_at' | 'duration' | 'filename' | 'width' | 'height'
	/**
	 * @default desc
	 */
	sort_option?: 'asc' | 'desc'
	/**
	 * Put tasks which status is not ready first
	 */
	indexing_first?: true
	/**
	 * Number of items to return per page.
	 * Maximum value is 100
	 * @default 10
	 */
	page_limit?: number
	/**
	 * Page number.
	 * @default 1
	 */
	page?: number
}

export interface TaskResponse extends DefaultResponse {
	estimated_time: string // date format
	index_id: string
	video_id?: string
	metadata: {
		duration: number
		filename: string
		width: number
		height: number
	}
	status: IndexStatus
	deleted?: boolean
	process?: {
		upload_percentage: number
		remain_seconds: number
	}
	error_reason?: string // show up when status is 'failed'
}

export type FileId = string & { __brand: 'FileId' }

export interface TasksStatusParams {
	statuses?: IndexStatus[]
}

export interface IndexIdsByStatusResponse {
	index_ids: string[]
	count: Record<IndexStatus, number>
}

export interface VideoFileType {
	id: FileId
	file: File
	blobUrl: string
	duration: number | null // browser doesn't support some video format like .avi
}

export interface VideoUrlType {
	id: FileId
	url: string
	title: string
	thumbnailUrl: string
	duration: number // the video duration in seconds
}

export type VideoType = VideoFileType | VideoUrlType

export enum UploadStatus {
	Queued = 'queued',
	Uploading = 'uploading',
	Done = 'done',
	Failed = 'failed'
}

type UploadTaskBase<Status extends UploadStatus, T = Record<never, never>> = {
	indexId: string
	status: Status
} & VideoType &
	T

export type QueuedUploadingTask = UploadTaskBase<UploadStatus.Queued, { cancel: () => void }>
export type UploadingTask = UploadTaskBase<
	UploadStatus.Uploading,
	{ cancel: () => void; signal: AbortSignal; progress?: number }
>
export type DoneTask = UploadTaskBase<UploadStatus.Done, { taskId: string; clear: () => void }>
export type FailedUploadingTask = UploadTaskBase<
	UploadStatus.Failed,
	{ code: TaskErrorCode | GeneralErrorCode; message: string; clear: () => void }
>
export type UploadTask = QueuedUploadingTask | UploadingTask | DoneTask | FailedUploadingTask
