import type { DialogProps } from '@mui/material/Dialog'
import type { EngineData } from 'apis/indexes'
import type { TwelveLabsApiError } from 'utils/response'

import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { EngineFamily, IndexAddon, EngineOption, useCreateIndex } from 'apis/indexes'
import clsx from 'clsx'
import Dialog from 'components/Dialog'
import FormLabel from 'components/FormLabel'
import { INDEX_CREATE_BUTTON, INDEX_MODAL_SELECT, INDEX_NAME_INPUT } from 'constants/elementIds'
import { FormProvider } from 'react-hook-form'
import useIsMobile from 'utils/hooks/useIsMobile'
import { IndexErrorCode } from 'utils/response'

import EngineItem from './EngineItem'
import SelectEngineTooltip from './SelectEngineTooltip'
import { useIndexCreateForm, type IndexCreateSchema } from './utils'

const IndexCreateDialog = ({ open, onClose: close }: DialogProps): JSX.Element => {
	const isMobile = useIsMobile()
	const createIndex = useCreateIndex()

	const form = useIndexCreateForm()

	const onClose = (): void => {
		close?.({}, 'backdropClick')
		form.reset()
	}

	const onSubmit = ({ index_name, marengo, pegasus }: IndexCreateSchema): void => {
		const engines: EngineData[] = []

		;[marengo, pegasus].forEach(({ selected, engine_name, engine_options }) => {
			if (selected) {
				engines.push({
					engine_name,
					engine_options: Object.entries(engine_options)
						.map(([option, checked]) => checked && option)
						.filter(Boolean)
				} as EngineData)
			}
		})

		createIndex
			.mutateAsync({
				index_name,
				engines,
				addons: [IndexAddon.thumbnail]
			})
			.then(() => onClose())
			.catch((error) => {
				const apiError = error as TwelveLabsApiError<IndexErrorCode>
				if (apiError.isAxiosError && apiError.response?.data?.code === IndexErrorCode.IndexNameAlreadyExists) {
					form.setError('index_name', {
						message: 'This name is already in use'
					})
				}
			})
	}

	const { errors, isSubmitting } = form.formState
	const disabled = Object.values(errors).length > 0

	return (
		<Dialog
			disableAutoFocus
			disableRestoreFocus
			disableEnforceFocus
			open={open}
			onClose={onClose}
			onSubmit={form.handleSubmit(onSubmit)}
			PaperProps={isMobile ? undefined : { sx: { minWidth: '700px' } }}
			title="Create index"
			action={{
				label: 'Create',
				disabled,
				loading: isSubmitting || createIndex.isPending,
				// @ts-expect-error data-intercom-target is a special attribute for Intercom
				'data-intercom-target': INDEX_CREATE_BUTTON
			}}
		>
			<FormProvider {...form}>
				<div className={clsx('flex flex-col', 'gap-y-6')}>
					<FormLabel label="Set index name">
						<input
							{...form.register('index_name')}
							placeholder="Type index name here"
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
							data-intercom-target={INDEX_NAME_INPUT}
							className={clsx(
								'text-body2',
								'p-2',
								'border border-solid border-grey-400',
								'hover:bg-grey-100',
								'focus:border-green-500',
								'focus:bg-transparent',
								'placeholder:text-grey-500',
								errors.index_name && '!border-red-500'
							)}
						/>
						{errors.index_name && (
							<p className={clsx('my-0 text-body3 font-normal text-red-500', 'mt-1')}>{errors.index_name.message}</p>
						)}
					</FormLabel>
					<FormLabel
						label={isMobile ? 'Engines and engine options' : 'Select engines and engine options'}
						data-intercom-target={INDEX_MODAL_SELECT}
						tooltip={{
							name: 'create_index_engine',
							content: <SelectEngineTooltip />,
							placement: 'right',
							componentsProps: {
								tooltip: { sx: { maxWidth: 400 } }
							}
						}}
					>
						<div className={clsx('border border-moss_green-400', 'px-3 py-[10px]', 'bg-moss_green-50', 'flex gap-x-2')}>
							<InfoOutlined fontSize="small" className="text-moss_green-800" />
							<span className="text-body2 text-grey-1100">
								Engines and engine options cannot be changed once index is created
							</span>
						</div>
						<div className={clsx('flex flex-col gap-y-3', 'mt-3')}>
							<EngineItem
								engineFamily={EngineFamily.MARENGO}
								engineName={form.getValues('marengo.engine_name')}
								options={[
									{ value: EngineOption.visual },
									{ value: EngineOption.conversation },
									{ value: EngineOption.logo },
									{ value: EngineOption.text_in_video }
								]}
							/>
							<EngineItem
								engineFamily={EngineFamily.PEGASUS}
								engineName={form.getValues('pegasus.engine_name')}
								options={[{ value: EngineOption.visual }, { value: EngineOption.conversation }]}
							/>
						</div>
						{errors.root?.message && (
							<p className={clsx('my-0 text-body3 font-normal text-red-500', 'mt-1')}>{errors.root?.message}</p>
						)}
					</FormLabel>
				</div>
			</FormProvider>
		</Dialog>
	)
}

export default IndexCreateDialog
