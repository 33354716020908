import type { TwelveLabsApiError } from 'utils/response'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { refetchIndexTaskData } from 'apis/tasks'
import { ApiType, getInstance } from 'utils/network'

import { getVideosKey } from './keys'

// eslint-disable-next-line import/prefer-default-export
export function useDeleteIndexVideo<
	Response extends { type: 'delete_video_index'; message: string },
	VideoId extends string
>(indexId: string) {
	const queryClient = useQueryClient()
	return useMutation<Response, TwelveLabsApiError, VideoId>({
		mutationFn: (videoId: VideoId) =>
			getInstance(ApiType.TWELVE_LABS)
				.delete<Response>(`/indexes/${indexId}/videos/${videoId}`)
				.then((res) => res.data),
		onSuccess: () =>
			Promise.all([queryClient.invalidateQueries({ queryKey: getVideosKey(indexId) }), refetchIndexTaskData(indexId)])
	})
}
