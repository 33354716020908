import clsx from 'clsx'
import React from 'react'
import useIsMobile from 'utils/hooks/useIsMobile'

const MAX_VIDEO_SIZE_TEXT = '2GB'

interface Props {
	isOverMaxSize: boolean
	isDragFailed: boolean
	isDragAccept: boolean
	disabled?: boolean
}

const DropzoneCenterText = ({ isOverMaxSize, isDragAccept, isDragFailed, disabled }: Props): JSX.Element => {
	const isMobile = useIsMobile()

	if (isOverMaxSize) {
		return (
			<div className="text-subtitle2 text-red-500 tablet:text-subtitle1">
				You can only upload files less than {MAX_VIDEO_SIZE_TEXT}
			</div>
		)
	}

	if (isDragFailed) {
		return <div className="text-subtitle2 text-red-500 tablet:text-subtitle1">You can only upload video files</div>
	}

	if (isDragAccept) {
		return <div className="text-subtitle2 text-moss_green-800 tablet:text-subtitle1">Drop here!</div>
	}

	return (
		<p className={clsx('text-subtitle2 tablet:text-subtitle1', disabled ? '!text-grey-500' : '!text-grey-1000')}>
			{isMobile ? 'Tap to browse files' : 'Drop videos here or click to browse files'}
		</p>
	)
}

export default DropzoneCenterText
