'use client'

import * as Intercom from '@intercom/messenger-js-sdk'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Popover from '@mui/material/Popover'
import clsx from 'clsx'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import ContactSupportIcon from 'public/icons/contact-support.svg'
import DiscordOutlineIcon from 'public/icons/discord-outline.svg'
import HelpOutlineIcon from 'public/icons/help-outline.svg'
import React, { useRef, useState } from 'react'

import SideMenuItem from './SideMenuItem'

const HelpSubMenuItem = ({ children, ...restProps }: React.HTMLAttributes<HTMLButtonElement>) => (
	<button
		className={clsx(
			'flex items-center gap-x-1',
			'w-full px-3 py-[10px]',
			'text-body2 text-grey-1000',
			'hover:bg-grey-100'
		)}
		type="button"
		{...restProps}
	>
		{children}
	</button>
)

interface Props {
	expanded: boolean
}

const HelpSideMenuItem = ({ expanded }: Props) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false)
	const openPopover = () => setIsPopoverOpen(true)
	const closePopover = () => setIsPopoverOpen(false)

	const triggerRef = useRef<HTMLDivElement>(null)

	return (
		<div ref={triggerRef}>
			<SideMenuItem
				active={isPopoverOpen}
				expanded={expanded}
				Icon={HelpOutlineIcon}
				onClick={openPopover}
				tooltipLabel="Help"
			>
				<div className="flex items-center justify-between">
					Help <ArrowForwardIosIcon color="inherit" className="h-6 w-[14px]" />
				</div>
			</SideMenuItem>
			<Popover
				className="ml-1"
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				anchorEl={triggerRef.current}
				open={isPopoverOpen}
				onClose={closePopover}
			>
				<div className="py-3">
					<HelpSubMenuItem
						onClick={() => {
							Intercom.show()
							closePopover()
							mixpanel.track('click', { type: 'button', element: 'intercom' })
						}}
					>
						<ContactSupportIcon className="h-5 min-w-[23px]" />
						Contact support
					</HelpSubMenuItem>
					<Link
						href={process.env.NEXT_PUBLIC_DISCORD_URL}
						target="_blank"
						rel="noreferrer"
						onClick={() => {
							closePopover()
							mixpanel.track('click', { type: 'link', element: 'navbar', url: process.env.NEXT_PUBLIC_DISCORD_URL })
						}}
					>
						<HelpSubMenuItem>
							<DiscordOutlineIcon className="h-5 min-w-[23px]" />
							Ask the community
						</HelpSubMenuItem>
					</Link>
				</div>
			</Popover>
		</div>
	)
}

export default HelpSideMenuItem
