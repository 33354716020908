'use client'

import Tooltip from '@mui/material/Tooltip'
import { clsx } from 'clsx'
import { useState } from 'react'
import { secondsToCeiledMinutes } from 'utils/formatTime'
import { capitalizeFirstLetter } from 'utils/helpers'

export interface ProgressBarProps {
	data:
		| number
		| {
				label: string
				color: string
				value: number
				tooltip?: React.ReactNode
		  }[]
	limit?: number
	unit?: 'seconds'
	className?: string
}

const ProgressBar = ({ data, limit, className, unit = 'seconds' }: ProgressBarProps) => {
	const total = typeof data === 'number' ? data : data.reduce((acc, { value }) => acc + value, 0)

	const getPercentage = (value: number) => `${(value / Math.max(total, limit ?? 0)) * 100}%`

	const [hoveredLabel, setHoveredLabel] = useState<string | null>(null)

	return (
		<div className={clsx(className, 'flex flex-col gap-y-2')}>
			<div className={clsx('relative', 'group/progress-bar')}>
				<div
					className={clsx('relative', 'flex items-center overflow-hidden', 'h-2 w-full rounded-full', 'bg-grey-100')}
				>
					{typeof data === 'number' ? (
						<div className={clsx('bg-primary', 'h-full')} style={{ width: getPercentage(total) }} />
					) : (
						data.map(({ label, color, value }) => (
							<div
								key={label}
								className={clsx(`bg-${color}-500`, 'h-full', hoveredLabel && hoveredLabel !== label && 'opacity-30')}
								style={{ width: getPercentage(value) }}
							/>
						))
					)}
				</div>
			</div>
			{typeof data !== 'number' && (
				<div className={clsx('flex gap-x-3')}>
					{data.map(({ label, tooltip, value, color }) => (
						<Tooltip key={label} title={tooltip ?? (unit === 'seconds' ? secondsToCeiledMinutes(value) : value)}>
							<div
								className={clsx('flex items-center gap-x-1')}
								onMouseEnter={() => setHoveredLabel(label)}
								onMouseLeave={() => setHoveredLabel(null)}
							>
								<div className={clsx(`bg-${color}-500`, 'h-2 w-2 rounded-full')} />
								<p className={clsx('text-body2 text-grey-700')}>{capitalizeFirstLetter(label)}</p>
							</div>
						</Tooltip>
					))}
				</div>
			)}
		</div>
	)
}

export default ProgressBar
