import axios from 'axios'
import { StatusCodes } from 'http-status-codes'

import { GeneralErrorCode, type TwelveLabsApiError } from './response'

export const isTLApiError = <EC>(err: Error): err is TwelveLabsApiError<EC> =>
	axios.isAxiosError(err) && Boolean(err.config?.baseURL?.includes(process.env.NEXT_PUBLIC_API_BASE_URL))

export const getTLApiErrorData = <EC>({
	error,
	customErrorMessage = 'An unexpected error has occurred',
	use500ErrorMessage,
	custom500ErrorMessage
}: {
	error: Error
	customErrorMessage?: string
	use500ErrorMessage?: boolean
	custom500ErrorMessage?: string
}) => {
	if (isTLApiError<EC>(error)) {
		if (error.response?.data?.code && error.response?.data?.message) {
			return error.response.data
		}

		if (
			use500ErrorMessage &&
			error.response?.status === StatusCodes.INTERNAL_SERVER_ERROR &&
			Boolean(error.response.data) &&
			typeof error.response.data === 'string'
		) {
			return { code: GeneralErrorCode.UnexpectedError, message: custom500ErrorMessage || error.response.data }
		}
	}

	return { code: GeneralErrorCode.UnexpectedError, message: customErrorMessage }
}
