import type { ImageProps } from 'next/legacy/image'

import clsx from 'clsx'
import Image from 'next/legacy/image'
import VideoThumbnailIcon from 'public/icons/video-thumbnail.svg'
import { useCallback, useEffect, useState } from 'react'

function getUrl(src?: string): string | undefined {
	return src?.split('?')[0]
}

const IMAGE_CACHE_TTL = 60 // seconds
function isCacheExpired(now: Date, cachedDate: Date): boolean {
	const diff = now.getTime() - cachedDate.getTime()
	return diff >= IMAGE_CACHE_TTL * 1000
}

export interface ThumbnailProps extends Omit<ImageProps, 'layout' | 'src'> {
	src?: string
}

const Thumbnail = ({
	alt,
	src,
	onLoadingComplete,
	objectFit = 'contain',
	unoptimized = process.env.NODE_ENV !== 'production',
	...props
}: ThumbnailProps): JSX.Element | null => {
	const [isLoaded, setIsLoaded] = useState<boolean>(false)
	const [cache, setCache] = useState<{ src?: string; date: Date }>({ src, date: new Date() })

	useEffect(() => {
		const now = new Date()
		if (!isCacheExpired(now, cache.date) && getUrl(src) === getUrl(cache.src)) {
			return
		}

		setCache({ src, date: now })
		if (process.env.NODE_ENV !== 'production' && src === cache.src) {
			// Prevent to set wrong state because of hot reloading on dev environment
			return
		}
		setIsLoaded(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [src])

	const loadingComplete: Exclude<typeof onLoadingComplete, undefined> = useCallback(
		(result) => {
			onLoadingComplete?.(result)
			setIsLoaded(true)
		},
		[onLoadingComplete]
	)

	if (!cache.src) {
		return (
			<VideoThumbnailIcon
				className={clsx(
					'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
					'h-2/5 min-h-[14px] w-auto',
					'text-grey-500'
				)}
			/>
		)
	}

	return (
		<div className={clsx('absolute inset-0', 'bg-grey-900')}>
			<Image
				priority
				unoptimized={unoptimized}
				{...props}
				src={cache.src}
				alt={alt || src}
				onLoadingComplete={loadingComplete}
				layout="fill"
				objectFit={objectFit}
				className={clsx('bg-grey-900', !isLoaded && 'blur-md')}
			/>
		</div>
	)
}

const ThumbnailWithWrapper = ({ className, ...props }: ThumbnailProps): JSX.Element => (
	<div className={clsx(className, 'relative', 'aspect-video w-full', 'bg-grey-200')}>
		<Thumbnail {...props} />
	</div>
)

export default ThumbnailWithWrapper
