import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

interface Props {
	children: React.ReactNode
	width: 'fit-content' | '100%'
	show: boolean | undefined
}

const HideMotionDiv = ({ show, width, children }: Props) => (
	<AnimatePresence initial={false}>
		{show && (
			<motion.div
				initial="hidden"
				animate="expand"
				exit="hidden"
				variants={{
					expand: { opacity: 1, width },
					hidden: { opacity: 0, width: 0 }
				}}
				transition={{ duration: 0.3, ease: 'easeInOut' }}
			>
				{children}
			</motion.div>
		)}
	</AnimatePresence>
)

export default HideMotionDiv
