const VIDEOS = 'videos' as const
const THUMBNAIL = 'thumbnail' as const

export function getVideosKey(indexId: string): [typeof VIDEOS, string]
export function getVideosKey<Params = Record<string, unknown>>(
	indexId: string,
	params: Params
): [typeof VIDEOS, string, Params]
export function getVideosKey<Params = Record<string, unknown>>(indexId: string, params?: Params): unknown {
	if (!params) return [VIDEOS, indexId]
	return [VIDEOS, indexId, params]
}

export const getVideoKey = (indexId: string, videoId: string): [typeof VIDEOS, string, string] => [
	...getVideosKey(indexId),
	videoId
]

export function getVideoThumbnailKey(
	indexId: string,
	videoId: string
): [typeof VIDEOS, string, string, typeof THUMBNAIL]
export function getVideoThumbnailKey(
	indexId: string,
	videoId: string,
	params: Record<string, unknown>
): [typeof VIDEOS, string, string, typeof THUMBNAIL, Record<string, unknown>]
export function getVideoThumbnailKey(indexId: string, videoId: string, params?: Record<string, unknown>): unknown {
	if (params) return [...getVideoKey(indexId, videoId), THUMBNAIL, params]
	return [...getVideoKey(indexId, videoId), THUMBNAIL]
}
