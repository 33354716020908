import InfoOutlined from '@mui/icons-material/InfoOutlined'
import Skeleton from '@mui/material/Skeleton'
import { useSuspenseQuery } from '@tanstack/react-query'
import { indexOptions } from 'apis/indexes'
import clsx from 'clsx'
import Collapse from 'components/Collapse'
import Tooltip from 'components/Tooltip'
import ChevronUpMini from 'components/svg/ChevronUpMini'
import { Fragment, useState } from 'react'
import { getVideoFileLimits } from 'utils/helpers'
import withBoundary from 'utils/hocs/withBoundary'
import useIsMobile from 'utils/hooks/useIsMobile'

interface SupportedVideoInfoProps {
	indexId: string
	className?: string
}

const SupportedVideoInfoList = withBoundary(
	({ indexId, open }: Omit<SupportedVideoInfoProps, 'className'> & { open: boolean }): JSX.Element => {
		const { data: index } = useSuspenseQuery(indexOptions(indexId))

		const fileLimits = getVideoFileLimits(index)

		return (
			<Collapse
				className={clsx('grid grid-cols-[78px_1fr] gap-y-1', 'md:grid-cols-[80px_1fr_80px_1fr]', 'mt-2')}
				open={open}
			>
				{fileLimits.map(([label, text, tooltip]) => (
					<Fragment key={label}>
						<div className={clsx('mr-3', 'text-subtitle3 font-medium text-black', 'md:text-grey-900')}>{label}</div>
						<div className={clsx('text-body2 text-grey-700', 'flex items-center')}>
							<span className={clsx(label === 'Duration' && 'text-green-900')}>{text}</span>
							{tooltip && (
								<Tooltip content={tooltip} title={text}>
									<InfoOutlined className={clsx('ml-1', 'text-base text-grey-600')} />
								</Tooltip>
							)}
						</div>
					</Fragment>
				))}
			</Collapse>
		)
	},
	{},
	{
		fallback: (
			<div className={clsx('grid grid-cols-[78px_1fr] gap-y-1', 'md:grid-cols-[80px_1fr_80px_1fr]', 'mt-2')}>
				{Array.from(Array(4).keys()).map((i) => (
					<Skeleton key={i} variant="rounded" className={clsx('w-40', 'h-5')} />
				))}
			</div>
		)
	}
)

const SupportedVideoInfoWithAction = ({ className, ...props }: SupportedVideoInfoProps): JSX.Element => {
	const isMobile = useIsMobile()
	const [open, setOpen] = useState(true)

	const toggleCollapse = (): void => {
		if (!isMobile) return

		setOpen((prev) => !prev)
	}

	return (
		<div className={className}>
			<button
				className={clsx(
					'w-full',
					'text-subtitle2 font-medium text-grey-1100',
					'bg-transparent',
					'border-none',
					'p-0',
					'flex items-center justify-between'
				)}
				type="button"
				onClick={toggleCollapse}
			>
				Supported videos
				{isMobile && (
					<ChevronUpMini
						sx={{
							transform: open ? undefined : 'rotate(180deg)'
						}}
					/>
				)}
			</button>
			<SupportedVideoInfoList open={open} {...props} />
		</div>
	)
}

export default SupportedVideoInfoWithAction
