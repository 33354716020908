import type { UploadTask, VideoType } from 'apis/tasks'

import CircularProgress from '@mui/material/CircularProgress'
import { UploadStatus, errorByCode, isFileType, isUrlType, useClearUploadTasks } from 'apis/tasks'
import clsx from 'clsx'
import Thumbnail from 'components/Thumbnail'
import ErrorRoundIcon from 'public/icons/error-round.svg'
import UploadIcon from 'public/icons/upload.svg'
import VideoThumbnailIcon from 'public/icons/video-thumbnail.svg'
import { forwardRef } from 'react'

import StatusBadgeWithClearButton from './StatusBadgeWithClearButton'

const getVideoTitle = (video: VideoType) => {
	if (isFileType(video)) return video.file.name
	if (isUrlType(video)) return video.title
	throw new Error('Invalid video type')
}

const UploadingTaskStatusItem = forwardRef<HTMLDivElement, UploadTask & { indexId: string }>(
	({ indexId, ...task }, ref) => {
		const clearUploadTasks = useClearUploadTasks()

		return (
			<div ref={ref} className={clsx('group/task-status', 'flex items-center gap-x-2', 'h-8 w-full')}>
				{isFileType(task) && (
					<div className={clsx('h-6 w-11', 'relative overflow-hidden rounded-sm', 'bg-grey-200')}>
						<VideoThumbnailIcon
							className={clsx(
								'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
								'h-2/5 min-h-[14px] w-auto',
								'text-grey-500'
							)}
						/>
						<video
							muted
							preload="auto"
							src={`${task.blobUrl}#t=0.001`}
							className={clsx('relative z-1', 'h-[inherit] w-full')}
						/>
					</div>
				)}
				{isUrlType(task) && (
					<Thumbnail
						unoptimized
						alt={task.id}
						src={task.thumbnailUrl}
						objectFit="cover"
						className={clsx('h-6 !w-11 flex-shrink-0', 'overflow-hidden rounded-sm')}
					/>
				)}
				<p className={clsx('truncate text-body2', 'flex-1')}>{getVideoTitle(task)}</p>
				{task.status === UploadStatus.Queued && (
					<StatusBadgeWithClearButton
						badge={{
							icon: UploadIcon,
							label: 'Pending'
						}}
						cancel={{ label: 'Cancel', onClick: task.cancel }}
					/>
				)}
				{task.status === UploadStatus.Uploading && (
					<StatusBadgeWithClearButton
						badge={
							task.progress == null || task.progress === 100 ? (
								<CircularProgress size={16} />
							) : (
								{
									icon: UploadIcon,
									label: `${task.progress}%`
								}
							)
						}
						cancel={{
							label: 'Cancel',
							onClick: () => {
								task.cancel()
								clearUploadTasks({ fileId: task.id })
							}
						}}
					/>
				)}
				{task.status === UploadStatus.Failed && (
					<StatusBadgeWithClearButton
						badge={{
							icon: ErrorRoundIcon,
							label: errorByCode(task.code),
							appearance: 'error'
						}}
						cancel={{ label: 'Clear', onClick: task.clear }}
					/>
				)}
			</div>
		)
	}
)

export default UploadingTaskStatusItem
