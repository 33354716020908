import muiSvg from './muiSvg'

const LogoDetection = muiSvg(
	(props): JSX.Element => (
		<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2 4.5C2 3.11929 3.11929 2 4.5 2H7V4.5H4.5V7H2V4.5Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22 19.5C22 20.8807 20.8807 22 19.5 22L17 22L17 19.5L19.5 19.5L19.5 17L22 17L22 19.5Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22 4.5C22 3.11929 20.8807 2 19.5 2L17 2V4.5H19.5V7H22V4.5Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2 19.5C2 20.8807 3.11929 22 4.5 22L7 22L7 19.5L4.5 19.5L4.5 17L2 17L2 19.5Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
				fill="currentColor"
			/>
		</svg>
	)
)

export default LogoDetection
