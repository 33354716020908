'use client'

import clsx from 'clsx'
import { pathName } from 'constants/paths'
import mixpanel from 'mixpanel-browser'

import TwelveLabsIcon from './icon/twelve-labs.svg'

const LogoButton = () => (
	<button
		type="button"
		className={clsx('p-1')}
		onClick={() => mixpanel.track('click', { type: 'link', element: 'navbar_logo', url: pathName.OVERVIEW })}
	>
		<TwelveLabsIcon className={clsx('h-7 w-7')} />
	</button>
)

export default LogoButton
