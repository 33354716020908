import type { AxiosError } from 'axios'
import type { ErrorBoundaryProps, FallbackProps } from 'react-error-boundary'

import { datadogLogs } from '@datadog/browser-logs'
import clsx from 'clsx'
import ErrorPage from 'pages/_error'
import { useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import Navbar from 'widgets/Navbar'

interface Props {
	children: React.ReactNode
}

const GlobalErrorBoundary = ({ children }: Props): JSX.Element => {
	const onError: ErrorBoundaryProps['onError'] = (err) => {
		datadogLogs.logger.error(err.message, undefined, err)
	}

	const fallbackRender = useCallback(({ error }: FallbackProps) => {
		const networkError = error as AxiosError

		return (
			<div className={clsx('flex flex-col', 'min-h-screen w-full')}>
				<Navbar disableFetch />
				<ErrorPage statusCode={networkError.response?.status ?? 500} />
			</div>
		)
	}, [])

	return (
		<ErrorBoundary onError={onError} fallbackRender={fallbackRender}>
			{children}
		</ErrorBoundary>
	)
}

export default GlobalErrorBoundary
