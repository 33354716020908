'use client'

import type { NavbarMenuProps } from '../NavbarMenu'

import Popover from '@mui/material/Popover'
import Skeleton from '@mui/material/Skeleton'
import { Button } from '@twelvelabs/tds'
import UpgradePlanButton from 'app/dashboard/billing/components/Usage/UpgradePlanButton'
import { dashboardMenus } from 'app/src/layouts'
import clsx from 'clsx'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import ChevronDownIcon from 'public/icons/chevron-down.svg'
import ExitIcon from 'public/icons/exit.svg'
import UserIcon from 'public/icons/user.svg'
import { useMemo, useRef } from 'react'
import useAuth0 from 'utils/hooks/useAuth0'
import { UsageVertical } from 'widgets/Usage'

const UserAvatar = ({ size }: { size: 'lg' | 'sm' }) => {
	const { user, error, isLoading } = useAuth0()

	const nickname = useMemo(() => {
		const nameWithFallback = user?.name ?? user?.nickname
		if (nameWithFallback == null) return undefined
		const names = nameWithFallback.split(' ')
		if (names.length < 2) return nameWithFallback.slice(0, 2)
		return names
			.slice(0, 2)
			.map((name) => name[0])
			.join('')
	}, [user?.name, user?.nickname])

	if (isLoading) {
		return (
			<Skeleton
				variant="circular"
				width={size === 'sm' ? 32 : 56}
				height={size === 'sm' ? 32 : 56}
				className={clsx('rounded-full')}
			/>
		)
	}

	if (error || !nickname) {
		return (
			<div
				className={clsx(
					'rounded-full bg-grey-600',
					'flex items-center justify-center',
					size === 'sm' ? 'h-8 w-8' : 'h-14 w-14'
				)}
			>
				<UserIcon className={clsx('text-grey-200', 'h-4 w-4')} />
			</div>
		)
	}

	return (
		<div
			className={clsx(
				'rounded-full bg-secondary',
				'flex items-center justify-center',
				size === 'sm' ? 'h-8 w-8' : 'h-14 w-14'
			)}
		>
			<p
				className={clsx('!text-primary', 'capitalize', '!font-medium', size === 'sm' ? 'text-body3' : 'text-subtitle2')}
			>
				{nickname}
			</p>
		</div>
	)
}

export const UserInfo = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>): JSX.Element | null => {
	const { user } = useAuth0()

	if (user == null) return null

	return (
		<div className={clsx(className, 'flex items-center gap-3 tablet:flex-col')} {...props}>
			<UserAvatar size="lg" />
			<div className={clsx('flex flex-col items-start tablet:items-center')}>
				<p className={clsx('truncate text-subtitle2 !text-grey-1200')}>{user.name}</p>
				<p className={clsx('truncate text-body2 !text-grey-1200')}>{user.email}</p>
			</div>
		</div>
	)
}

interface UserInfoButtonProps extends Pick<NavbarMenuProps, 'hideUsage' | 'hideTabs'> {
	open: boolean
	onOpen: () => void
	onClose: () => void
}

const UserInfoButton = ({ open: isOpen, onOpen, onClose, hideTabs, hideUsage }: UserInfoButtonProps): JSX.Element => {
	const { user, error, isLoading, logout: logoutWithRedirect, loginWithRedirect } = useAuth0()
	const buttonElem = useRef<HTMLButtonElement | null>(null)
	const pathname = usePathname()

	const login = () => {
		loginWithRedirect()
		mixpanel.track('click', { type: 'button', element: 'login' })
	}
	const logout = () => {
		logoutWithRedirect()
		mixpanel.track('click', { type: 'button', element: 'logout' })
	}

	if (isLoading) {
		return (
			<button disabled type="button" className={clsx('tablet:pl-3 tablet:pr-5')}>
				<UserAvatar size="sm" />
			</button>
		)
	}

	if (error != null) {
		return (
			<Button size="sm" appearance="subtle" onClick={logout} className={clsx('!px-1 !text-grey-1000')}>
				<span className={clsx('max-w-[120px] truncate')}>Sign out</span>
			</Button>
		)
	}

	if (user == null) {
		return (
			<Button size="sm" appearance="subtle" onClick={login} className={clsx('!px-1 !text-grey-1000')}>
				<span className={clsx('max-w-[120px] truncate')}>Sign in</span>
			</Button>
		)
	}

	return (
		<>
			<button
				type="button"
				ref={buttonElem}
				onClick={onOpen}
				className={clsx('text-grey-1000', 'tablet:flex tablet:items-center tablet:pl-3')}
			>
				<UserAvatar size="sm" />
				<ChevronDownIcon className={clsx('hidden tablet:block', 'h-5 w-5', isOpen && 'rotate-180')} />
			</button>
			<Popover
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				anchorEl={buttonElem.current}
				open={isOpen}
				onClose={onClose}
			>
				<div className={clsx('flex flex-col', 'w-[360px] py-6')}>
					<UserInfo className={clsx('mb-8 px-5')} />
					{!hideUsage && (
						<div className={clsx('mb-3 px-5', 'flex flex-col gap-y-5')}>
							<UsageVertical />
							<UpgradePlanButton show size="md" className={clsx('[&>button]:w-full')} />
						</div>
					)}
					{!hideTabs &&
						dashboardMenus.map(({ label, path, getIcon }) => {
							const Icon = getIcon(false)

							return (
								<Link key={label} href={path}>
									<Button
										disabled={pathname === path}
										appearance="subtle"
										size="lg"
										className={clsx(
											'!font-normal !text-black',
											['hover:!bg-grey-50 active:!bg-green-50 disabled:!bg-green-50'],
											'!h-14 w-full !px-5',
											'!justify-start'
										)}
										onClick={() => {
											mixpanel.track('click', {
												type: 'link',
												element: 'user_menu',
												url: path
											})
											onClose()
										}}
									>
										<Icon />
										{label}
									</Button>
								</Link>
							)
						})}
					<Button
						appearance="subtle"
						size="lg"
						className={clsx(
							'!font-normal !text-black hover:!bg-grey-50 active:!bg-green-200',
							'mt-2.5 !h-14 w-full !px-5',
							'border-t !border-grey-200',
							'!justify-start'
						)}
						onClick={logout}
					>
						<ExitIcon />
						Sign out
					</Button>
				</div>
			</Popover>
		</>
	)
}

export default UserInfoButton
