import type {
	BillingHistory,
	BillingHistoryResponse,
	CreditInfo,
	CreditInfoResponse,
	CustomerPaymentIntentResponse,
	TotalDue,
	UpcomingInvoice,
	UpcomingInvoiceResponse,
	Usage,
	UserCardInfo
} from './types'
import type { AxiosError, AxiosResponse } from 'axios'
import type { UseCommonQueryOptions } from 'utils/response'

import { fromUnixTime } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import { ApiType, getInstance } from 'utils/network'

import {
	getUpcomingInvoiceKey,
	getBillingHistoryKey,
	getTotalDueKey,
	getUsageKey,
	getFreeCreditKey,
	getCurrentMonthUsageKey,
	getPaymentsKey,
	getPaymentMethodsKey
} from './keys'

export function totalAmountDueOptions<Response extends TotalDue>(): UseCommonQueryOptions<
	AxiosResponse<Response>,
	AxiosError,
	Response,
	ReturnType<typeof getTotalDueKey>
> {
	return {
		queryKey: getTotalDueKey(),
		queryFn: () => getInstance(ApiType.INTERNAL).get<Response>('/billing/totalDue'),
		select: ({ data }) => data
	}
}

export function usageOptions<Response extends Usage>(): UseCommonQueryOptions<
	AxiosResponse<Response>,
	AxiosError,
	Response,
	ReturnType<typeof getUsageKey>
> {
	return {
		queryKey: getUsageKey(),
		queryFn: async () => getInstance(ApiType.INTERNAL).get<Response>('/billing/usage'),
		select: (res) => res.data
	}
}

export function billingHistoryOptions(): UseCommonQueryOptions<
	AxiosResponse<BillingHistoryResponse[] | undefined>,
	AxiosError,
	BillingHistory[],
	ReturnType<typeof getBillingHistoryKey>
> {
	return {
		queryKey: getBillingHistoryKey(),
		queryFn: () => getInstance(ApiType.INTERNAL).get<BillingHistoryResponse[] | undefined>('/billing/history'),
		select: ({ data }) =>
			(data ?? []).map((item) => ({
				...item,
				issued_date: new Date(item.issued_date),
				due_date: new Date(item.issued_date),
				paid_with:
					isEmpty(item.paid_with) || !item.paid_with.brand || !item.paid_with.last4
						? null
						: {
								...item.paid_with,
								brand: item.paid_with.brand.toUpperCase()
						  },
				paid_amount: Math.max(item.paid_amount, 0),
				total_amount: Math.max(item.total_amount, 0),
				billing_period_start: new Date(item.billing_period_start),
				billing_period_end: new Date(item.billing_period_end),
				next_payment_attempt: item.next_payment_attempt ? new Date(item.next_payment_attempt) : null,
				invoice_lines: item.invoice_lines ?? []
			}))
	}
}

export function currentMonthUsageOptions<
	Response extends Pick<Usage, 'infra_usage_per_engine_family' | 'total_infra_usage_used'>
>(): UseCommonQueryOptions<AxiosResponse<Response>, AxiosError, Response, ReturnType<typeof getCurrentMonthUsageKey>> {
	return {
		queryKey: getCurrentMonthUsageKey(),
		queryFn: () => getInstance(ApiType.INTERNAL).get<Response>('/billing/current-month-usage'),
		select: ({ data }) => data
	}
}

export function upcomingInvoiceOptions(): UseCommonQueryOptions<
	AxiosResponse<UpcomingInvoiceResponse>,
	AxiosError,
	UpcomingInvoice,
	ReturnType<typeof getUpcomingInvoiceKey>
> {
	return {
		queryKey: getUpcomingInvoiceKey(),
		queryFn: () => getInstance(ApiType.INTERNAL).get<UpcomingInvoiceResponse>('/billing/upcomingInvoice'),
		select: ({ data }) => ({
			...data,
			next_payment_attempt: fromUnixTime(data.next_payment_attempt),
			period_start: fromUnixTime(data.period_start),
			period_end: fromUnixTime(data.period_end)
		})
	}
}

export function freeCreditOptions(): UseCommonQueryOptions<
	AxiosResponse<CreditInfoResponse>,
	AxiosError,
	CreditInfo,
	ReturnType<typeof getFreeCreditKey>
> {
	return {
		queryKey: getFreeCreditKey(),
		queryFn: () => getInstance(ApiType.INTERNAL).get<CreditInfoResponse>('/billing/freeCredit'),
		select: ({ data }) => ({
			...data,
			past_grants: data.past_grants?.map((item) => ({ ...item, grant_date: new Date(item.grant_date) })) ?? []
		})
	}
}

export function stripeClientSecretOptions<Response extends CustomerPaymentIntentResponse>(): UseCommonQueryOptions<
	AxiosResponse<Response>,
	AxiosError,
	string,
	ReturnType<typeof getPaymentsKey>
> {
	return {
		queryKey: getPaymentsKey('setup'),
		queryFn: async () => getInstance(ApiType.INTERNAL).post<Response>('/billing/payments/setup'),
		select: (res) => res.data.clientSecret
	}
}

export function paymentMethodsOptions<Response extends UserCardInfo>(): UseCommonQueryOptions<
	AxiosResponse<Response>,
	AxiosError,
	Response,
	ReturnType<typeof getPaymentMethodsKey>
> {
	return {
		queryKey: getPaymentMethodsKey(),
		queryFn: () => getInstance(ApiType.INTERNAL).get<Response>('/billing/paymentMethods'),
		select: ({ data }) => data
	}
}
