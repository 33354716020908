import type { Usage } from './types'
import type { AxiosError } from 'axios'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiType, getInstance } from 'utils/network'

import { getUsageKey } from './keys'
import { usageOptions } from './options'

interface ChangePlanParams {
	plan_name: 'free' | 'developer'
	cancel_reason?: string
	cancel_description?: string
}

export function useChangePlan() {
	const queryClient = useQueryClient()

	return useMutation<void, AxiosError, ChangePlanParams>({
		mutationFn: (data) =>
			getInstance(ApiType.INTERNAL)
				.post('/billing/changePlan', data)
				.then((res) => res.data),
		onSuccess: () => {
			queryClient.resetQueries({ queryKey: getUsageKey() })
		}
	})
}

export const isFreePlan = (usage: Usage | undefined) => (usage ? usage.is_hard_limit : true)
export const useIsFreePlan = () => useQuery({ ...usageOptions(), select: (res) => isFreePlan(res.data) })
