import type { EngineData, IndexResponse } from 'apis/indexes'
import type { LinkProps } from 'next/link'
import type { Sample } from 'utils/response'

import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { useFlag } from '@unleash/nextjs/client'
import { sortEngineOptions, sortEngines, EngineOption } from 'apis/indexes'
import { indexVideosOptions, indexVideoThumbnailOptions } from 'apis/videos'
import clsx from 'clsx'
import Alert, { ExpirationAlert } from 'components/Alert'
import EngineLogo from 'components/EngineLogo'
import OverflowText from 'components/OverflowText'
import Thumbnail from 'components/Thumbnail'
import Tooltip from 'components/Tooltip'
import { pathName } from 'constants/paths'
import { format } from 'date-fns'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import { useCallback } from 'react'
import { capitalize, stripAfterHyphen } from 'utils/formatString'
import withBoundary from 'utils/hocs/withBoundary'
import { FlagName } from 'utils/unleash'
import IndexMenuButton from 'widgets/IndexMenuButton'
import { useOpenIndexTaskCreateDialog } from 'widgets/IndexTaskCreate'
import IndexTaskStatus from 'widgets/IndexTaskStatus'
import PlayNavButtons from 'widgets/PlayNavButtons'

interface IndexItemProps extends Sample {
	id: IndexResponse['_id']
	name: IndexResponse['index_name']
	engines: IndexResponse['engines']
	totalDuration: IndexResponse['total_duration']
	createdAt: IndexResponse['created_at']
	isExpired: IndexResponse['isExpired']
	outOfCredit?: boolean
	isMobile?: boolean
}

export const tooltipContent: Record<EngineOption, string> = {
	[EngineOption.visual]:
		'Index what is seen and heard, including actions, objects, and sound, while excluding human speech.',
	[EngineOption.conversation]: 'Index the speech within your videos to enable semantic conversation understanding.',
	[EngineOption.text_in_video]: 'Extract and read the text (OCR) that appears in your videos.',
	[EngineOption.logo]: 'Identify brand logos that appear in your videos.'
}

const IndexCardThumbnailGradient = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div
		className={clsx(
			className,
			'absolute inset-0 z-0',
			'bg-[linear-gradient(180deg,rgba(0,0,0,0.05)0%,rgba(0,0,0,0.1)60%,rgba(0,0,0,0.3)100%)]'
		)}
		{...props}
	/>
)

const IndexCardContent = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={clsx(className, 'h-[216px] px-5 pb-5 pt-3', 'flex flex-col gap-y-2')} {...props} />
)

export const IndexCardSkeleton = (): JSX.Element => (
	<div className="border border-solid border-grey-300">
		<Skeleton variant="rectangular" width="100%" height={0} sx={{ paddingTop: '56.25%' }} />
		<IndexCardContent>
			<Skeleton variant={'subtitle1' as 'text'} width={120} />
			<Box display="flex" mt={12} gap={20}>
				<Skeleton variant="circular" width={24} height={24} />
				<Skeleton variant="circular" width={24} height={24} />
			</Box>
			<Box mt="auto" display="flex" gap={8}>
				<Skeleton variant="rectangular" height={36} sx={{ flex: 1 }} />
				<Skeleton variant="rectangular" height={36} sx={{ flex: 1 }} />
			</Box>
		</IndexCardContent>
	</div>
)

const LinkWithDisabled = ({
	disabled,
	children,
	...props
}: LinkProps & { children?: React.ReactNode; disabled?: boolean }): JSX.Element =>
	disabled ? (
		<div className="relative">
			<div className="absolute inset-0 z-1 bg-white/40" />
			{children}
		</div>
	) : (
		<Link {...props} legacyBehavior>
			{children}
		</Link>
	)

const EngineIcons = ({ engines, isMobile }: { engines: Array<EngineData>; isMobile?: boolean }): JSX.Element => {
	const isAdmin = useFlag(FlagName.IS_ADMIN)

	return (
		// The <div> element has a child <button> element that allows keyboard interaction
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
		<div className={clsx('flex', 'w-fit')} onClick={(e): void => e.stopPropagation()}>
			{engines
				.sort((a, b) => sortEngines(a.engine_name, b.engine_name))
				.map(({ engine_name, engine_options }) => {
					const formattedName = isAdmin ? capitalize(engine_name) : stripAfterHyphen(capitalize(engine_name))
					const engineOptions = engine_options.sort(sortEngineOptions).map((o) => capitalize(o))
					const formattedOptions = engineOptions.join(', ')
					const mobileFormattedOptions = new Intl.ListFormat('en').format(engineOptions)

					return (
						<Tooltip
							key={engine_name}
							content={isMobile ? mobileFormattedOptions : `${formattedName} (${formattedOptions})`}
							title={formattedName}
						>
							<span
								className={clsx('rounded-full', 'h-10 w-10', 'flex items-center justify-center', 'hover:bg-grey-200')}
							>
								<EngineLogo className="h-8 w-8 text-grey-600" engine_name={engine_name} />
							</span>
						</Tooltip>
					)
				})}
		</div>
	)
}

const IndexCard = ({
	id,
	name,
	sample,
	engines,
	createdAt,
	isExpired,
	outOfCredit,
	totalDuration,
	isMobile
}: IndexItemProps): JSX.Element => {
	const { data: videos } = useSuspenseQuery(indexVideosOptions(id, { page_limit: 1 }))
	const { data: thumbnail } = useQuery({
		...indexVideoThumbnailOptions(id, videos.data[0]?._id, {}),
		select: (res) => res.data.thumbnail,
		enabled: videos.data.length > 0
	})

	const openIndexTaskCreateDialog = useOpenIndexTaskCreateDialog()

	const onOpenUploadDialog = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>): void => {
			e.stopPropagation()
			mixpanel.track('click', { type: 'button', element: 'index_card_upload' })
			openIndexTaskCreateDialog(id)
		},
		[id, openIndexTaskCreateDialog]
	)

	const hasIndexedVideo = videos.data.length > 0

	return (
		<LinkWithDisabled
			disabled={isExpired}
			href={pathName.index(id)}
			onClick={() => mixpanel.track('click', { type: 'button', element: 'index_card' })}
		>
			<div className={clsx('border border-solid border-grey-300 hover:border-primary', !isExpired && 'cursor-pointer')}>
				<div className={clsx('relative')}>
					<Thumbnail src={thumbnail} />
					<IndexCardThumbnailGradient />
					<ExpirationAlert indexId={id} className={clsx('absolute left-3 top-3 z-2')} />
					{sample ? (
						<Alert className="absolute right-3 top-3" icon={null} title="Sample" />
					) : (
						<IndexMenuButton
							indexId={id}
							indexName={name}
							expired={isExpired}
							className={clsx('text-white', 'absolute right-3 top-3 z-2')}
						/>
					)}
					<div className={clsx('absolute bottom-3 right-3')}>
						<IndexTaskStatus indexId={id} totalDuration={totalDuration} />
					</div>
				</div>
				<IndexCardContent>
					<OverflowText text={name}>
						<p className={clsx('my-0 min-h-[1.5rem] text-subtitle1 !font-medium !text-black')}>{name}</p>
					</OverflowText>
					{!sample && (
						<p className={clsx('text-body2 !text-grey-700')}>
							{format(new Date(createdAt), "'Created on' MMM dd, yyyy")}
						</p>
					)}
					<EngineIcons engines={engines} isMobile={isMobile} />
					{!isExpired && (
						<PlayNavButtons
							className={clsx('mt-auto', 'flex flex-col gap-y-2')}
							indexId={id}
							readonly={outOfCredit}
							onOpenUploadDialog={onOpenUploadDialog}
							hasVideo={hasIndexedVideo}
							uploadTriggerType={hasIndexedVideo ? 'text' : 'button'}
						/>
					)}
				</IndexCardContent>
			</div>
		</LinkWithDisabled>
	)
}

export default withBoundary(IndexCard, {}, { fallback: <IndexCardSkeleton /> })
