'use client'

import { useQuery } from '@tanstack/react-query'
import { Button } from '@twelvelabs/tds'
import { usageOptions } from 'apis/billing'
import clsx from 'clsx'
import Routes from 'constants/routes'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import React from 'react'
import { calcUsageNumbers, USAGE_WARNING_THRESHOLD } from 'utils/billing'

const UserUsageWithBorder = () => {
	const { data: usage } = useQuery(usageOptions())
	const isDeveloperPlan = usage && !usage.is_hard_limit

	if (!usage || isDeveloperPlan) {
		return null
	}

	const { limitInMin, usedInMin, usedInPercentage } = calcUsageNumbers(usage)
	const isOverWarningThreshold = usedInPercentage >= USAGE_WARNING_THRESHOLD

	const sendMixpanelEvent = () => {
		mixpanel.track('click', { type: 'link', element: 'navbar', url: Routes.USAGE })
	}

	return (
		<>
			<Link href={Routes.USAGE} onClick={sendMixpanelEvent}>
				<Button
					className={clsx(
						'flex items-center',
						'text-subtitle3 hover:text-moss_green-800 active:text-moss_green-800',
						isOverWarningThreshold ? 'text-red-500' : 'text-grey-700',
						'group'
					)}
					appearance="subtle"
					size="xs"
					type="button"
				>
					Used&nbsp;
					<span className={isOverWarningThreshold ? 'text-inherit' : 'text-grey-1000 group-hover:text-inherit'}>
						{usedInMin}
					</span>
					&nbsp;/ {limitInMin} min
				</Button>
			</Link>
			<div className={clsx('border-r-[1px] border-grey-300', 'ml-3 mr-5 h-6')} />
		</>
	)
}

export default UserUsageWithBorder
