import type { IndexResponse } from 'apis/indexes'
import type { Sample } from 'utils/response'

import { getQueryClient } from 'utils/providers/QueryProvider'

import { ApiType, getInstance } from './instances'

const isSampleIndex = (indexId: string): Promise<boolean> =>
	getQueryClient().fetchQuery({
		queryKey: ['is_sample_index', indexId],
		queryFn: async (): Promise<boolean> =>
			getInstance(ApiType.ROUTE)
				.get<IndexResponse & Sample>(`/indexes/${indexId}`)
				.then(({ data: { sample } }) => sample ?? false)
				.catch(() => false),
		staleTime: Infinity
	})

export async function getInstanceByIndexId(indexId?: string) {
	if (indexId == null) return getInstance(ApiType.TWELVE_LABS)
	const isSample = await isSampleIndex(indexId)
	return isSample ? getInstance(ApiType.SAMPLE) : getInstance(ApiType.TWELVE_LABS)
}
