import type { TypographyProps } from '@mui/material/Typography'
import type { Theme } from '@mui/material/styles'
import type { SystemStyleObject } from '@mui/system/styleFunctionSx'
import type { ButtonProps } from '@twelvelabs/tds'
import type { FlagName } from 'utils/unleash'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useFlag } from '@unleash/nextjs/client'
import clsx from 'clsx'
import Classify from 'components/svg/Classify'
import Generate from 'components/svg/Generate'
import SearchLeft from 'components/svg/SearchLeft'
import { pathName, Play } from 'constants/paths'
import ChevronDownIcon from 'public/icons/chevron-down.svg'
import React, { useCallback, useMemo, useState } from 'react'
import { capitalize } from 'utils/formatString'
import useIsMobile from 'utils/hooks/useIsMobile'
import useRouter from 'utils/hooks/useRouter'

const Tab = styled('a')(({ theme }) => ({
	width: theme.spacing(92),
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	textDecoration: 'none'
}))

const TabLabel = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'selected'
})<TypographyProps & { selected?: boolean }>(({ theme, selected }) => ({
	...theme.typography.body1,
	color: selected ? theme.palette.secondary.main : theme.palette.text.primary,
	transition: 'color 0.24s'
}))

interface PlayType {
	icon: JSX.Element
	path: string | ((indexId?: string) => string)
	type: Play
	desktopOnly?: boolean
	flag?: FlagName
}

const PLAY_TYPES: PlayType[] = [
	{
		icon: (
			<SearchLeft fontSize={20} sx={({ palette }): SystemStyleObject<Theme> => ({ color: palette.text.primary })} />
		),
		path: pathName.search,
		type: Play.SEARCH
	},
	{
		icon: <Classify fontSize={20} sx={({ palette }): SystemStyleObject<Theme> => ({ color: palette.text.primary })} />,
		path: pathName.classify,
		type: Play.CLASSIFY,
		desktopOnly: true
	},
	{
		icon: <Generate fontSize={20} sx={({ palette }): SystemStyleObject<Theme> => ({ color: palette.text.primary })} />,
		path: pathName.generate,
		type: Play.GENERATE
	}
]

const PlayTypeMenuItem = ({
	icon,
	path,
	type,
	playType,
	indexId,
	flag,
	desktopOnly
}: PlayType & { indexId?: string; playType?: Play }) => {
	const isMobile = useIsMobile()
	const router = useRouter()

	const isFlagEnabled = !useFlag(flag ?? '')
	const isFlagDisabled = !!flag && !isFlagEnabled

	const onItemClick = () => router.push({ pathname: typeof path === 'function' ? path(indexId) : path })

	return (
		<MenuItem
			onClick={onItemClick}
			disabled={isFlagDisabled || (desktopOnly && isMobile) || playType === type}
			sx={{ ...(playType === type && { opacity: '1 !important' }) }}
		>
			<Tab sx={{ width: '100%', justifyContent: 'flex-start' }}>
				{icon}
				<TabLabel ml={4}>{capitalize(type)}</TabLabel>
				{isFlagDisabled ? (
					<p className={clsx('my-0 text-body3 text-grey-600', 'ml-3')}>Coming soon</p>
				) : (
					desktopOnly && isMobile && <p className={clsx('my-0 text-body3 text-grey-600', 'ml-3')}>Desktop only</p>
				)}
			</Tab>
		</MenuItem>
	)
}

const defaultButton = (
	<button
		type="button"
		className={clsx(
			'cursor-pointer border-none bg-transparent px-0',
			'text-black',
			'flex items-center justify-center gap-1',
			'font-denton text-2xl font-medium capitalize'
		)}
	/>
)

const IndexPlaySelect = ({
	indexId,
	label,
	playType,
	button: btnProp = defaultButton
}: {
	indexId: string | undefined
	label: string
	button?: JSX.Element
	playType?: Play
}): JSX.Element => {
	const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)

	const openMenu: React.MouseEventHandler<HTMLButtonElement> = useCallback((e) => setMenuEl(e.currentTarget), [])

	const closeMenu = () => setMenuEl(null)

	const button = useMemo(
		() =>
			React.isValidElement<ButtonProps>(btnProp) &&
			React.cloneElement(btnProp, {
				children: [
					label,
					<ChevronDownIcon key={label} className={clsx('text-inherit', 'h-5 w-5', menuEl && 'rotate-180')} />
				],
				onClick: openMenu
			}),
		[btnProp, label, menuEl, openMenu]
	)

	return (
		<>
			{button}
			<Menu
				open={Boolean(menuEl)}
				anchorEl={menuEl}
				onClose={closeMenu}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				MenuListProps={{ sx: { minWidth: 204 } }}
			>
				{PLAY_TYPES.map((type) => (
					<PlayTypeMenuItem key={type.type} playType={playType} indexId={indexId} {...type} />
				))}
			</Menu>
		</>
	)
}

export default IndexPlaySelect
