'use client'

import ListItemButton, { listItemButtonClasses } from '@mui/material/ListItemButton'
import { useFlag } from '@unleash/nextjs'
import clsx from 'clsx'
import { NAVBAR_HEIGHT } from 'constants/measurements'
import Routes from 'constants/routes'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import BillingFilledIcon from 'public/icons/billing-filled.svg'
import BillingIcon from 'public/icons/billing.svg'
import IntegrationFilledIcon from 'public/icons/integration-filled.svg'
import IntegrationIcon from 'public/icons/integration.svg'
import LockFilledIcon from 'public/icons/lock-filled.svg'
import LockIcon from 'public/icons/lock.svg'
import UsageFilledIcon from 'public/icons/usage-filled.svg'
import UsageIcon from 'public/icons/usage.svg'
import UserFilledIcon from 'public/icons/user-filled.svg'
import UserIcon from 'public/icons/user.svg'
import React from 'react'
import { FlagName } from 'utils/unleash'

export const dashboardMenus: {
	label: string
	path: string
	getIcon: (selected: boolean) => React.FC<React.SVGProps<SVGSVGElement>>
}[] = [
	{
		label: 'API key',
		path: Routes.API_KEY,
		getIcon: (selected) => (selected ? LockFilledIcon : LockIcon)
	},
	{
		label: 'Billing & plan',
		path: Routes.BILLING,
		getIcon: (selected) => (selected ? BillingFilledIcon : BillingIcon)
	},
	{
		label: 'Usage',
		path: Routes.USAGE,
		getIcon: (selected) => (selected ? UsageFilledIcon : UsageIcon)
	},
	{
		label: 'Integrations',
		path: Routes.INTEGRATIONS,
		getIcon: (selected) => (selected ? IntegrationFilledIcon : IntegrationIcon)
	},
	{
		label: 'Account settings',
		path: Routes.ACCOUNT_SETTINGS,
		getIcon: (selected) => (selected ? UserFilledIcon : UserIcon)
	}
]

interface Props {
	className?: string
}

const DashboardSideMenu = ({ className }: Props): JSX.Element => {
	const pathname = usePathname()
	const displayNewNav = useFlag(FlagName.UP_NAVIGATION)

	if (displayNewNav) {
		return (
			<div
				className={clsx(className, 'w-[212px] px-5 py-6', 'sticky')}
				style={{ height: `calc(100vh - ${NAVBAR_HEIGHT}px)`, top: NAVBAR_HEIGHT }}
			>
				<p className="text-xs font-medium leading-5 tracking-[1.92px] !text-grey-800">SETTINGS</p>
				<div className={clsx('flex flex-col gap-y-1', 'mt-2')}>
					{dashboardMenus.map((menu) => {
						const selected = pathname === menu.path
						const Icon = menu.getIcon(false)

						return (
							<Link key={menu.label} href={menu.path}>
								<div
									className={clsx('h-10 px-2', 'flex items-center gap-x-2', 'rounded', {
										'bg-moss_green-200 text-moss_green-800': selected,
										'text-grey-800 hover:bg-grey-200': !selected
									})}
								>
									<Icon color="currentColor" className={clsx('h-5 w-5')} />
									<p className={clsx('text-subtitle2 font-medium', selected ? '!text-secondary' : '!text-grey-800')}>
										{menu.label}
									</p>
								</div>
							</Link>
						)
					})}
				</div>
			</div>
		)
	}

	return (
		<div
			className={clsx(className, 'w-[196px]', 'flex flex-col', 'py-2')}
			style={{ height: `calc(100vh - ${NAVBAR_HEIGHT}px)` }}
		>
			{dashboardMenus.map((menu) => {
				const selected = pathname === menu.path
				const Icon = menu.getIcon(selected)

				return (
					<Link key={menu.label} className="py-[6px] pl-2 pr-4" href={menu.path}>
						<ListItemButton
							selected={selected}
							sx={({ palette }) => ({
								[`& .${listItemButtonClasses.selected}`]: {
									background: palette.mossGreen[200]
								},
								color: selected ? palette.secondary.main : palette.grey[800],
								padding: '8px 0px 8px 8px'
							})}
						>
							<Icon color="currentColor" className={clsx('h-6 w-6')} />
							<p className={clsx('text-subtitle3', 'ml-3', selected && '!font-bold !text-secondary')}>{menu.label}</p>
						</ListItemButton>
					</Link>
				)
			})}
		</div>
	)
}

export default DashboardSideMenu
