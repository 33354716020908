'use client'

import { useFlag } from '@unleash/nextjs'
import clsx from 'clsx'
import React from 'react'
import useAuth0 from 'utils/hooks/useAuth0'
import useIsMobile from 'utils/hooks/useIsMobile'
import useIsOnboardingComplete from 'utils/hooks/useIsOnboardingComplete'
import { FlagName } from 'utils/unleash'

import NavbarButtons from './NavbarButtons'
import TaskStatusButton from './TaskStatusButton'
import UploadingStatusButton from './UploadingStatusButton'
import TabLinks from './common/TabLinks'
import UserInfoButton from './common/UserInfoButton'
import MobileMenuButton from './mobile/MobileMenuButton'
import { NavbarMenuType, useNavbarStore } from './navbarStore'

export interface NavbarMenuProps {
	hideTabs?: boolean
	hideUsage?: boolean
	/**
	 * Hide all menus that require fetching data from the API
	 * - Usage
	 */
	disableFetch?: boolean
}

const MobileNavbarMenu = ({ hideTabs, hideUsage, disableFetch }: NavbarMenuProps) => {
	const isOnboardingComplete = useIsOnboardingComplete()
	const isActivityPanelEnabled = useFlag(FlagName.ACTIVITY_PANEL)

	const { menu, open, close } = useNavbarStore()

	const isTaskStatusOpen = !isActivityPanelEnabled && menu === NavbarMenuType.TaskStatus

	return (
		<div className={clsx('flex items-center gap-x-3')}>
			{isActivityPanelEnabled ? (
				<TaskStatusButton
					open={menu === NavbarMenuType.TaskStatus}
					onOpen={() => open(NavbarMenuType.TaskStatus)}
					onClose={close}
				/>
			) : (
				<UploadingStatusButton
					open={menu === NavbarMenuType.TaskStatus}
					onOpen={() => open(NavbarMenuType.TaskStatus)}
					onClose={close}
				/>
			)}
			{!isTaskStatusOpen &&
				(isOnboardingComplete ? (
					<MobileMenuButton
						open={menu === NavbarMenuType.Menu}
						onOpen={() => open(NavbarMenuType.Menu)}
						onClose={close}
						hideTabs={hideTabs}
						hideUsage={disableFetch || hideUsage}
					/>
				) : (
					<UserInfoButton
						open={menu === NavbarMenuType.UserInfo}
						onOpen={() => open(NavbarMenuType.UserInfo)}
						onClose={close}
						hideTabs={hideTabs}
						hideUsage={disableFetch || hideUsage}
					/>
				))}
		</div>
	)
}

const DesktopNavbarMenu = ({ hideTabs, hideUsage, disableFetch }: NavbarMenuProps) => {
	const { menu, open, close } = useNavbarStore()
	const isActivityPanelEnabled = useFlag(FlagName.ACTIVITY_PANEL)

	return (
		<>
			{!hideTabs && (
				<div className={clsx('absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2', 'hidden tablet:block')}>
					<div className={clsx('flex items-center gap-x-4')}>
						<TabLinks />
					</div>
				</div>
			)}
			<div className={clsx('hidden items-center gap-x-0.5 tablet:flex', 'pr-2')}>
				<NavbarButtons />
				{isActivityPanelEnabled && (
					<TaskStatusButton
						open={menu === NavbarMenuType.TaskStatus}
						onOpen={() => open(NavbarMenuType.TaskStatus)}
						onClose={close}
					/>
				)}
				<UserInfoButton
					open={menu === NavbarMenuType.UserInfo}
					onOpen={() => open(NavbarMenuType.UserInfo)}
					onClose={close}
					hideTabs={hideTabs}
					hideUsage={disableFetch || hideUsage}
				/>
			</div>
		</>
	)
}

const NavbarMenu = ({ hideTabs, hideUsage, disableFetch }: NavbarMenuProps): JSX.Element => {
	const { error } = useAuth0()
	const hasErrorOnAuth = error != null

	const isMobile = useIsMobile()
	const Menu = isMobile ? MobileNavbarMenu : DesktopNavbarMenu

	return disableFetch ? (
		<Menu hideUsage hideTabs={hideTabs} />
	) : (
		<Menu hideUsage={hideUsage || hasErrorOnAuth} hideTabs={hideTabs || hasErrorOnAuth} />
	)
}

export default NavbarMenu
