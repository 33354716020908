import { useFlag } from '@unleash/nextjs'
import clsx from 'clsx'
import { NAVBAR_ID } from 'constants/elementIds'
import React from 'react'
import { FlagName } from 'utils/unleash'
import Navbar from 'widgets/Navbar'
import PageLayout from 'widgets/PageLayout'

const PageNavigation = ({ children }: { children: React.ReactNode }) => {
	const displayNewNav = useFlag(FlagName.UP_NAVIGATION)

	if (displayNewNav) {
		return (
			<PageLayout>
				<PageLayout.Side />
				<PageLayout.Content>
					<PageLayout.Top id={NAVBAR_ID} />
					{children}
				</PageLayout.Content>
			</PageLayout>
		)
	}

	return (
		<div className={clsx('flex flex-col', 'min-h-screen w-full', '[&>*:last-child]:flex-1')}>
			<Navbar id={NAVBAR_ID} />
			{children}
		</div>
	)
}

export default PageNavigation
