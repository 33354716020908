export const NAVBAR_HEIGHT = 64

export const PLAY_LAYOUT_SIDE_PANEL_WIDTH = 364
export const PLAY_LAYOUT_TOP_HEIGHT = 64
export const UP_PLAY_LAYOUT_SIDE_PANEL_WIDTH = 312
export const UP_PLAY_LAYOUT_TOP_HEIGHT = 72

export const breakpoints = {
	tablet: '900px',
	desktop: '1200px'
}
