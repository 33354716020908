import type { DoneTask, TaskResponse } from 'apis/tasks'

import CheckCircle from '@mui/icons-material/CheckCircle'
import { useSuspenseQuery } from '@tanstack/react-query'
import { refetchIndexTaskData, taskOptions } from 'apis/tasks'
import { HIGH_RATE_REFETCH_INTERVAL } from 'constants/task'
import { useEffect } from 'react'
import { getTLApiErrorData } from 'utils/error'
import withBoundary from 'utils/hocs/withBoundary'
import { GeneralErrorCode, type TaskErrorCode } from 'utils/response'

import { DynamicButton, UploadErrorMessage, UploadProgress } from '../common'

const useInvalidateOnFailed = ({ status, index_id }: Pick<TaskResponse, 'status' | 'index_id'>) => {
	useEffect(() => {
		if (status !== 'failed') return
		refetchIndexTaskData(index_id)
	}, [index_id, status])
}

const ValidatingStatus = ({ task }: { task: DoneTask }) => {
	const { data } = useSuspenseQuery({
		...taskOptions(task.taskId),
		refetchInterval({ state }) {
			if (state.data?.data.status === 'validating') return HIGH_RATE_REFETCH_INTERVAL
			return undefined
		}
	})

	useInvalidateOnFailed({ status: data.status, index_id: data.index_id })

	if (data.error_reason) {
		return <UploadErrorMessage error={{ code: GeneralErrorCode.UnexpectedError, message: data.error_reason }} />
	}
	if (data.status !== 'validating') {
		return (
			<DynamicButton onClick={task.clear} hover={{ label: 'Clear' }}>
				<CheckCircle fontSize="small" color="success" />
			</DynamicButton>
		)
	}
	return <UploadProgress value="Validating" />
}

export default withBoundary(
	ValidatingStatus,
	{
		fallbackRender({ error }) {
			const errorData = getTLApiErrorData<TaskErrorCode>({ error })
			return <UploadErrorMessage error={errorData} />
		}
	},
	{
		fallback: <UploadProgress />
	}
)
