/**
 * React component for conditionally wrapping children
 * credit: https://gist.github.com/kitze/23d82bb9eb0baabfd03a6a720b1d637f?permalink_comment_id=4209506#gistcomment-4209506
 */

import { type ReactNode, type FunctionComponent, createElement } from 'react'

interface Props<WP> {
	if?: boolean
	with: string | FunctionComponent<WP>
	wrapperProps: WP
	children: NonNullable<ReactNode>
}

const Wrap = <WP extends object>({ if: condition, with: wrapper, wrapperProps, children }: Props<WP>) =>
	condition ? createElement(wrapper, wrapperProps, [children]) : children

export default Wrap
