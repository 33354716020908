import type { FileId, UploadTask, VideoType } from '../types'
import type { IndexStatus } from 'utils/response'

import { datadogLogs } from '@datadog/browser-logs'
import axios from 'axios'
import produce from 'immer'
import { getTLApiErrorData } from 'utils/error'
import { checkRateLimitExceeded } from 'utils/rateLimit'
import { TaskErrorCode } from 'utils/response'
import { createStore, type StateCreator } from 'zustand'
import { devtools, subscribeWithSelector } from 'zustand/middleware'

import { UploadStatus } from '../types'
import { isFileType } from '../utils'

export interface UploadTasksStore {
	uploadTasks: UploadTask[]
	addUploadTasks: (indexId: string, videos: VideoType[]) => void
	updateUploadTasks: (
		params: Array<
			| { fileId: FileId; status: UploadStatus.Uploading }
			| { fileId: FileId; status?: never; progress: number }
			| { fileId: FileId; status: UploadStatus.Done; taskId: string }
			| { fileId: FileId; status: UploadStatus.Failed; error: Error }
		>
	) => void
	clearUploadTasks: (
		params:
			| { indexId: string; fileId?: never; statuses?: never }
			| { indexId?: never; fileId: FileId; statuses?: never }
			| { indexId?: never; fileId?: never; statuses: UploadStatus[] }
	) => void
}

const uploadingTasksStore: StateCreator<UploadTasksStore> = (set, get) => ({
	uploadTasks: [],
	addUploadTasks(indexId, videos) {
		const tasks = videos.map((video) => {
			const task: UploadTask = {
				...video,
				indexId,
				status: UploadStatus.Queued,
				cancel: () => get().clearUploadTasks({ fileId: video.id })
			}
			if (isFileType(task)) {
				task.blobUrl = URL.createObjectURL(task.file)
			}

			return task
		})

		set((state) => ({
			uploadTasks: [...tasks, ...state.uploadTasks]
		}))
	},
	updateUploadTasks(params) {
		set((state) => ({
			uploadTasks: produce(state.uploadTasks, (draft) => {
				params.forEach((param) => {
					const index = draft.findIndex((t) => t.id === param.fileId)
					if (index === -1) return

					const task = draft[index]

					if (task.status === UploadStatus.Queued && param.status === UploadStatus.Uploading) {
						const { cancel, ...taskBase } = task
						const controller = new AbortController()
						draft[index] = {
							...taskBase,
							status: UploadStatus.Uploading,
							signal: controller.signal,
							cancel() {
								controller.abort()
							}
						}
						return
					}

					if (task.status === UploadStatus.Uploading && param.status == null) {
						task.progress = param.progress
						return
					}

					if (task.status === UploadStatus.Uploading && param.status === UploadStatus.Done) {
						const { progress, signal, cancel, ...taskBase } = task
						draft[index] = {
							...taskBase,
							status: UploadStatus.Done,
							taskId: param.taskId,
							clear() {
								get().clearUploadTasks({ fileId: taskBase.id })
							}
						}
					}

					if (task.status === UploadStatus.Uploading && param.status === UploadStatus.Failed) {
						const { progress, signal, cancel, ...taskBase } = task
						cancel()

						// eslint-disable-next-line prefer-const
						let { code, message } = axios.isCancel(param.error)
							? { code: TaskErrorCode.Canceled, message: 'Upload canceled by user' }
							: getTLApiErrorData<TaskErrorCode>({ error: param.error })

						const { rateLimitExceeded, rateLimitMessage } = checkRateLimitExceeded(param.error)
						if (rateLimitExceeded && rateLimitMessage) {
							message = rateLimitMessage
						}

						draft[index] = {
							...taskBase,
							status: UploadStatus.Failed,
							code,
							message,
							clear() {
								get().clearUploadTasks({ fileId: taskBase.id })
							}
						}

						datadogLogs.logger.error(code, taskBase, param.error)
					}
				})
			})
		}))
	},
	clearUploadTasks({ indexId, fileId, statuses }) {
		set((state) => ({
			uploadTasks: state.uploadTasks.filter((task) => {
				const isMatch = task.indexId === indexId || task.id === fileId || statuses?.includes(task.status)
				if (!isMatch) return true

				if (isFileType(task)) {
					URL.revokeObjectURL(task.blobUrl)
				}
				if (task.status === UploadStatus.Uploading) {
					task.cancel()
				}

				return false
			})
		}))
	}
})

export interface CompletedTask {
	taskId: string
	videoId: string
	status: Extract<IndexStatus, 'ready' | 'failed'>
}

export interface CompletedTasksStore {
	completedTasks: { [indexId: string]: CompletedTask[] }
	addCompletedTask: (indexId: string, task: CompletedTask) => void
	clearCompletedTasks: (indexId?: string, taskId?: string) => void
}

const completedTasksStore: StateCreator<CompletedTasksStore> = (set) => ({
	completedTasks: {},
	addCompletedTask(indexId, data) {
		set((state) => ({
			completedTasks: produce(state.completedTasks, (draft) => {
				if (!draft[indexId]) {
					draft[indexId] = []
				}
				draft[indexId].push(data)
			})
		}))
	},
	clearCompletedTasks(indexId, taskId) {
		set((state) => ({
			/* eslint-disable consistent-return */
			completedTasks: produce(state.completedTasks, (draft) => {
				if (indexId == null) return {}

				if (draft[indexId] == null) return

				if (taskId == null) {
					delete draft[indexId]
					return
				}

				draft[indexId] = draft[indexId].filter((task) => task.taskId !== taskId)
				if (draft[indexId].length === 0) {
					delete draft[indexId]
				}
			})
			/* eslint-enable consistent-return */
		}))
	}
})

interface UsageAlertDialogStore {
	isUsageAlertDialogOpen: boolean
	closeUsageAlertDialog: () => void
}

const usageAlertDialogStore: StateCreator<UsageAlertDialogStore> = (set) => ({
	isUsageAlertDialogOpen: false,
	closeUsageAlertDialog() {
		set({ isUsageAlertDialogOpen: false })
	}
})

const tasksStore = createStore<UploadTasksStore & CompletedTasksStore & UsageAlertDialogStore>()(
	devtools(
		subscribeWithSelector((...methods) => ({
			...uploadingTasksStore(...methods),
			...completedTasksStore(...methods),
			...usageAlertDialogStore(...methods)
		})),
		{ name: 'tasksStore' }
	)
)

export default tasksStore
