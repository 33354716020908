import clsx from 'clsx'
import React from 'react'

interface SectionLayoutProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
	title: JSX.Element | string
	fullWidth?: boolean
	addons?: React.ReactNode
	stretchChildren?: boolean
}

const SectionLayout = ({
	title,
	fullWidth,
	children,
	addons,
	stretchChildren = false,
	className,
	...props
}: SectionLayoutProps): JSX.Element => (
	<div
		className={clsx(
			className,
			'flex items-start gap-6',
			'border border-grey-200',
			'p-6',
			'bg-white',
			fullWidth && 'w-full'
		)}
		{...props}
	>
		<div className={clsx('flex items-center justify-between', fullWidth ? 'w-full' : 'min-w-[180px] basis-[24%]')}>
			{React.isValidElement(title) ? (
				title
			) : (
				<p className={clsx('whitespace-pre-wrap text-subtitle1 text-grey-1000')}>{title}</p>
			)}
			{addons}
		</div>
		{children && (
			// eslint-disable-next-line no-nested-ternary
			<div className={clsx(fullWidth ? 'w-full' : stretchChildren ? 'basis-[76%] ' : 'basis-auto')}>{children}</div>
		)}
	</div>
)

export default SectionLayout
