import { Button } from '@twelvelabs/tds'
import React from 'react'
import useIsMobile from 'utils/hooks/useIsMobile'
import { MAX_VIDEO_SIZE_TEXT } from 'utils/hooks/useVideoDropzoneState'

interface Props {
	isOverMaxSize: boolean
	isDragFailed: boolean
	isDragAccept: boolean
}

const DropzoneCenterText = ({ isOverMaxSize, isDragAccept, isDragFailed }: Props): JSX.Element => {
	const isMobile = useIsMobile()

	if (isOverMaxSize) {
		return (
			<div className="text-body2 font-medium text-red-500">
				You can only upload files less than {MAX_VIDEO_SIZE_TEXT}
			</div>
		)
	}

	if (isDragFailed) {
		return <div className="text-body2 font-medium text-red-500">You can only upload video files</div>
	}

	if (isDragAccept) {
		return <div className="text-body2 font-medium text-moss_green-800">Drop here!</div>
	}

	if (isMobile) {
		return (
			<Button size="sm" appearance="secondary">
				Browse files
			</Button>
		)
	}

	return (
		<p className="text-center text-body2 text-grey-700">
			Drag and drop files here
			<br />
			or <span className="text-subtitle3 text-moss_green-800 hover:underline">Browse files</span>
		</p>
	)
}

export default DropzoneCenterText
