import muiSvg from './muiSvg'

const Menu = muiSvg(
	(props): JSX.Element => (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect x="3" y="5.25" width="18" height="1.5" fill="currentColor" />
			<rect x="3" y="11.25" width="18" height="1.5" fill="currentColor" />
			<rect x="3" y="17.25" width="18" height="1.5" fill="currentColor" />
		</svg>
	)
)

export default Menu
