'use client'

import type { DialogProps } from 'components/Dialog'

import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import clsx from 'clsx'
import Dialog from 'components/Dialog'
import { useFormik } from 'formik'
import { useCallback, useMemo, useState } from 'react'

import CancelConfirmationModal from './CancelConfirmationModal'
import CancelDescriptionModal from './CancelDescriptionModal'
import {
	CancelReasonBetterSolution,
	CancelReasonDifficultToUse,
	CancelReasonFeaturesMissing,
	CancelReasonOther,
	CancelReasonUseCase
} from './CancelReasons'

type CancelPlanModalProps = Omit<DialogProps, 'action'>

type CancelReason = {
	reason: string
	title?: string
	element?: (setDescription: (reason: string) => void) => React.ReactNode
}

const cancelReasons: CancelReason[] = [
	{
		reason: 'I found a better solution',
		title: 'What other solution have you found?',
		element: (setDescription) => <CancelReasonBetterSolution setDescription={setDescription} />
	},
	{
		reason: `It doesn't support my use case`,
		title: 'What is use case?',
		element: (setDescription) => <CancelReasonUseCase setDescription={setDescription} />
	},
	{
		reason: `Pricing is too high`
	},
	{
		reason: `Features I need are missing`,
		title: 'What feature are you looking for?',
		element: (setDescription) => <CancelReasonFeaturesMissing setDescription={setDescription} />
	},
	{
		reason: `It is too difficult to use`,
		title: 'What part of the product was hard to use?',
		element: (setDescription) => <CancelReasonDifficultToUse setDescription={setDescription} />
	},
	{
		reason: `Other`,
		title: 'What can we do to improve?',
		element: (setDescription) => <CancelReasonOther setDescription={setDescription} />
	}
]

const CancelPlanModalWithSteps = ({ onClose, open, ...props }: CancelPlanModalProps): JSX.Element => {
	const [isSubmitted, setIsSubmitted] = useState(false)
	const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false)
	const formik = useFormik({
		initialValues: {
			reason: undefined,
			description: undefined
		},
		validate: ({ reason, description }) => {
			if (!reason || !description) {
				return new Error('Please select a reason')
			}
			return undefined
		},
		onSubmit: () => {
			setIsSubmitted(true)
		}
	})

	const onRadioChange = useCallback(
		(_: React.ChangeEvent<HTMLInputElement>, value: string): void => {
			formik.setFieldValue('reason', value)
		},
		[formik]
	)

	const resetFields = useCallback(() => {
		formik.resetForm()
		setIsSubmitted(false)
		setIsDescriptionModalOpen(false)
	}, [formik])

	const cancelReasonMap = useMemo(
		() => cancelReasons.find((v) => v.reason === formik.values.reason),
		[formik.values.reason]
	)

	const onActionClick = useCallback(() => {
		if (cancelReasonMap?.element == null) {
			formik.handleSubmit()
		}
		setIsDescriptionModalOpen(true)
	}, [cancelReasonMap?.element, formik])

	const dialogTitle = useMemo(() => {
		if (cancelReasonMap?.title) {
			return cancelReasonMap.title
		}
		return 'What can we do to improve?'
	}, [cancelReasonMap?.title])

	const isReasonSelected = !!formik.values.reason

	return (
		<>
			<Dialog
				{...props}
				title="Why are you cancelling your plan?"
				action={{
					label: 'Next',
					onClick: onActionClick,
					disabled: !isReasonSelected
				}}
				open={open && !isDescriptionModalOpen}
				onClose={(): void => {
					onClose?.({}, 'backdropClick')
					if (isSubmitted) {
						resetFields()
					}
				}}
			>
				<div className={clsx('min-w-[400px]')}>
					<FormControl>
						<RadioGroup onChange={onRadioChange}>
							{cancelReasons.map(({ reason }) => (
								<FormControlLabel
									value={reason}
									control={<Radio />}
									label={<p className={clsx('text-body1 !text-grey-1000')}>{reason}</p>}
									key={reason}
								/>
							))}
						</RadioGroup>
					</FormControl>
				</div>
			</Dialog>
			<CancelDescriptionModal
				open={isDescriptionModalOpen && !isSubmitted}
				nextStep={(): void => {
					formik.handleSubmit()
				}}
				title={dialogTitle}
				onClose={(): void => {
					setIsDescriptionModalOpen(false)
					onClose?.({}, 'backdropClick')
					resetFields()
				}}
			>
				{cancelReasonMap?.element &&
					cancelReasonMap?.element((description: string) => {
						formik.setFieldValue('description', description)
					})}
			</CancelDescriptionModal>
			<CancelConfirmationModal
				open={isSubmitted}
				reason={formik.values.reason}
				description={formik.values.description}
				onClose={(e, r): void => {
					onClose?.(e, r)
					resetFields()
				}}
			/>
		</>
	)
}

export default CancelPlanModalWithSteps
