import type { TaskStatusResponse } from './options'
import type { IndexIdsByStatusResponse, VideoFileType, VideoType, VideoUrlType } from './types'
import type { AxiosResponse } from 'axios'
import type { GeneralErrorCode, IndexStatus } from 'utils/response'

import { getIndexKey } from 'apis/indexes/keys'
import { getQueryClient } from 'utils/providers/QueryProvider'
import produce from 'immer'
import debounce from 'lodash/debounce'
import { TaskErrorCode } from 'utils/response'

import { getIndexIdsByStatusKey, getTasksKey, getTasksStatusKey } from './keys'

export const isFileType = (video: VideoType): video is VideoFileType => 'file' in video
export const isUrlType = (video: VideoType): video is VideoUrlType => 'url' in video

export const addDataToIndexIdsByStatus = (indexId: string, status: IndexStatus) => {
	getQueryClient().setQueryData<AxiosResponse<IndexIdsByStatusResponse>>(getIndexIdsByStatusKey(), (data) =>
		produce(data, (draft) => {
			if (draft?.data) {
				draft.data.count[status] += 1
				if (!draft.data.index_ids.some((id) => id === indexId)) {
					draft.data.index_ids.unshift(indexId)
				}
			}
		})
	)
}

export const addCountToTaskStatus = (indexId: string, status: IndexStatus) => {
	getQueryClient().setQueryData<AxiosResponse<TaskStatusResponse>>(getTasksStatusKey(indexId), (data) =>
		produce(data, (draft) => {
			if (draft?.data) {
				draft.data[status] += 1
				draft.data.total_result += 1
			}
		})
	)
}

const indexTaskDataQueryKey = (indexId: string) => ({
	index: getIndexKey(indexId),
	tasks: getTasksKey(indexId),
	tasksStatus: getTasksStatusKey(indexId),
	indexIdsByStatus: getIndexIdsByStatusKey()
})
type IndexTaskDataQueryKey = keyof ReturnType<typeof indexTaskDataQueryKey>
export const refetchIndexTaskData = debounce(
	async (indexId: string, keys: IndexTaskDataQueryKey[] = ['index', 'tasks', 'tasksStatus', 'indexIdsByStatus']) => {
		const queryKeys = indexTaskDataQueryKey(indexId)
		return Promise.all(keys.map((key) => getQueryClient().refetchQueries({ queryKey: queryKeys[key] })))
	},
	500
)

export const errorByCode = (code: TaskErrorCode | GeneralErrorCode) => {
	switch (code) {
		case TaskErrorCode.Canceled:
			return 'Cancelled'
		case TaskErrorCode.VideoResolutionTooHigh:
			return 'Resolution too high'
		case TaskErrorCode.VideoResolutionTooLow:
			return 'Resolution too low'
		case TaskErrorCode.VideoDurationTooLong:
			return 'Duration too long'
		case TaskErrorCode.VideoDurationTooShort:
			return 'Duration too short'
		case TaskErrorCode.VideoAudioTrackNotExists:
			return 'No audio'
		case TaskErrorCode.UsageLimitExceeded:
			return 'Exceeds index limit'
		case TaskErrorCode.VideoFileBroken:
			return 'File broken'
		case TaskErrorCode.ExternalVideoInvalid:
		case TaskErrorCode.ExternalVideoNotEmbeddable:
		case TaskErrorCode.ExternalVideoUnavailable:
		case TaskErrorCode.ExternalVideoLiveUnsupported:
		case TaskErrorCode.ExternalVideoDomainUnsupported:
			return 'Invalid video URL'
		case TaskErrorCode.TooManyRequests:
			return 'Exceeds rate limit'
		default:
			return 'Failed'
	}
}
