import muiSvg from './muiSvg'

const Generate = muiSvg(
	(props): JSX.Element => (
		<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.4697 1.46967L19.3483 3.59099L20.409 4.65165L22.5303 2.53033L21.4697 1.46967ZM13.4277 1L13.4277 4L14.9277 4L14.9277 1L13.4277 1ZM17.1429 5.33839L14.4869 6.23907C14.2531 6.31834 13.9953 6.27751 13.7975 6.12987L11.5498 4.45254L10.7483 3.85441C10.2501 3.48263 9.54187 3.8435 9.54982 4.46507L9.59844 8.2694C9.6016 8.51622 9.4831 8.7488 9.28155 8.89133L6.99175 10.5107L6.17521 11.0881C5.66768 11.447 5.79202 12.2321 6.38563 12.4167L7.34057 12.7135L7.35355 12.7175L1 19.0711L4.53553 22.6066L11.1266 16.0156L11.3448 16.7177L11.6417 17.6727C11.8262 18.2663 12.6113 18.3907 12.9702 17.8831L13.5476 17.0667L15.167 14.7768C15.3096 14.5753 15.5421 14.4568 15.789 14.4599L19.5933 14.5085C20.2149 14.5165 20.5757 13.8082 20.204 13.31L19.6059 12.5086L17.9285 10.2609C17.7809 10.063 17.74 9.80522 17.8193 9.57145L18.72 6.91546L19.0411 5.96835C19.2408 5.37965 18.6787 4.81759 18.09 5.01722L17.1429 5.33839ZM3.82843 19.0711L9.51126 13.3882L10.0188 13.546C10.1963 13.6012 10.3448 13.7195 10.4386 13.8751L4.53554 19.7782L3.82843 19.0711ZM16.3337 7.72467L15.1292 8.13312C14.272 8.4238 13.3267 8.27408 12.6013 7.73275L11.582 6.97208L11.5983 8.24383C11.6098 9.14887 11.1753 10.0016 10.4363 10.5243L9.39793 11.2586L10.6125 11.6362C11.4768 11.9048 12.1535 12.5816 12.4222 13.4459L12.7997 14.6604L13.5341 13.622C14.0567 12.883 14.9095 12.4485 15.8145 12.4601L17.0863 12.4763L16.3256 11.457C15.7843 10.7316 15.6346 9.78632 15.9252 8.92916L16.3337 7.72467ZM20 9.25L23 9.25V10.75L20 10.75V9.25Z"
				fill="currentColor"
			/>
		</svg>
	)
)

export default Generate
