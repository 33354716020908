import muiSvg from './muiSvg'

const TextInVideo = muiSvg(
	(props): JSX.Element => (
		<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2 4.5C2 3.11929 3.11929 2 4.5 2H7V4.5H4.5V7H2V4.5ZM22 19.5C22 20.8807 20.8807 22 19.5 22H17V19.5H19.5V17H22V19.5ZM19.5 2C20.8807 2 22 3.11929 22 4.5V7H19.5V4.5H17V2H19.5ZM2 19.5C2 20.8807 3.11929 22 4.5 22H7V19.5H4.5L4.5 17H2V19.5ZM8.03175 9.43019V6.85373H16.2857V9.43019H13.5099V18.4148H10.8075V9.43019H8.03175Z"
				fill="currentColor"
			/>
		</svg>
	)
)

export default TextInVideo
