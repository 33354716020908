import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import { forwardRef } from 'react'

const TaskStatusItemSkeleton = forwardRef<HTMLDivElement>((_, ref) => (
	<div ref={ref} className={clsx('flex items-center', 'gap-x-2')}>
		<Skeleton variant="rounded" className={clsx('h-6 w-11')} />
		<Skeleton variant="rounded" className={clsx('h-5 w-40')} />
	</div>
))

export default TaskStatusItemSkeleton
