import type { EventResponse } from './types'

import { stream } from 'fetch-event-stream'
import { StatusCodes } from 'http-status-codes'
import { useCallback, useRef } from 'react'
import { useMount, useUnmount } from 'react-use'
import useAuth0 from 'utils/hooks/useAuth0'

// eslint-disable-next-line import/prefer-default-export
export function useEvents(callback: (event: EventResponse) => void) {
	const { getAccessTokenSilently } = useAuth0()
	const controllerRef = useRef<AbortController>()

	const getEvents = useCallback(
		async (token: string) => {
			controllerRef.current?.abort()
			controllerRef.current = new AbortController()
			try {
				const events = await stream(`${process.env.NEXT_PUBLIC_API_BASE_URL}/tl/notification/events`, {
					headers: {
						Authorization: `Bearer ${token}`,
						'Cache-Control': 'no-cache'
					},
					signal: controllerRef.current.signal
				})

				// eslint-disable-next-line no-restricted-syntax
				for await (const event of events) {
					if (event.data != null) {
						callback(JSON.parse(event.data))
					}
				}
			} catch (e) {
				const reconnect = () => {
					console.error('Reconnecting notification server...')
					controllerRef.current?.abort()
					getEvents(token)
				}

				if (e instanceof Response) {
					if (e.status === StatusCodes.GATEWAY_TIMEOUT) {
						reconnect()
					}
				}
			}
		},
		[callback]
	)

	useMount(() => {
		getAccessTokenSilently().then(getEvents)
	})

	useUnmount(() => {
		controllerRef.current?.abort()
	})
}
