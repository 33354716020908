import type { FileId, VideoType } from 'apis/tasks'

import CloseIcon from '@mui/icons-material/CloseRounded'
import Tooltip from '@mui/material/Tooltip'
import { isFileType, isUrlType } from 'apis/tasks'
import clsx from 'clsx'
import { useIsOverflow } from 'components/OverflowText'
import Thumbnail from 'components/Thumbnail'
import VideoThumbnailIcon from 'public/icons/video-thumbnail.svg'
import React from 'react'

interface Props {
	video: VideoType
	onRemove: (id: FileId) => void
}

const getVideoTitle = (video: VideoType) => {
	if (isFileType(video)) return video.file.name
	if (isUrlType(video)) return video.title
	throw new Error('Invalid video type')
}

const UploadVideoItem = ({ video, onRemove }: Props): JSX.Element => {
	const videoTitle = getVideoTitle(video)
	const [isOverflow, titleRef] = useIsOverflow()

	return (
		<div
			className={clsx(
				'w-full md:w-[112px]',
				'mr-0 md:mr-2',
				'mt-0 md:mt-2',
				'relative',
				'flex items-center gap-2',
				'md:block'
			)}
			key={video.id}
		>
			<div
				className={clsx('h-8 md:h-16', 'w-[56px] md:w-[112px]', 'overflow-hidden rounded', 'relative', 'bg-grey-200')}
			>
				{isFileType(video) && (
					<>
						<VideoThumbnailIcon
							className={clsx(
								'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
								'h-2/5 min-h-[14px] w-auto',
								'text-grey-500'
							)}
						/>
						<video
							className={clsx('relative z-1', 'h-[inherit] w-full')}
							preload="auto"
							src={`${video.blobUrl}#t=0.001`}
							muted
						/>
					</>
				)}
				{isUrlType(video) && (
					<Thumbnail src={video.thumbnailUrl} alt="youtube thumbnail image" objectFit="cover" unoptimized />
				)}
			</div>
			<Tooltip title={isOverflow && videoTitle}>
				<span
					ref={titleRef}
					className={clsx(
						'block flex-1',
						'truncate break-words',
						'text-body2 text-grey-900 md:text-body3',
						'mt-0 md:mt-1'
					)}
				>
					{videoTitle}
				</span>
			</Tooltip>
			<button
				className={clsx(
					'h-5 w-5 p-0',
					'static z-1',
					'-right-2 -top-2 md:absolute',
					'flex items-center justify-center',
					'bg-grey-1000 opacity-80',
					'rounded-full border-none',
					'cursor-pointer'
				)}
				type="button"
				onClick={() => onRemove(video.id)}
			>
				<CloseIcon className="text-white" fontSize="inherit" />
			</button>
		</div>
	)
}

export default UploadVideoItem
