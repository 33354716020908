import muiSvg from './muiSvg'

const Blank = muiSvg((props) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.6 3.6V12.4H12.4V3.6H3.6ZM2.6 1.6C2.04772 1.6 1.6 2.04771 1.6 2.6V13.4C1.6 13.9523 2.04772 14.4 2.6 14.4H13.4C13.9523 14.4 14.4 13.9523 14.4 13.4V2.6C14.4 2.04771 13.9523 1.6 13.4 1.6H2.6Z"
			fill="currentColor"
		/>
	</svg>
))

export default Blank
