'use client'

import type { CardValidation } from 'app/dashboard/billing/utils/schema'
import type { useFormik } from 'formik'

import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import CircularProgress from '@mui/material/CircularProgress'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { Button } from '@twelvelabs/tds'
import { paymentMethodsOptions } from 'apis/billing'
import { isCardSavedOptions } from 'apis/user'
import { getCountryName } from 'app/dashboard/billing/utils/countries'
import InfoWithLabel from 'app/src/components/InfoWithLabel'
import clsx from 'clsx'
import { useCallback, useEffect } from 'react'
import withBoundary from 'utils/hocs/withBoundary'

import CardDisplay from './CardDisplay'
import { RegisterCard } from '../../RegisterCard'

const PaymentSection = ({
	formik,
	isEditingCard,
	setIsEditingCard
}: {
	formik: ReturnType<typeof useFormik<CardValidation>>
	isEditingCard: boolean
	setIsEditingCard: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => {
	const { data: hasCardInfo } = useSuspenseQuery(isCardSavedOptions())

	const { data: paymentMethods } = useQuery({
		...paymentMethodsOptions(),
		refetchOnMount: true,
		refetchOnReconnect: true
	})

	useEffect(
		() => () => {
			setIsEditingCard(false)
		},
		[setIsEditingCard]
	)

	const onEditClick = useCallback(() => {
		formik.setFieldValue('cardHolderName', paymentMethods?.name_on_card)
		setIsEditingCard((s) => !s)
	}, [formik, paymentMethods?.name_on_card, setIsEditingCard])

	return (
		<div className={clsx('h-full p-6', 'flex flex-col gap-6', 'border border-grey-200')}>
			<div className={clsx('flex items-center justify-between')}>
				<p className={clsx('text-subtitle2-bold')}>Payment info</p>
				{(hasCardInfo || isEditingCard) &&
					!formik.isSubmitting &&
					(isEditingCard ? (
						<Button type="button" size="xs" appearance="secondary" onClick={onEditClick}>
							<CloseIcon color="inherit" />
							Cancel
						</Button>
					) : (
						<Button type="button" size="xs" appearance="secondary" onClick={onEditClick}>
							<EditIcon color="inherit" />
							Edit
						</Button>
					))}
			</div>
			{isEditingCard || !hasCardInfo ? (
				<RegisterCard
					formik={formik}
					footer={
						<p className={clsx('text-body2 !text-grey-700', 'mt-3')}>
							By clicking the button below, I agree to authorize Twelve Labs to charge the amount according to the terms
							of Developer Plan
						</p>
					}
				/>
			) : (
				<>
					<InfoWithLabel label="Card information">
						<CardDisplay last4={paymentMethods?.last4 ?? ''} brand={paymentMethods?.brand ?? ''} />
					</InfoWithLabel>
					<InfoWithLabel label="Name on card">{paymentMethods?.name_on_card}</InfoWithLabel>
					<InfoWithLabel label="Billing address">{getCountryName(paymentMethods?.country_code) ?? ''}</InfoWithLabel>
					<p className={clsx('text-body2')}>
						By clicking the button below, I agree to authorize Twelve Labs to charge the amount according to the terms
						of Developer Plan
					</p>
				</>
			)}
		</div>
	)
}

export default withBoundary(PaymentSection, undefined, {
	fallback: (
		<div className={clsx('h-full', 'flex items-center justify-center', 'border border-grey-200')}>
			<CircularProgress />
		</div>
	)
})
