'use client'

import type { CardValidation } from '../../utils/schema'
import type { useFormik } from 'formik'

import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import { inputBaseClasses } from '@mui/material/InputBase'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import MUITextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import InfoWithLabel from 'app/src/components/InfoWithLabel'
import clsx from 'clsx'
import ReactCountryFlag from 'react-country-flag'

import { countryNames, getCountryCode } from '../../utils/countries'
import StripeCardElement from '../StripeCardElement'

const TextField = styled(MUITextField)(({ theme: { palette, spacing } }) => ({
	[`& .${outlinedInputClasses.root}`]: {
		height: spacing(40),
		borderRadius: 0,
		padding: '0 !important',
		'& fieldset': {
			border: `1px solid ${palette.grey[200]}`
		},
		[`&:hover fieldset`]: {
			borderColor: palette.grey[300]
		},
		[`&.${outlinedInputClasses.focused} fieldset`]: {
			border: `2px solid ${palette.primary.main}`
		}
	},
	[`& .${inputBaseClasses.input}, & .${autocompleteClasses.input}`]: {
		height: '100%',
		padding: `0 ${spacing(12)} !important`
	}
}))

const RegisterCard = ({
	formik: { handleChange, values, touched, errors, setFieldValue },
	footer
}: {
	formik: ReturnType<typeof useFormik<CardValidation>>
	footer?: React.ReactNode
}): JSX.Element => (
	<>
		<div className={clsx('flex flex-col gap-y-5')}>
			<InfoWithLabel label="Card information">
				<StripeCardElement />
			</InfoWithLabel>
			<InfoWithLabel label="Name on card">
				<FormControl fullWidth required>
					<TextField
						variant="outlined"
						type="text"
						onChange={handleChange}
						name="cardHolderName"
						value={values.cardHolderName}
						placeholder="Name on card"
						error={touched.cardHolderName && Boolean(errors.cardHolderName)}
						helperText={touched.cardHolderName && errors.cardHolderName}
					/>
				</FormControl>
			</InfoWithLabel>
			<InfoWithLabel label="Billing address">
				<FormControl fullWidth>
					<Autocomplete
						autoHighlight
						options={countryNames}
						renderOption={({ className, ...props }, option): React.ReactElement => (
							<li className={clsx(className, 'flex gap-x-2')} {...props} key={option}>
								<ReactCountryFlag countryCode={getCountryCode(option)} className={clsx('shrink-0')} />
								<p className={clsx('text-body1 !text-grey-1000')}>
									{option} ({getCountryCode(option)})
								</p>
							</li>
						)}
						value={values.address.country || null}
						isOptionEqualToValue={(option, value): boolean => {
							if (!value) return false
							return option === value
						}}
						onChange={(e, value): void => {
							setFieldValue('address.country', value ?? '')
						}}
						getOptionLabel={(option): string => option ?? ''}
						renderInput={(params): React.ReactElement => (
							<TextField
								{...params}
								variant="outlined"
								type="text"
								placeholder="Choose a country"
								inputProps={{
									...params.inputProps,
									autoComplete: 'new-password' // disable autocomplete and autofill
								}}
								error={touched.address?.country && Boolean(errors.address?.country)}
								helperText={touched.address?.country && errors.address?.country}
							/>
						)}
					/>
				</FormControl>
			</InfoWithLabel>
		</div>
		{footer}
	</>
)

export default RegisterCard
