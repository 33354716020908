import Popover from '@mui/material/Popover'
import clsx from 'clsx'
import AddIcon from 'public/icons/add.svg'
import IndexesIcon from 'public/icons/indexes.svg'
import VideoFileIcon from 'public/icons/video-file.svg'
import React, { useRef, useState } from 'react'
import { UploadVideosDialog } from 'widgets/Home/UploadVideos'
import IndexCreateDialog from 'widgets/IndexSection/IndexCreateDialog'

import HideMotionDiv from '../PageLayoutSide/components/HideMotionDiv'

const MenuItem = ({ children, ...restProps }: React.HTMLAttributes<HTMLButtonElement>) => (
	<button
		className={clsx(
			'flex items-center gap-x-2',
			'w-full px-3 py-[10px]',
			'text-body2 text-grey-1000',
			'[&>svg]:h-5 [&>svg]:w-5',
			'hover:bg-grey-100'
		)}
		type="button"
		{...restProps}
	>
		{children}
	</button>
)

const defaultOverlayStates = { popover: false, createModal: false, uploadModal: false }

interface Props {
	className?: string
	expanded: boolean
}

const NewMenuButton = ({ className, expanded }: Props) => {
	const triggerRef = useRef<HTMLButtonElement>(null)
	const [overlayStates, setOverlayStates] = useState(defaultOverlayStates)

	const onNewIndexClick = () => {
		setOverlayStates({ ...overlayStates, popover: false, createModal: true })
	}
	const onUploadVideosClick = () => {
		setOverlayStates({ ...overlayStates, popover: false, uploadModal: true })
	}
	const onButtonClick = () => setOverlayStates({ ...overlayStates, popover: true })
	const onClose = () => setOverlayStates(defaultOverlayStates)

	return (
		<>
			<button
				className={clsx(className, 'flex items-center justify-center', 'h-10 bg-grey-200', 'rounded-[40px]')}
				type="button"
				ref={triggerRef}
				onClick={onButtonClick}
			>
				<AddIcon className="h-6 w-6 text-grey-1000" />
				<HideMotionDiv width="fit-content" show={expanded}>
					<p className={clsx('pl-1', 'text-subtitle3 !text-grey-1000')}>New</p>
				</HideMotionDiv>
			</button>
			<Popover
				className={expanded ? undefined : 'ml-2'}
				open={overlayStates.popover}
				onClose={onClose}
				anchorEl={triggerRef.current}
				anchorOrigin={
					expanded ? { vertical: 'bottom', horizontal: 'center' } : { vertical: 'top', horizontal: 'right' }
				}
				transformOrigin={expanded ? { vertical: 'top', horizontal: 'center' } : { vertical: 'top', horizontal: 'left' }}
			>
				<div className="min-w-[172px] py-3">
					<MenuItem onClick={onNewIndexClick}>
						<IndexesIcon /> New index
					</MenuItem>
					<MenuItem onClick={onUploadVideosClick}>
						<VideoFileIcon /> Upload videos
					</MenuItem>
				</div>
			</Popover>
			<IndexCreateDialog open={overlayStates.createModal} onClose={onClose} />
			<UploadVideosDialog open={overlayStates.uploadModal} onClose={onClose} />
		</>
	)
}

export default NewMenuButton
