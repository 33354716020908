import clsx from 'clsx'
import CreateIndex from 'components/svg/CreateIndex'
import { CREATE_INDEX_CARD } from 'constants/elementIds'
import mixpanel from 'mixpanel-browser'
import { useCallback, useState } from 'react'

import IndexCreateDialog from '../IndexCreateDialog'

const IndexCreateButton = ({ outOfCredit }: { outOfCredit?: boolean }): JSX.Element => {
	const [openDialog, setOpenDialog] = useState(false)

	const onClick = useCallback(() => {
		mixpanel.track('click', { type: 'button', element: 'create_index' })
		setOpenDialog(true)
	}, [])

	const onClose = useCallback(() => {
		setOpenDialog(false)
	}, [])

	return (
		<>
			<button
				className={clsx(
					'w-full',
					'h-full min-h-[280px]',
					'bg-white disabled:bg-grey-100',
					'cursor-pointer disabled:cursor-not-allowed',
					'border border-solid border-grey-200 hover:border-primary disabled:border-grey-200'
				)}
				type="button"
				disabled={outOfCredit}
				onClick={onClick}
				data-intercom-target={CREATE_INDEX_CARD}
			>
				<div className={clsx('relative', 'h-full')}>
					<div className={clsx('absolute inset-0', 'flex flex-col items-center justify-center gap-2')}>
						<CreateIndex width={32} height={32} />
						<p className={clsx('text-subtitle1')}>{outOfCredit ? 'Out of credit' : 'Create an index'}</p>
						<p className={clsx('whitespace-pre-line text-body2', 'px-7')}>
							{outOfCredit
								? `You have no free credit left.\nPlease upgrade your plan.`
								: `By creating an index, you can upload your own videos and start building.`}
						</p>
					</div>
				</div>
			</button>
			<IndexCreateDialog open={openDialog} onClose={onClose} />
		</>
	)
}

export default IndexCreateButton
