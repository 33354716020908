import type { IndexResponse } from 'apis/indexes'

import { Button } from '@twelvelabs/tds'
import { useAddUploadQueue } from 'apis/tasks'
import clsx from 'clsx'
import VideoDurationMessage from 'components/VideoDurationMessage'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import React, { useState } from 'react'
import useUsageLimit from 'utils/hooks/useUsageLimit'
import useVideosToUpload from 'utils/hooks/useVideosToUpload'
import { NavbarMenuType, useOpenNavbarMenu } from 'widgets/Navbar'

import SelectMyIndex from './common/SelectMyIndex'
import VideoDropzone from './common/VideoDropzone'

const UploadVideos = (): JSX.Element => {
	const [index, setIndex] = useState<IndexResponse | undefined>()

	const { videos, cleanUpVideos, onVideoFilesAdded, onVideoRemoved } = useVideosToUpload()
	const { exceedUsageLimit, shouldDisableUpload } = useUsageLimit(index?._id, videos)

	const addUploadQueue = useAddUploadQueue()
	const openNavbarMenu = useOpenNavbarMenu()

	const onUpload = async () => {
		if (!index) return
		addUploadQueue(index._id, videos)
		cleanUpVideos()
		openNavbarMenu(NavbarMenuType.TaskStatus)
		mixpanel.track('click', { type: 'button', element: 'video_upload_banner' })
	}

	const isDropzoneDisabled = !index

	return (
		<div
			data-testid="upload-videos-section"
			className={clsx(
				'h-full',
				'tablet:transparent-scroll',
				'px-5 tablet:px-[30px]',
				'py-10 tablet:py-8',
				'bg-moss_green-50',
				'border border-moss_green-200'
			)}
		>
			<p className={clsx('text-heading7 !text-grey-900', 'mb-5', 'w-fit')}>Upload videos</p>
			<div className={clsx('flex flex-col justify-between gap-y-2 tablet:flex-row tablet:items-center', 'mb-3')}>
				<div className={clsx('flex flex-col gap-2 tablet:flex-row tablet:items-center')}>
					<p className="text-subtitle2 text-grey-1000">Select index</p>
					<SelectMyIndex index={index} onIndexChange={setIndex} />
				</div>
				<div className={clsx('flex flex-col gap-x-2 gap-y-1 tablet:flex-row tablet:items-center')}>
					<p className={clsx('text-body2')}>No videos to upload at the moment?</p>
					<Link href="#sample_indexes">
						<p className={clsx('text-body2 font-medium !text-secondary hover:underline')}>Try sample indexes</p>
					</Link>
				</div>
			</div>
			<VideoDropzone
				className={clsx('max-h-[244px] min-h-[194px]', 'mb-5 tablet:mb-3')}
				index={index}
				videos={videos}
				disabled={isDropzoneDisabled}
				onDrop={onVideoFilesAdded}
				onRemove={onVideoRemoved}
			/>
			<div className={clsx('flex items-center justify-end', 'flex-col tablet:flex-row', 'gap-1 tablet:gap-2')}>
				<VideoDurationMessage
					className={clsx('text-center tablet:text-left', 'max-w-[270px] tablet:max-w-none')}
					videos={videos}
					exceedUsageLimit={exceedUsageLimit}
				/>
				<Button
					className="w-full tablet:w-[104px]"
					size="sm"
					appearance="primary"
					type="button"
					onClick={onUpload}
					disabled={shouldDisableUpload}
				>
					Upload
				</Button>
			</div>
		</div>
	)
}

export default UploadVideos
