'use client'

import { EngineFamily, useIndexWithVideoQuery } from 'apis/indexes'
import clsx from 'clsx'
import { pathName } from 'constants/paths'
import Routes from 'constants/routes'
import mixpanel from 'mixpanel-browser'
import BookIcon from 'public/icons/book.svg'
import ClassifyIcon from 'public/icons/classify.svg'
import ExamplesIcon from 'public/icons/examples.svg'
import GenerateIcon from 'public/icons/generate.svg'
import HomeIcon from 'public/icons/home.svg'
import IndexesIcon from 'public/icons/indexes.svg'
import SearchIcon from 'public/icons/search.svg'
import SettingsIcon from 'public/icons/settings.svg'
import SidebarCloseIcon from 'public/icons/sidebar-close.svg'
import SidebarOpenIcon from 'public/icons/sidebar-open.svg'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import HelpSideMenuItem from './components/HelpSideMenuItem'
import LogoButtonWithBeta from './components/LogoButtonWithBeta'
import SideMenuItem from './components/SideMenuItem'
import NewMenuButton from '../components/NewMenuButton'

export interface Props {
	collapseInitially?: boolean
}

const PageLayoutSide = ({ collapseInitially }: Props) => {
	const [expanded, setExpanded] = useState(!collapseInitially)

	const marengoIndexWithVideo = useIndexWithVideoQuery({ engine_family: EngineFamily.MARENGO })
	const pegasusIndexWithVideo = useIndexWithVideoQuery({ engine_family: EngineFamily.PEGASUS })

	// Automatically open/close the side menu for better responsiveness
	const isNotEnoughSpaceForContent = useMediaQuery({ maxWidth: 1400 })
	useEffect(() => {
		const noDefaultCondition = typeof collapseInitially === 'undefined'
		if (noDefaultCondition) {
			setExpanded(!isNotEnoughSpaceForContent)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNotEnoughSpaceForContent])

	const createLinkTypeMixpanelHandler = (url: string) => () => {
		mixpanel.track('click', { type: 'link', element: 'navbar', url })
	}

	return (
		<nav
			id="page-layout-side"
			className={clsx(
				expanded ? 'min-w-[212px] max-w-[212px] px-5' : 'min-w-[64px] max-w-[64px] px-3',
				'h-screen transform transition-all duration-300 ease-in-out',
				'border-r-[1px] border-grey-200',
				'pb-10 pt-5',
				'hidden tablet:flex tablet:flex-col',
				'sticky top-0'
			)}
		>
			<LogoButtonWithBeta expanded={expanded} />
			<NewMenuButton className="mt-6" expanded={expanded} />
			<div className={clsx('my-6', 'flex flex-col gap-y-1')}>
				<SideMenuItem
					expanded={expanded}
					href={pathName.OVERVIEW}
					pattern={/^(\/)?$/}
					Icon={HomeIcon}
					tooltipLabel="Overview"
					onClick={createLinkTypeMixpanelHandler(pathName.OVERVIEW)}
				>
					Overview
				</SideMenuItem>
				<SideMenuItem
					expanded={expanded}
					href={pathName.indexes()}
					pattern={/^\/indexes(\/[^/]+)?$/}
					Icon={IndexesIcon}
					tooltipLabel="Indexes"
					onClick={createLinkTypeMixpanelHandler(pathName.indexes())}
				>
					Indexes
				</SideMenuItem>
				<SideMenuItem
					expanded={expanded}
					href={pathName.EXAMPLES}
					pattern={/^\/examples(\/.+)?/}
					Icon={ExamplesIcon}
					tooltipLabel="Examples"
					onClick={createLinkTypeMixpanelHandler(pathName.EXAMPLES)}
				>
					Examples
				</SideMenuItem>
			</div>
			<p
				className={clsx(
					'pl-2 opacity-0 transition-opacity duration-300',
					expanded && 'opacity-100',
					'text-xs font-medium leading-5 tracking-[1.92px] !text-grey-800'
				)}
			>
				PLAYGROUND
			</p>
			<div className={clsx('flex flex-col gap-y-1', 'mt-2')}>
				<SideMenuItem
					expanded={expanded}
					href={pathName.search(marengoIndexWithVideo?._id)}
					pattern={/^\/indexes\/[^/]+\/search\??$/}
					Icon={SearchIcon}
					tooltipLabel="Search"
					onClick={createLinkTypeMixpanelHandler('search')}
				>
					Search
				</SideMenuItem>
				<SideMenuItem
					expanded={expanded}
					href={pathName.generate(pegasusIndexWithVideo?._id)}
					pattern={/^\/indexes\/[^/]+\/generate\??$/}
					Icon={GenerateIcon}
					tooltipLabel="Generate"
					onClick={createLinkTypeMixpanelHandler('generate')}
				>
					Generate
				</SideMenuItem>
				<SideMenuItem
					expanded={expanded}
					href={pathName.classify(marengoIndexWithVideo?._id)}
					pattern={/^\/indexes\/[^/]+\/classify\??$/}
					Icon={ClassifyIcon}
					tooltipLabel="Classify"
					onClick={createLinkTypeMixpanelHandler('classify')}
				>
					Classify
				</SideMenuItem>
			</div>
			<div className={clsx('mb-6 mt-auto', 'flex flex-col gap-y-1')}>
				<SideMenuItem
					expanded={expanded}
					href={Routes.API_KEY}
					pattern={/^\/dashboard(\/[^/]+)?$/}
					target="_self"
					Icon={SettingsIcon}
					tooltipLabel="Settings"
					onClick={createLinkTypeMixpanelHandler(Routes.API_KEY)}
				>
					Settings
				</SideMenuItem>
				<SideMenuItem
					expanded={expanded}
					href={process.env.NEXT_PUBLIC_DOCS_URL}
					target="_blank"
					Icon={BookIcon}
					tooltipLabel="API docs"
					onClick={createLinkTypeMixpanelHandler(process.env.NEXT_PUBLIC_DOCS_URL)}
				>
					API docs
				</SideMenuItem>
				<HelpSideMenuItem expanded={expanded} />
			</div>
			<SideMenuItem
				expanded={expanded}
				Icon={expanded ? SidebarCloseIcon : SidebarOpenIcon}
				tooltipLabel="Expand"
				onClick={() => {
					setExpanded((prev) => {
						mixpanel.track('click', { type: 'accordion', element: 'navbar', value: prev ? 'close' : 'open' })
						return !prev
					})
				}}
			>
				Collapse
			</SideMenuItem>
		</nav>
	)
}

export default PageLayoutSide
