import type { UploadingTask } from 'apis/tasks'
import type { DialogProps } from 'components/Dialog'

import { UploadStatus, useUploadTasks } from 'apis/tasks'
import clsx from 'clsx'
import Dialog from 'components/Dialog'

const CancelAllUploadDialog = ({ open, onClose }: Pick<DialogProps, 'open' | 'onClose'>): JSX.Element => {
	const uploadingTasks = useUploadTasks((tasks) =>
		tasks.filter((task): task is UploadingTask => task.status === UploadStatus.Uploading)
	)

	const cancel = async () => {
		onClose?.({}, 'backdropClick')
		uploadingTasks.forEach((task) => {
			task.cancel()
		})
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			title="Cancel all uploads?"
			action={{ label: 'Cancel Upload', appearance: 'danger', onClick: cancel }}
		>
			<p className={clsx('text-body1 !text-grey-700')}>
				All video upload progress and data will be lost. Videos that finished uploading will not be deleted and remain
				in the index.
			</p>
		</Dialog>
	)
}

export default CancelAllUploadDialog
