import Add from '@mui/icons-material/Add'
import { MenuItem, Select, Skeleton, type SelectProps } from '@mui/material'
import { useInfiniteQuery } from '@tanstack/react-query'
import { Button } from '@twelvelabs/tds'
import { type IndexResponse, infiniteIndexesOptions } from 'apis/indexes'
import clsx from 'clsx'
import IndexMenuItem from 'components/IndexMenuItem'
import mixpanel from 'mixpanel-browser'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import useIsMobile from 'utils/hooks/useIsMobile'
import { IndexCreateDialog } from 'widgets/IndexSection'

import CreateIndexCTAButton from './CreateIndexCTAButton'

const ITEM_HEIGHT = 48
const CREATE_INDEX_BUTTON_HEIGHT = 44

const useEnsureSelectDefaultIndex = (
	indexes: IndexResponse[] | undefined,
	callback: (value: IndexResponse | undefined) => void
) => {
	useEffect(() => {
		callback(indexes?.[0])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [indexes?.length])
}

type Props = SelectProps & {
	index: IndexResponse | undefined
	onIndexChange: (index: IndexResponse | undefined) => void
}

const SelectMyIndex = ({ index, onIndexChange, ...selectProps }: Props) => {
	const isMobile = useIsMobile()

	const [openModal, setOpenModal] = useState(false)
	const onCreateIndexClick = () => {
		mixpanel.track('click', { type: 'choose_index', element: 'create_index' })
		setOpenModal(true)
	}

	const {
		data: indexes,
		fetchNextPage,
		isLoading,
		hasNextPage,
		error
	} = useInfiniteQuery({
		...infiniteIndexesOptions({ page_limit: 8, sort_by: 'created_at' }),
		select: ({ pages }) =>
			pages
				.flatMap((page) => page.data.data)
				.filter(({ expires_at }) => expires_at == null || new Date(expires_at).getTime() > new Date().getTime())
	})

	useEnsureSelectDefaultIndex(indexes, onIndexChange)

	const { ref } = useInView({ rootMargin: '120px', onChange: (inView) => inView && fetchNextPage() })

	const hasIndex = indexes && indexes.length > 0

	return (
		<>
			{!hasIndex ? (
				<CreateIndexCTAButton disabled={isLoading} onCreateIndexClick={onCreateIndexClick} />
			) : (
				<Select
					className={clsx('h-9 w-full tablet:w-[200px]', 'bg-white', 'pl-[1px]')}
					value={index?.index_name || ''}
					renderValue={(name) => <div className="truncate">{name as string}</div>}
					MenuProps={{
						PaperProps: {
							style: { maxHeight: ITEM_HEIGHT * 3 + CREATE_INDEX_BUTTON_HEIGHT, maxWidth: isMobile ? undefined : 200 }
						},
						MenuListProps: {
							style: { padding: 0 }
						}
					}}
					sx={({ palette }) => ({
						'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
							borderWidth: 1
						},
						'&:hover .MuiOutlinedInput-notchedOutline': {
							borderColor: `${palette.primary.main} !important`
						}
					})}
					{...selectProps}
				>
					{indexes.map((item) => (
						<IndexMenuItem
							key={item._id}
							selected={item._id === index?._id}
							value={item.index_name}
							onClick={() => {
								mixpanel.track('click', { type: 'selector', element: 'choose_index', value: item?.index_name })
								onIndexChange(item)
							}}
							index={item}
							sx={{ paddingX: 12 }}
						>
							{item.index_name}
						</IndexMenuItem>
					))}
					{hasNextPage && (
						<MenuItem disabled ref={ref} sx={{ alignItems: 'flex-start', flexDirection: 'column', paddingX: 12 }}>
							<Skeleton variant={'body1' as 'text'} width={60} />
							<Skeleton variant={'body1' as 'text'} width={180} sx={{ mt: 4 }} />
						</MenuItem>
					)}
					<div className={clsx('border-t border-grey-200', 'sticky bottom-0 z-1 bg-white')}>
						<Button
							className={clsx('h-11 w-full', '!justify-start', 'px-[10px]')}
							type="button"
							appearance="secondary"
							onClick={onCreateIndexClick}
						>
							<Add /> Create index
						</Button>
					</div>
				</Select>
			)}
			{error && <p className={clsx('text-body3 !text-red-500', 'mt-1')}>Error occurred while fetching indexes.</p>}
			<IndexCreateDialog open={openModal} onClose={() => setOpenModal(false)} />
		</>
	)
}

export default SelectMyIndex
