import type { Usage } from 'apis/billing'

export const USAGE_WARNING_THRESHOLD = 80 // in percentage

export const calcUsageNumbers = (usage: Usage) => {
	const formatter = new Intl.NumberFormat('en-US', { notation: 'compact' })

	const usedInMin = formatter.format(Math.ceil(usage.total_infra_usage_used / 60)).toLocaleLowerCase()
	const limitInMin = formatter.format(Math.ceil(usage.infra_usage_limit / 60)).toLocaleLowerCase()
	const usedInPercentage = Math.ceil((usage.total_infra_usage_used / usage.infra_usage_limit) * 100)

	return {
		usedInMin,
		limitInMin,
		usedInPercentage
	}
}
