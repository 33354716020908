'use client'

import type { NavbarButtonsProps } from './NavbarButtons'
import type { NavbarButtonType } from './buttons'
import type { ButtonProps } from '@twelvelabs/tds'

import { Menu } from '@mui/material'
import { Button as TdsButton } from '@twelvelabs/tds'
import clsx from 'clsx'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import ChevronDownIcon from 'public/icons/chevron-down.svg'
import { useMemo, useState } from 'react'
import useIsMobile from 'utils/hooks/useIsMobile'

import NavbarMobileButton from '../mobile/NavbarMobileButton'

const Button = ({ className, ...props }: ButtonProps) => {
	const isMobile = useIsMobile()

	return isMobile ? (
		<NavbarMobileButton className={clsx(className, '!py-4')} {...props} />
	) : (
		<TdsButton
			type="button"
			size="sm"
			appearance="secondary"
			className={clsx(className ?? '!text-black hover:!text-secondary')}
			{...props}
		/>
	)
}

const NavbarButtonWithMenu = ({ items, children, onClick, ...props }: { items: NavbarButtonType[] } & ButtonProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
		onClick?.(event)
	}

	const handleClose = () => setAnchorEl(null)

	return (
		<>
			<Button onClick={handleOpen} {...props}>
				{children}
				<ChevronDownIcon className={clsx('!h-3 !w-3', open && 'rotate-180')} />
			</Button>
			<Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
				{items.map(({ className, ...item }, index) => (
					// eslint-disable-next-line @typescript-eslint/no-use-before-define
					<NavbarButton
						key={item.label ?? index.toString()}
						className={clsx(
							'!h-11 w-full',
							'!justify-start',
							'!text-black',
							'hover:!bg-grey-50 active:!bg-green-50',
							className
						)}
						{...item}
					/>
				))}
			</Menu>
		</>
	)
}

const DynamicLink = ({ href, children }: { href: string; children: React.ReactNode }) => {
	const isOuterLink = href.match(/^(https?:)?\/\//)

	return (
		<Link href={href} {...(isOuterLink ? { rel: 'noreferrer', target: '_blank' } : undefined)}>
			{children}
		</Link>
	)
}

const NavbarButton = ({
	Icon,
	name,
	label,
	link,
	onClick,
	menus,
	closeMobileMenu,
	...buttonProps
}: NavbarButtonsProps & NavbarButtonType) => {
	const icon = useMemo(() => <Icon />, [Icon])

	if (link != null) {
		return (
			<DynamicLink href={link}>
				<Button
					onClick={(e) => {
						onClick?.(e)
						mixpanel.track('click', { type: 'link', element: 'navbar', url: link })
						closeMobileMenu?.()
					}}
					{...buttonProps}
				>
					{icon}
					{label}
				</Button>
			</DynamicLink>
		)
	}

	if (menus) {
		return (
			<NavbarButtonWithMenu items={menus} onClick={onClick} {...buttonProps}>
				{icon}
				{label}
			</NavbarButtonWithMenu>
		)
	}

	return (
		<Button
			onClick={(e) => {
				onClick?.(e)
				mixpanel.track('click', { type: 'button', element: name ?? label })
				closeMobileMenu?.()
			}}
			{...buttonProps}
		>
			{icon}
			{label}
		</Button>
	)
}

export default NavbarButton
