import type { UserProfile } from '@auth0/nextjs-auth0/client'

import clsx from 'clsx'
import React from 'react'

import UserLetterAvatar from './UserLetterAvatar'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	user: UserProfile | undefined
}

const UserInfo = ({ className, user, ...props }: Props) => {
	if (!user) return null

	return (
		<div className={clsx(className, 'flex flex-col items-center gap-y-3')} {...props}>
			<UserLetterAvatar size="lg" user={user} />
			<div className={clsx('flex flex-col items-start items-center')}>
				<p className={clsx('truncate text-subtitle2 !text-grey-1200')}>{user.name}</p>
				<p className={clsx('truncate text-body2 !text-grey-1200')}>{user.email}</p>
			</div>
		</div>
	)
}

export default UserInfo
