import * as Intercom from '@intercom/messenger-js-sdk'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useQuery } from '@tanstack/react-query'
import { usageOptions } from 'apis/billing'
import { useUsageAlertDialog } from 'apis/tasks'
import Dialog from 'components/Dialog'
import Routes from 'constants/routes'
import { capitalizeFirstLetter } from 'utils/helpers'
import withBoundary from 'utils/hocs/withBoundary'
import useAuth0 from 'utils/hooks/useAuth0'
import useRouter from 'utils/hooks/useRouter'

const TextButton = styled('a')({})

const UsageLimitAlertDialog = (): JSX.Element | null => {
	const { open, onClose } = useUsageAlertDialog()
	const { isAuthenticated } = useAuth0()
	const { data: usage } = useQuery({
		...usageOptions(),
		select: ({ data }) => ({ ...data, plan_name: capitalizeFirstLetter(data.plan_name) }),
		enabled: isAuthenticated
	})
	const router = useRouter()

	if (!usage) return null

	const remainingMinutes = Math.floor((usage.infra_usage_limit - usage.total_infra_usage_used) / 60).toLocaleString(
		'en-US'
	)

	return (
		<Dialog
			open={open}
			onClose={onClose}
			title={`Request exceeds ${usage.plan_name} indexing limit`}
			action={{
				label: 'Upgrade plan',
				onClick: () => router.push({ pathname: Routes.BILLING })
			}}
		>
			<Typography variant="body1" color="grey.700">
				You have {remainingMinutes} minutes left for indexing videos on the {usage.plan_name}. To continue with the
				current request, please upgrade your plan or{' '}
				<TextButton onClick={(): void => Intercom.showNewMessage('')}>contact sales</TextButton>.
			</Typography>
		</Dialog>
	)
}

export default withBoundary(UsageLimitAlertDialog, {}, { fallback: null })
