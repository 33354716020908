'use client'

import { breakpoints } from 'constants/measurements'
import { useEffect, useState, createContext, useContext } from 'react'
import { useMediaQuery } from 'react-responsive'

const useMediaQueryWithSSR = (...args: Parameters<typeof useMediaQuery>): boolean => {
	const match = useMediaQuery(...args)

	/**
	 * Ensure that the component renders the same content server-side
	 * as it does during the initial client-side render to prevent a hydration mismatch.
	 *
	 * References
	 * https://nextjs.org/docs/messages/react-hydration-error#solution-1-using-useeffect-to-run-on-the-client-only
	 * https://velog.io/@jminkyoung/TIL-32-Next.js-SSR-react-responisve-%EC%97%90%EB%9F%AC-5ga8lsnw
	 */
	const [clientMatch, setClientMatch] = useState(false)

	useEffect(() => {
		setClientMatch(match)
	}, [match])

	return clientMatch
}

export const IsMobileContext = createContext(false)

export const ViewportListener = ({ children }: { children: React.ReactNode }): JSX.Element => {
	const isMobile = useMediaQueryWithSSR({ query: `(max-width: ${breakpoints.tablet})` })

	return <IsMobileContext.Provider value={isMobile}>{children}</IsMobileContext.Provider>
}

const useIsMobile = () => useContext(IsMobileContext)

export default useIsMobile
