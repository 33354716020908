import type { DialogProps } from 'components/Dialog'

import clsx from 'clsx'
import Dialog from 'components/Dialog'

const CancelCompleteModal = ({ open, ...props }: DialogProps): JSX.Element => {
	const now = new Date()
	return (
		<Dialog open={open} title="Your cancellation is confirmed" {...props}>
			<p>
				We hope to see you on your next project. Your plan will downgrade to Free plan starting on{' '}
				<span className={clsx('text-subtitle3')}>
					{`${now.toLocaleDateString('en-US', {
						month: 'long',
						day: 'numeric',
						year: 'numeric'
					})}. `}
				</span>
			</p>
		</Dialog>
	)
}

export default CancelCompleteModal
