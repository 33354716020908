import type { ButtonProps } from '@twelvelabs/tds'

import { Button } from '@twelvelabs/tds'
import clsx from 'clsx'
import { pathName } from 'constants/paths'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useCallback } from 'react'
import useIsMobile from 'utils/hooks/useIsMobile'

import NavbarMobileButton from '../mobile/NavbarMobileButton'

interface TabItem {
	path: string
	pattern: RegExp
	label: string
}

const tabs: TabItem[] = [
	{
		path: pathName.OVERVIEW,
		pattern: /^(\/)?$/,
		label: 'Overview'
	},
	{
		path: pathName.indexes(),
		pattern: /^\/indexes(\/.+)?/,
		label: 'Indexes'
	},
	{
		path: pathName.EXAMPLES,
		pattern: /^\/examples(\/.+)?/,
		label: 'Examples'
	}
]

interface Props {
	closeMobileMenu?: () => void
}

const TabLinks = ({ closeMobileMenu }: Props): JSX.Element => {
	const pathname = usePathname()
	const isMobile = useIsMobile()

	const selectedTabIndex = tabs.findIndex(({ pattern }) => Boolean(pathname?.match(pattern)))

	const TabButton = useCallback(
		// eslint-disable-next-line react/no-unused-prop-types
		({ selected, children }: Pick<ButtonProps, 'children'> & { selected: boolean }) =>
			isMobile ? (
				<NavbarMobileButton className={clsx(selected && '!text-secondary')} onClick={closeMobileMenu}>
					{children}
				</NavbarMobileButton>
			) : (
				<Button
					size="lg"
					appearance="secondary"
					className={clsx('w-[92px]', !selected && '!text-grey-1000 hover:!text-secondary')}
				>
					{children}
				</Button>
			),
		[closeMobileMenu, isMobile]
	)

	return (
		<>
			{tabs.map((item, index) => (
				<Link
					key={item.label}
					href={item.path}
					onClick={() => mixpanel.track('click', { type: 'link', url: item.path })}
				>
					<TabButton selected={selectedTabIndex === index}>{item.label}</TabButton>
				</Link>
			))}
		</>
	)
}

export default TabLinks
