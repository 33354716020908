import type { RefObject } from 'react'

import Tooltip from '@mui/material/Tooltip'
import React, { useRef, useLayoutEffect, useState, memo } from 'react'

export const useIsOverflow = (callback?: (isOverflow: boolean) => void): [boolean, RefObject<HTMLSpanElement>] => {
	const [isOverflow, setIsOverflow] = useState(false)
	const ref = useRef<HTMLSpanElement>(null)

	useLayoutEffect(() => {
		const { current } = ref
		const checkOverflow = () => {
			if (current) {
				const hasOverflow = current.scrollWidth > current.clientWidth || current.scrollHeight > current.clientHeight
				setIsOverflow(hasOverflow)
				if (callback) callback(hasOverflow)
			}
		}

		if (current) {
			checkOverflow()
			window.addEventListener('resize', checkOverflow)
		}

		return () => window.removeEventListener('resize', checkOverflow)
	}, [callback])

	return [isOverflow, ref]
}
interface OverflownTextProps {
	text: string
	children: React.ReactNode
}

/**
 * Component that adds a tooltip on hover to show the entire string
 * @param children : Text
 * @returns Wrapped Component
 */
const OverflownText: React.FC<OverflownTextProps> = ({ text, children }) => {
	const [isOverflow, ref] = useIsOverflow()

	return (
		<Tooltip title={text} disableHoverListener={!isOverflow}>
			<span ref={ref} className="line-clamp-1">
				{children}
			</span>
		</Tooltip>
	)
}

export default memo(OverflownText)
