import { CardElement } from '@stripe/react-stripe-js'
import clsx from 'clsx'
import { memo } from 'react'

const StripeCardElement = () => (
	<CardElement
		options={{
			iconStyle: 'default',
			hidePostalCode: true,
			classes: {
				base: clsx(
					'font-aeonik',
					'border border-grey-200',
					'h-10 px-3',
					'flex items-center',
					'[&>div]:flex-1',
					'&:hover:border-grey-300'
				),
				focus: clsx('border border-primary')
			}
		}}
	/>
)

export default memo(StripeCardElement)
