import loCapitalize from 'lodash/capitalize'

export function capitalize(text: string): string {
	return loCapitalize(text).replaceAll('_', ' ')
}

export function stripAfterHyphen(str: string): string {
	return str.replace(/-.*$/, '')
}

const dollarFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2
})

export const numberToCommaDollarString = (n: number | undefined): string => dollarFormatter.format(n ?? 0)
