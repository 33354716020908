'use client'

import type { NextPage } from 'next'
import type { ErrorProps } from 'next/error'

import * as Intercom from '@intercom/messenger-js-sdk'
import { Button } from '@twelvelabs/tds'
import { clsx } from 'clsx'
import ErrorIcon from 'public/icons/error.svg'
import Searching from 'public/images/searching.svg'
import { isValidElement } from 'react'
import useIsMobile from 'utils/hooks/useIsMobile'

interface Action {
	onClick: React.MouseEventHandler<HTMLButtonElement>
	label: string
	loading?: boolean
}

export interface ErrorPageProps extends Omit<ErrorProps, 'title'> {
	className?: string
	title?: string | null
	message?: React.ReactNode
	action?: Action | JSX.Element | null
	hideContactButton?: boolean
}

const Heading = (props: React.HTMLAttributes<HTMLHeadingElement>) => {
	const isMobile = useIsMobile()
	// eslint-disable-next-line jsx-a11y/heading-has-content
	return isMobile ? <h5 {...props} /> : <h4 {...props} />
}

const ErrorPage: NextPage<ErrorPageProps> = ({
	className,
	title = 'Oops! something went wrong',
	message = "Please try refreshing the page, or contact us if the issue persists. We're here to help!",
	action = { onClick: () => window.location.replace('/'), label: 'Refresh' },
	hideContactButton
}: ErrorPageProps) => {
	const openIntercom = () => Intercom.showNewMessage('')

	return (
		<main
			className={clsx('h-full w-full', 'bg-white', 'flex items-center justify-center', 'px-5 tablet:px-0', className)}
			data-testid="error-section"
		>
			<div className={clsx('flex flex-col items-center', 'w-full tablet:max-w-[420px]')}>
				{title ? (
					<>
						<Searching className={clsx('h-[168px] w-full', 'mb-5')} />
						<Heading className={clsx('text-center text-grey-1100', 'mb-6')}>{title}</Heading>
					</>
				) : (
					<ErrorIcon className={clsx('h-10 w-10', 'mb-1')} />
				)}
				{typeof message === 'string' ? (
					<p className={clsx('whitespace-pre-line text-center text-body1 !text-grey-800')}>{message}</p>
				) : (
					message
				)}

				<div className={clsx('flex flex-col items-center gap-y-3', 'mt-5')}>
					{action != null &&
						(isValidElement(action) ? (
							action
						) : (
							<Button
								appearance="primary"
								className={clsx('w-full')}
								onClick={(action as Action).onClick}
								loading={(action as Action).loading}
							>
								{(action as Action).label}
							</Button>
						))}
					{!hideContactButton && (
						<Button appearance="secondary" className={clsx('w-full', 'mt-5')} onClick={openIntercom}>
							Contact us
						</Button>
					)}
				</div>
			</div>
		</main>
	)
}

export default ErrorPage
