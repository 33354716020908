import muiSvg from './muiSvg'

const ChevronUpMini = muiSvg(
	(props): JSX.Element => (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M15 12.5L10 8.33333L5 12.5" stroke="currentColor" strokeWidth="2" />
		</svg>
	)
)

export default ChevronUpMini
