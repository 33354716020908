'use client'

import type { CardValidation } from 'app/dashboard/billing/utils/schema'
import type { DialogProps } from 'components/Dialog'

import { useUser } from '@auth0/nextjs-auth0/client'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { useQuery } from '@tanstack/react-query'
import { Link } from '@twelvelabs/tds'
import { stripeClientSecretOptions, useChangePlan } from 'apis/billing'
import { isCardSavedOptions } from 'apis/user'
import { getCountryCode } from 'app/dashboard/billing/utils/countries'
import { cardValidationSchema } from 'app/dashboard/billing/utils/schema'
import { clsx } from 'clsx'
import Dialog from 'components/Dialog'
import Routes from 'constants/routes'
import { useFormik } from 'formik'
import mixpanel from 'mixpanel-browser'
import CircleUpLineIcon from 'public/icons/circle-up-line.svg'
import OutlinkIcon from 'public/icons/outlink.svg'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getStripe } from 'utils/stripe'

import PaymentSection from './PaymentSection'
import UpgradeSuccessModal from './UpgradeSuccessModal'
import { PRICING_WEBSITE_URL } from '../../../utils/const'

const devPlanCopies: { title: string; content: string }[] = [
	{
		title: 'Index more videos',
		content: 'Grow your index up to 1,000 hours of video.'
	},
	{
		title: 'Index faster in parallel',
		content: 'Concurrently index 25 videos at the same time.'
	},
	{
		title: 'No expiration',
		content:
			'Your index is now your permanent video embedding database. Launch and grow your video applications without limits.'
	}
]

interface UpgradePlanModalProps extends Omit<DialogProps, 'action'> {
	clientSecret: string
}

const UpgradePlanModal = ({ open, onClose, clientSecret, ...props }: UpgradePlanModalProps): JSX.Element => {
	const { user } = useUser()
	const [openSuccessModal, setOpenSuccessModal] = useState(false)
	const stripe = useStripe()
	const elements = useElements()
	const [isEditingCard, setIsEditingCard] = useState<boolean>(false)
	const [isSavingCard, setIsSavingCard] = useState<boolean>(false)
	const changePlan = useChangePlan()

	const { data: hasCardInfo, refetch: refetchHasCard } = useQuery({ ...isCardSavedOptions(), enabled: open })

	const onUpgradePlanSubmit = useCallback(
		async (values: CardValidation): Promise<void> => {
			try {
				if (hasCardInfo && !isEditingCard) {
					await changePlan.mutateAsync({
						plan_name: 'developer'
					})
				} else {
					if (!stripe || !elements) return
					try {
						setIsSavingCard(true)
						const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
							return_url: `${window.location.origin}${Routes.BILLING}`,
							payment_method: {
								card: elements.getElement('card') ?? elements.create('card'),
								billing_details: {
									name: values.cardHolderName,
									address: {
										country: getCountryCode(values.address.country)
									}
								}
							}
						})

						if (error != null) {
							if (error.param === 'payment_method_data[billing_details][address][country]') {
								toast('Please select a country', { type: 'warning' })
							} else {
								toast(error.message, { type: 'error' })
							}
						} else if (setupIntent?.status === 'succeeded') {
							await refetchHasCard()
							toast('Payment saved... Upgrading plan')
							await changePlan.mutateAsync({
								plan_name: 'developer'
							})
						}
					} catch (e) {
						toast('Something went wrong :(', { type: 'error' })
					} finally {
						setIsSavingCard(false)
					}
				}
			} catch (e) {
				toast('Something went wrong :(', { type: 'error' })
			}
		},
		[changePlan, clientSecret, elements, hasCardInfo, isEditingCard, refetchHasCard, stripe]
	)

	useEffect(() => {
		if (changePlan.isSuccess) {
			toast('Successfully upgraded your plan 🎉')
			setOpenSuccessModal(true)
		}
	}, [changePlan.isSuccess])

	const formik = useFormik<CardValidation>({
		initialValues: {
			cardHolderName: user?.name ?? '',
			address: {
				country: ''
			}
		},
		validationSchema: !hasCardInfo ? cardValidationSchema : undefined,
		validateOnChange: true,
		onSubmit: (values, { setSubmitting }) => {
			setSubmitting(true)
			onUpgradePlanSubmit(values)
			setSubmitting(false)
		}
	})

	return (
		<>
			<Dialog
				open={open && !openSuccessModal}
				fullWidth
				maxWidth="md"
				onClose={(e, r): void => {
					if (isSavingCard || changePlan.isPending) return
					onClose?.(e, r)
					formik.resetForm()
				}}
				title={
					<h5 className={clsx('flex items-center justify-between')}>
						<div className={clsx('flex items-center gap-x-2')}>
							<CircleUpLineIcon className={clsx('text-secondary', 'h-6 w-6')} />
							<p className={clsx('text-subtitle1-bold')}>Upgrade to Developer plan</p>
						</div>
						<Link
							href={PRICING_WEBSITE_URL}
							target="_blank"
							rel="noreferrer"
							onClick={() =>
								mixpanel.track('click', { type: 'link', element: 'upgrade_plan_modal', url: PRICING_WEBSITE_URL })
							}
							className={clsx('text-subtitle3')}
						>
							Pricing plan
							<OutlinkIcon color="inherit" />
						</Link>
					</h5>
				}
				{...props}
				onSubmit={formik.handleSubmit}
				action={{
					label: 'Upgrade',
					loading: formik.isSubmitting || isSavingCard || changePlan.isPending,
					disabled: !formik.isValid
				}}
			>
				<div className={clsx('flex justify-between gap-x-4')}>
					<div className={clsx('flex-1', 'border border-moss_green-200 bg-moss_green-50', 'p-6')}>
						<p className={clsx('text-subtitle2-bold', 'mb-6')}>Perks of the Developer plan</p>
						<p className={clsx('text-subtitle3', 'mb-5')}>What is included:</p>
						<div className={clsx('flex flex-col gap-y-6')}>
							{devPlanCopies.map(({ title, content }) => (
								<div key={title} className={clsx('flex')}>
									<CheckCircle className={clsx('text-secondary', 'h-[1.375rem] w-[1.375rem]', 'mr-3')} />
									<div className={clsx('flex flex-col gap-x-1')}>
										<p className={clsx('text-subtitle2')}>{title}</p>
										<p className={clsx('text-body2')}>{content}</p>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className={clsx('flex-1')}>
						<PaymentSection formik={formik} isEditingCard={isEditingCard} setIsEditingCard={setIsEditingCard} />
					</div>
				</div>
			</Dialog>
			<UpgradeSuccessModal
				open={openSuccessModal}
				onClose={(e, r): void => {
					formik.resetForm()
					setOpenSuccessModal(false)
					if (onClose) {
						onClose(e, r)
					}
				}}
				action={{
					label: 'Done',
					onClick: (): void => {
						setOpenSuccessModal(false)
						if (onClose) {
							onClose({}, 'backdropClick')
						}
					}
				}}
			/>
		</>
	)
}

const UpgradePlanModalFallback = ({
	open,
	...props
}: Omit<UpgradePlanModalProps, 'clientSecret'>): JSX.Element | null => {
	const { data: clientSecret } = useQuery(stripeClientSecretOptions())

	if (!open || !clientSecret) return null

	return (
		<Elements stripe={getStripe()} options={{ clientSecret }}>
			<UpgradePlanModal open={open} {...props} clientSecret={clientSecret} />
		</Elements>
	)
}

export default UpgradePlanModalFallback
