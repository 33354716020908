import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { CircularProgress } from '@mui/material'
import { useSuspenseQuery } from '@tanstack/react-query'
import { taskOptions, useDeleteTask } from 'apis/tasks'
import clsx from 'clsx'
import Thumbnail from 'components/Thumbnail'
import ErrorRoundIcon from 'public/icons/error-round.svg'
import { forwardRef, useMemo } from 'react'
import withBoundary, { ErrorFallback } from 'utils/hocs/withBoundary'

import StatusBadgeWithClearButton from './StatusBadgeWithClearButton'
import TaskStatusItemSkeleton from './TaskStatusItemSkeleton'

const IndexingTaskStatusItem = forwardRef<HTMLDivElement, { taskId: string }>(({ taskId }, ref) => {
	const { data: task } = useSuspenseQuery(taskOptions(taskId))

	const deleteTask = useDeleteTask(task.index_id)

	const icon = useMemo(() => {
		if (task.status === 'ready') return <CheckCircleIcon color="success" fontSize="small" />
		if (task.status === 'failed') {
			return (
				<StatusBadgeWithClearButton
					badge={{
						icon: ErrorRoundIcon,
						label: 'Failed to index',
						appearance: 'error'
					}}
					cancel={{
						label: 'Delete',
						onClick: async () => deleteTask.mutateAsync(task._id)
					}}
				/>
			)
		}
		return <CircularProgress size={16} />
	}, [deleteTask, task._id, task.status])

	return (
		<div ref={ref} className={clsx('group/task-status', 'flex items-center gap-x-2', 'h-8 w-full')}>
			<Thumbnail
				unoptimized
				src={task.hls?.thumbnail_urls?.[0]}
				className={clsx('h-6 !w-11 flex-shrink-0', 'overflow-hidden rounded-sm')}
			/>
			<p className={clsx('truncate text-body2', 'flex-1')}>{task.metadata.filename}</p>
			{icon}
		</div>
	)
})

export default withBoundary(
	IndexingTaskStatusItem,
	{
		fallbackRender: ({ error, resetErrorBoundary }) => (
			<ErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} className={clsx('!h-fit')} />
		)
	},
	{
		fallback: <TaskStatusItemSkeleton />
	}
)
