'use client'

import Popover from '@mui/material/Popover'
import { Button } from '@twelvelabs/tds'
import UpgradePlanButton from 'app/dashboard/billing/components/Usage/UpgradePlanButton'
import clsx from 'clsx'
import Routes from 'constants/routes'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import OutlinkIcon from 'public/icons/outlink.svg'
import React, { useRef, useState } from 'react'
import useAuth0 from 'utils/hooks/useAuth0'
import { UsageVertical } from 'widgets/Usage'

import UserInfo from './UserInfo'
import UserLetterAvatar from './UserLetterAvatar'

const menuItemClassName = clsx('px-5 py-3', 'text-left text-body1 text-grey-1200', 'hover:bg-grey-100')

const UserAccountMenu = () => {
	const { user, error, isLoading, logout } = useAuth0()

	const buttonRef = useRef<HTMLButtonElement>(null)

	const [isOpen, setIsOpen] = useState(false)
	const openMenu = () => setIsOpen(true)
	const closeMenu = () => setIsOpen(false)

	const handleLogout = () => {
		mixpanel.track('click', { type: 'button', element: 'signout' })
		logout()
	}

	if (isLoading) {
		return <UserLetterAvatar loading />
	}

	if (error) {
		return (
			<Button size="sm" appearance="subtle" onClick={handleLogout} className={clsx('!px-1 !text-grey-1000')}>
				<span className={clsx('max-w-[120px] truncate')}>Sign out</span>
			</Button>
		)
	}

	const sendUpgradeMixpanelEvent = () => mixpanel.track('click', { type: 'button', element: 'user_menu_upgrade' })
	const sendApiKeyMixpanelEvent = () =>
		mixpanel.track('click', { type: 'link', element: 'user_menu', url: Routes.API_KEY }, { send_immediately: true })
	const sendPricingMixpanelEvent = () =>
		mixpanel.track('click', {
			type: 'link',
			element: 'user_menu',
			url: `${process.env.NEXT_PUBLIC_COMPANY_SITE_URL}/pricing`
		})

	return (
		<>
			<button type="button" ref={buttonRef} onClick={openMenu} aria-label="user-account-menu">
				<UserLetterAvatar user={user} />
			</button>
			<Popover
				anchorOrigin={{ vertical: 'bottom', horizontal: 56 }}
				transformOrigin={{ vertical: -10, horizontal: 'right' }}
				anchorEl={buttonRef.current}
				open={isOpen}
				onClose={closeMenu}
			>
				<div className={clsx('flex flex-col', 'w-[360px] py-6')}>
					<UserInfo className="mb-3 px-5" user={user} />
					<div className={clsx('mb-6 px-5', 'flex flex-col gap-y-3')}>
						<UsageVertical />
						<UpgradePlanButton
							className={clsx('[&>button]:w-full', '[&_svg]:hidden')}
							show
							size="xs"
							label="Upgrade"
							onClick={sendUpgradeMixpanelEvent}
						/>
					</div>
					<Link className={menuItemClassName} href={Routes.API_KEY} onClick={sendApiKeyMixpanelEvent}>
						API key
					</Link>
					<a
						className={clsx(menuItemClassName, 'flex items-center gap-x-1')}
						href={`${process.env.NEXT_PUBLIC_COMPANY_SITE_URL}/pricing`}
						target="_blank"
						rel="noreferrer"
						onClick={sendPricingMixpanelEvent}
					>
						Pricing <OutlinkIcon className="h-4 w-4" />
					</a>
					<button className={clsx(menuItemClassName, 'border-t border-grey-200')} type="button" onClick={handleLogout}>
						Sign out
					</button>
				</div>
			</Popover>
		</>
	)
}

export default UserAccountMenu
