import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/material'
import { isPegasus, type IndexResponse } from 'apis/indexes'
import clsx from 'clsx'
import React from 'react'
import { getVideoFileLimits } from 'utils/helpers'

interface Props {
	index: IndexResponse | undefined
	disabled?: boolean
}

const DropzoneSubText = ({ index, disabled }: Props) => {
	const supportPegasus = index?.engines.some(isPegasus)

	const fileLimits = getVideoFileLimits(index)

	return (
		<div className={clsx('mt-3 tablet:mt-4', disabled ? 'text-grey-500' : 'text-grey-700')}>
			<div className={clsx('text-subtitle3', 'mb-1')}>
				Supported videos for&nbsp;
				<span className="text-green-900">{supportPegasus ? 'Pegasus' : 'Marengo'}</span> engine:
			</div>
			<div className="flex flex-col gap-x-3 gap-y-[2px] tablet:flex-row">
				{fileLimits.map(([label, text, tooltip]) => {
					const isDuration = label === 'Duration'

					return (
						<div key={label} className="text-center tablet:text-left">
							<span className={clsx('text-subtitle3', 'mr-2')}>{label}</span>
							<div className={clsx('inline-flex items-center', 'text-body2')}>
								<span className={clsx(isDuration && 'text-green-900')}>{text}</span>
								{tooltip && (
									<Tooltip title={tooltip}>
										<InfoOutlined className={clsx('ml-1 text-base text-grey-600', 'hidden tablet:block')} />
									</Tooltip>
								)}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default DropzoneSubText
