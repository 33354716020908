'use client'

import type { UserProfile } from '@auth0/nextjs-auth0/client'

import Skeleton from '@mui/material/Skeleton'
import clsx from 'clsx'
import UserIcon from 'public/icons/user.svg'

const getUserInitial = (userName: string | null | undefined) => {
	if (!userName) return undefined

	const names = userName.split(' ')
	if (names.length === 1) return userName.slice(0, 2).toLocaleUpperCase()

	return `${names[0][0]}${names[1][0]}`.toLocaleUpperCase()
}

interface Props {
	className?: string
	loading?: boolean
	user?: UserProfile
	size?: 'sm' | 'lg'
}

const UserLetterAvatar = ({ className, loading, user, size = 'sm' }: Props) => {
	const userInitial = getUserInitial(user?.name || user?.nickname)

	if (loading) {
		return (
			<Skeleton
				className={clsx('rounded-full', className)}
				variant="circular"
				width={size === 'sm' ? 32 : 56}
				height={size === 'sm' ? 32 : 56}
			/>
		)
	}

	if (!userInitial) {
		return (
			<div
				className={clsx(
					'rounded-full bg-grey-600',
					'flex items-center justify-center',
					size === 'sm' ? 'h-8 w-8' : 'h-14 w-14',
					className
				)}
			>
				<UserIcon className={clsx('text-grey-200', 'h-4 w-4')} />
			</div>
		)
	}

	return (
		<div
			className={clsx(
				'rounded-full bg-secondary',
				'flex items-center justify-center',
				size === 'sm' ? 'h-8 w-8' : 'h-14 w-14',
				className
			)}
		>
			<div className={clsx('font-medium text-primary', size === 'sm' ? 'text-body3' : 'text-subtitle2')}>
				{userInitial}
			</div>
		</div>
	)
}

export default UserLetterAvatar
