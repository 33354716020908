import CancelRounded from '@mui/icons-material/CancelRounded'
import clsx from 'clsx'
import React from 'react'

import CapitalizedLabel from './CapitalizedLabel'

interface Props extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'children'> {
	children: JSX.Element
	hover?: { label?: string; icon?: JSX.Element }
}

const DynamicButton = ({
	children,
	hover: { label = 'Cancel', icon = <CancelRounded aria-label="cancel" color="error" fontSize="small" /> } = {},
	className,
	...props
}: Props) => (
	<button type="button" className={clsx('group/dynamic', className)} {...props}>
		{React.cloneElement(children, { className: clsx('md:group-hover/dynamic:hidden', children.props.className) })}
		<div className={clsx('hidden md:items-center md:group-hover/dynamic:flex')}>
			<CapitalizedLabel>{label}</CapitalizedLabel>
			{icon}
		</div>
	</button>
)

export default DynamicButton
