import type { IVariant } from '@unleash/nextjs'

import { ApiType, getInstance } from './network'

export enum FlagName {
	IS_ADMIN = 'is_admin',
	GENERATE_STREAMING = 'generate-streaming',
	UP_NAVIGATION = 'unified-platform-navigation',
	ACTIVITY_PANEL = 'activity-panel'
}

/**
 * Can be used on both client and server side
 */
export const getFlag = async (flag: FlagName) => {
	const { data } = await getInstance(ApiType.ROUTE).get<{ isEnabled: boolean; variant: IVariant }>('/flag', {
		params: { flag }
	})
	return data.isEnabled
}
