import { EngineOption } from 'apis/indexes'
import clsx from 'clsx'
import OptionIcon from 'components/OptionIcon'
import Tooltip from 'components/Tooltip'
import Blank from 'components/svg/Blank'
import Checked from 'components/svg/Checked'
import React from 'react'
import { capitalize } from 'utils/formatString'

const optionTooltip: Record<EngineOption, React.ReactNode> = {
	[EngineOption.visual]: (
		<span className="text-body2">
			Index what has seen and heard, including actions, objects, and sound, while excluding human speech.
		</span>
	),
	[EngineOption.conversation]: (
		<span className="text-body2">
			Index the speech within your videos to enable semantic conversation understanding
		</span>
	),
	[EngineOption.text_in_video]: <span className="text-body2">Extract the text (OCR) within your videos.</span>,
	[EngineOption.logo]: <span className="text-body2">Identify brand logos within your videos.</span>
}

interface Props {
	value: EngineOption
	disabled: boolean | undefined
	checked: boolean | undefined
	onChange: (checked: boolean) => void
}

const EngineOptionCheckbox = ({ value, checked, disabled, onChange }: Props): JSX.Element => (
	// eslint-disable-next-line jsx-a11y/label-has-associated-control
	<label
		className={clsx('flex items-center', 'gap-x-1', disabled ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer')}
	>
		<input
			type="checkbox"
			checked={checked}
			onChange={() => onChange(!checked)}
			value={value}
			disabled={disabled}
			className={clsx('peer/option', 'hidden')}
		/>
		<Checked
			className={clsx(
				'text-secondary peer-disabled/option:text-grey-500',
				'h-4 w-4',
				'hidden peer-checked/option:block'
			)}
		/>
		<Blank className={clsx('text-grey-500', 'h-4 w-4', 'block peer-checked/option:hidden')} />
		<Tooltip name="engine_option_description" content={optionTooltip[value]} placement="right">
			<div className={clsx('flex items-center gap-x-[6px]', 'ml-[2px]')}>
				<OptionIcon option={value} className="h-4 w-4" />
				<span className="text-body2 text-grey-1100">{capitalize(value)}</span>
			</div>
		</Tooltip>
	</label>
)

export default EngineOptionCheckbox
