import type { Theme } from '@mui/material/styles'
import type { SystemStyleObject } from '@mui/system/styleFunctionSx'

import ErrorIcon from '@mui/icons-material/Error'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { Spinner } from '@twelvelabs/tds'
import { indexOptions } from 'apis/indexes'
import { UploadStatus, taskStatusOptions, useUploadTasks } from 'apis/tasks'
import { clsx } from 'clsx'
import ListWithSeparator from 'components/ListWithSeparator'
import VideoLibrary from 'components/svg/VideoLibrary'
import { useMemo } from 'react'
import { formatTime } from 'utils/formatTime'
import withBoundary from 'utils/hocs/withBoundary'

interface IndexTaskStatusProps {
	indexId: string
	totalDuration?: number // This is used to avoid fetching index data when it's already available
	alwaysShowReady?: boolean
	/**
	 * @default 'medium'
	 */
	gap?: 'medium' | 'large'
	dark?: boolean
	children?: JSX.Element | undefined | false
}

const IndexTaskStatus = ({
	indexId,
	dark,
	gap = 'medium',
	children,
	alwaysShowReady,
	totalDuration: totalDurationProp
}: IndexTaskStatusProps): JSX.Element => {
	const uploadingCount = useUploadTasks(
		(state) => state.filter((task) => task.indexId === indexId && task.status === UploadStatus.Uploading).length
	)
	const { data: counts } = useSuspenseQuery({
		...taskStatusOptions(indexId),
		select: ({ data: { ready, total_result, failed } }) => ({
			ready,
			failed,
			indexing: total_result - ready - failed
		})
	})

	const { data: index } = useQuery({ ...indexOptions(indexId), enabled: totalDurationProp == null, throwOnError: true })
	const totalDuration = useMemo(
		(): number => totalDurationProp ?? index?.total_duration ?? 0,
		[index?.total_duration, totalDurationProp]
	)

	const hasError = counts.failed > 0

	const statuses = useMemo((): JSX.Element[] => {
		const list: JSX.Element[] = []
		if (hasError) {
			list.push(
				<Box
					key="has_error"
					display="flex"
					alignItems="center"
					gap={gap === 'large' ? 8 : 4}
					sx={({ palette }): SystemStyleObject<Theme> => ({ color: dark ? palette.error.main : palette.error.light })}
				>
					<ErrorIcon color="error" fontSize="small" />
					<Typography variant="body2" color="inherit">
						Error
					</Typography>
				</Box>
			)
		}
		if (counts.indexing < 1 || alwaysShowReady) {
			list.push(
				<Box key="ready" display="flex" alignItems="center" gap={gap === 'large' ? 8 : 4}>
					<VideoLibrary color={dark ? 'grey.600' : '#FFFFFF'} fontSize={20} />
					<Typography variant="body2" color={dark ? 'grey.700' : '#FFFFFF'}>
						{`${counts.ready} video${counts.ready > 1 ? 's' : ''} (Total ${formatTime(totalDuration, {
							trim: true
						})})`}
					</Typography>
				</Box>
			)
		}
		if (uploadingCount + counts.indexing > 0) {
			list.push(
				<Box key="in_progress" display="flex" alignItems="center" gap={gap === 'large' ? 8 : 4}>
					<Spinner size="sm" color="primary" />
					<Typography variant="body2" color={dark ? 'grey.700' : '#FFFFFF'}>
						{uploadingCount ? `${uploadingCount} uploading` : `${counts.indexing} indexing`}
					</Typography>
				</Box>
			)
		}
		if (children) {
			list.push(children)
		}

		return list
	}, [alwaysShowReady, children, counts.indexing, counts.ready, uploadingCount, dark, gap, hasError, totalDuration])

	return (
		<ListWithSeparator
			separator={<div className={clsx('h-1 w-1', dark ? 'bg-grey-400' : 'bg-white', 'rounded-full')} />}
			className={clsx(gap === 'large' ? 'gap-x-3' : 'gap-x-2')}
		>
			{statuses}
		</ListWithSeparator>
	)
}

export default withBoundary(
	IndexTaskStatus,
	{
		fallbackRender: ({ gap, dark, resetErrorBoundary }) => (
			<button
				type="button"
				className={clsx('flex items-center', gap === 'large' ? 'gap-x-2' : 'gap-x-1')}
				onClick={(e) => {
					e.stopPropagation()
					resetErrorBoundary()
				}}
			>
				<ErrorIcon color="error" fontSize="small" />
				<p className={clsx('text-body2', dark ? '!text-grey-700' : '!text-white')}>Failed to get info</p>
			</button>
		)
	},
	{
		fallback: (
			<Box display="flex" alignItems="center" gap={4}>
				<Skeleton variant="circular" width={20} height={20} />
				<Skeleton variant={'body2' as 'text'} width={40} />
			</Box>
		)
	}
)
