import type { InfiniteData } from '@tanstack/react-query'
import type { TaskHLS } from 'apis/tasks'
import type { AxiosResponse } from 'axios'
import type {
	DefaultResponse,
	IndexStatus,
	PageResponseFormat,
	TwelveLabsApiError,
	UseCommonInfiniteQueryOptions,
	UseCommonQueryOptions
} from 'utils/response'

import { getVideoKey, getVideoThumbnailKey, getVideosKey } from './keys'
import { getInstanceByIndexId } from 'utils/network'

export interface IndexVideosResponse extends DefaultResponse {
	estimated_time: string // date format
	index_id: string
	metadata: {
		duration: number
		filename: string
		width: number
		height: number
	}
	status: IndexStatus
}

export interface IndexVideosParams {
	_id?: string
	filename?: string
	duration?: string
	fps?: number
	width?: number
	height?: number
	size?: number
	created_at?: string
	updated_at?: string
	indexed_at?: string
	/**
	 * @default 1
	 */
	page?: number
	/**
	 * Number of items to return per page.
	 * Maximum value is 100
	 * @default 20
	 */
	page_limit?: number
	/**
	 * @default created_at
	 */
	sort_by?: 'created_at' | 'updated_at'
	/**
	 * @default desc
	 */
	sort_option?: 'asc' | 'desc'
	/**
	 * Filter tasks by status which is one of 'ready', 'failed'
	 */
	indexing_done?: boolean
}

type IndexVideosPageResponse<Type = string> = Omit<PageResponseFormat<IndexVideosResponse, Type>, 'page_info'> & {
	page_info: PageResponseFormat<IndexVideosResponse, Type>['page_info'] & {
		total_duration?: number
	}
}

export function indexVideosOptions<Response extends IndexVideosPageResponse<'list_index_task'>>(
	indexId: string,
	params: IndexVideosParams
): UseCommonQueryOptions<AxiosResponse<Response>, TwelveLabsApiError, Response, ReturnType<typeof getVideosKey>> {
	return {
		queryKey: getVideosKey(indexId, params),
		queryFn: async () => {
			const instance = await getInstanceByIndexId(indexId)
			return instance.get<Response>(`/indexes/${indexId}/videos`, { params })
		},
		select: (res) => res.data
	}
}

type InfiniteIndexVideosPageParam = number | undefined
export function infiniteIndexVideosOptions<Response extends IndexVideosPageResponse<'list_index_task'>>(
	indexId: string,
	params: Omit<IndexVideosParams, 'page'>
): UseCommonInfiniteQueryOptions<
	AxiosResponse<Response>,
	TwelveLabsApiError,
	InfiniteData<Response, InfiniteIndexVideosPageParam>,
	AxiosResponse<Response>,
	ReturnType<typeof getVideosKey>,
	InfiniteIndexVideosPageParam
> {
	return {
		queryKey: getVideosKey(indexId, params),
		queryFn: async ({ pageParam: page }) => {
			const instance = await getInstanceByIndexId(indexId)
			return instance.get<Response>(`/indexes/${indexId}/videos`, { params: { ...params, page } })
		},
		select: ({ pages, pageParams }) => ({ pageParams, pages: pages.map(({ data }) => data) }),
		initialPageParam: 1,
		getNextPageParam: ({ data: { page_info } }): number | undefined =>
			page_info.page < page_info.total_page ? page_info.page + 1 : undefined
	}
}

export type VideoSource = {
	type: string
	name: string
	url?: string
}

export interface IndexVideoResponse extends DefaultResponse {
	indexed_at: string
	metadata: {
		duration: number
		filename: string
		fps: number
		height: number
		size: number
		width: number
	}
	// hls information
	hls?: Pick<TaskHLS, 'video_url' | 'thumbnail_urls' | 'vtt_url'>
	// source information
	source?: VideoSource
}

export function indexVideoOptions<Response extends IndexVideoResponse>(
	indexId: string,
	videoId: string
): UseCommonQueryOptions<AxiosResponse<Response>, TwelveLabsApiError, Response, ReturnType<typeof getVideoKey>> {
	return {
		queryKey: getVideoKey(indexId, videoId),
		queryFn: async () => {
			const instance = await getInstanceByIndexId(indexId)
			return instance.get<Response>(`/indexes/${indexId}/videos/${videoId}`)
		},
		select: (res) => res.data
	}
}

export function indexVideoThumbnailOptions<Response extends { thumbnail: string }>(
	indexId: string,
	videoId: string,
	params: { time?: number }
): UseCommonQueryOptions<
	AxiosResponse<Response>,
	TwelveLabsApiError,
	Response,
	ReturnType<typeof getVideoThumbnailKey>
> {
	return {
		queryKey: getVideoThumbnailKey(indexId, videoId, params),
		queryFn: async () => {
			const instance = await getInstanceByIndexId(indexId)
			return instance.get<Response>(`/indexes/${indexId}/videos/${videoId}/thumbnail`, { params })
		},
		select: (res) => res.data
	}
}
