import type { CompletedTasksStore, UploadTasksStore } from './store'

import { useStore } from 'zustand'

import tasksStore from './store'

export function useUploadTasks(): UploadTasksStore['uploadTasks']
export function useUploadTasks<T>(selector: (data: UploadTasksStore['uploadTasks']) => T): T
export function useUploadTasks<T>(selector?: (data: UploadTasksStore['uploadTasks']) => T) {
	return useStore(tasksStore, (state) => (selector ? selector(state.uploadTasks) : state.uploadTasks))
}

export function useAddUploadQueue() {
	return useStore(tasksStore, (state) => state.addUploadTasks)
}

export function useClearUploadTasks() {
	return useStore(tasksStore, (state) => state.clearUploadTasks)
}

export function useCompletedTasks(): CompletedTasksStore['completedTasks']
export function useCompletedTasks<T>(selector: (data: CompletedTasksStore['completedTasks']) => T): T
export function useCompletedTasks<T>(selector?: (data: CompletedTasksStore['completedTasks']) => T) {
	return useStore(tasksStore, (state) => (selector ? selector(state.completedTasks) : state.completedTasks))
}

export function useAddCompletedTask() {
	return useStore(tasksStore, (state) => state.addCompletedTask)
}

export function useClearCompletedTasks() {
	return useStore(tasksStore, (state) => state.clearCompletedTasks)
}

export function useClearIndexFromTasks() {
	const clearUploadTasks = useClearUploadTasks()
	const clearCreatedTasks = useClearCompletedTasks()
	return (indexId: string) => {
		clearUploadTasks({ indexId })
		clearCreatedTasks(indexId)
	}
}

export function useUsageAlertDialog() {
	return useStore(tasksStore, (state) => ({
		open: state.isUsageAlertDialogOpen,
		onClose: state.closeUsageAlertDialog
	}))
}
