import clsx from 'clsx'
import React from 'react'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
	className?: string
}

const PageLayoutContent = ({ className, children, ...restProps }: Props) => (
	<section id="page-layout-content" className={clsx('flex flex-1 flex-col', className)} {...restProps}>
		{children}
	</section>
)

export default PageLayoutContent
