import clsx from 'clsx'
import { Fragment } from 'react'

const ListWithSeparator = ({
	children,
	separator = <div className={clsx('h-1 w-1', 'bg-grey-400', 'rounded-full')} />,
	className
}: {
	children: React.ReactNode | React.ReactNode[]
	separator?: JSX.Element
	className?: string
}): JSX.Element => (
	<div className={clsx(className, 'flex', 'items-center', 'gap-x-2')}>
		{Array.isArray(children)
			? children.map((child, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<Fragment key={index}>
						{child}
						{index !== children.length - 1 && separator}
					</Fragment>
			  ))
			: children}
	</div>
)

export default ListWithSeparator
