import type { NavbarMenuProps } from '../NavbarMenu'

import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import Close from 'components/svg/Close'
import Menu from 'components/svg/Menu'
import { NAVBAR_HEIGHT } from 'constants/measurements'
import Routes from 'constants/routes'
import Link from 'next/link'
import ExitIcon from 'public/icons/exit.svg'
import React, { useCallback } from 'react'
import { createPortal } from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'
import useAuth0 from 'utils/hooks/useAuth0'
import { UsageVertical } from 'widgets/Usage'

import NavbarMobileButton from './NavbarMobileButton'
import NavbarButtons from '../NavbarButtons'
import TabLinks from '../common/TabLinks'
import { UserInfo } from '../common/UserInfoButton'

interface MobileMenuButtonProps extends Pick<NavbarMenuProps, 'hideTabs' | 'hideUsage'> {
	open: boolean
	onOpen: () => void
	onClose: () => void
}

const MobileMenuButton = ({ open, onOpen, onClose, hideTabs, hideUsage }: MobileMenuButtonProps) => {
	const { logout: logoutWithRedirect } = useAuth0()
	const logout = useCallback(() => logoutWithRedirect(), [logoutWithRedirect])

	return (
		<>
			<IconButton size="small" color="inherit" className={clsx('text-grey-900')} onClick={open ? onClose : onOpen}>
				{open ? <Close fontSize={24} /> : <Menu fontSize={24} />}
			</IconButton>
			{open &&
				createPortal(
					<RemoveScroll
						className={clsx('fixed inset-0 z-mobile-nav-menu', 'bg-white', 'overflow-y-auto')}
						style={{ top: NAVBAR_HEIGHT }}
					>
						<div className={clsx('flex flex-col gap-y-8', 'px-6 pt-6')}>
							<UserInfo />
							{!hideUsage && <UsageVertical />}
						</div>
						{!hideTabs && (
							<div className={clsx('my-3 pb-4 [&>a>button]:px-6', 'border-b border-grey-200')}>
								<TabLinks closeMobileMenu={onClose} />
							</div>
						)}
						<div className={clsx('my-3 pb-3', '[&_button]:px-6', 'border-b border-grey-200')}>
							<NavbarButtons closeMobileMenu={onClose} />
						</div>
						<div className={clsx('my-3 pb-3', '[&_button]:px-6', 'border-b border-grey-200')}>
							<Link aria-disabled="true" href={Routes.DASHBOARD}>
								<NavbarMobileButton disabled>
									Dashboard
									<div className={clsx('ml-auto', 'bg-moss_green-100 px-2 py-0.5')}>
										<p className={clsx('text-subtitle3 !text-secondary')}>Try on desktop</p>
									</div>
								</NavbarMobileButton>
							</Link>
						</div>
						<NavbarMobileButton className={clsx('mb-6 px-6')} onClick={logout}>
							<ExitIcon />
							Sign out
						</NavbarMobileButton>
					</RemoveScroll>,
					document.body
				)}
		</>
	)
}

export default MobileMenuButton
