'use client'

import type { ButtonProps } from '@twelvelabs/tds'

import { useSuspenseQuery } from '@tanstack/react-query'
import { Button } from '@twelvelabs/tds'
import { usageOptions } from 'apis/billing'
import clsx from 'clsx'
import mixpanel from 'mixpanel-browser'
import CircleUpLineIcon from 'public/icons/circle-up-line.svg'
import { useMemo, useState } from 'react'
import withBoundary from 'utils/hocs/withBoundary'

import CancelPlanModal from './CancelPlanModal'
import UpgradePlanModal from './UpgradePlanModal'
import { PRICING_WEBSITE_URL } from '../../utils/const'

interface UpgradePlanButtonProps extends ButtonProps {
	show?: boolean
	showPricingPlan?: boolean
	/**
	 * @default 80
	 */
	highUsageThreshold?: number
	label?: string
}

const UpgradePlanButton = ({
	show,
	showPricingPlan,
	className,
	highUsageThreshold = 80,
	onClick,
	label = 'Upgrade plan',
	...props
}: UpgradePlanButtonProps): JSX.Element | null => {
	const [openModal, setOpenModal] = useState(false)
	const [openCancelModal, setOpenCancelModal] = useState(false)
	const { data: usage } = useSuspenseQuery(usageOptions())

	const isHitUsageLimitSoon = useMemo((): boolean => {
		if (usage?.total_infra_usage_used == null || usage?.infra_usage_limit == null) return false
		const usagePercent = Math.min(100, Math.floor((usage.total_infra_usage_used / usage.infra_usage_limit) * 100))
		return usagePercent >= highUsageThreshold
	}, [highUsageThreshold, usage?.infra_usage_limit, usage?.total_infra_usage_used])

	const isUpgradeNeeded = useMemo((): boolean => {
		if (!usage?.is_hard_limit) return false
		return show || isHitUsageLimitSoon
	}, [show, isHitUsageLimitSoon, usage?.is_hard_limit])

	const onClickUpgradeButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		onClick?.(e)
		setOpenModal(true)
	}

	return (
		<>
			{isUpgradeNeeded && (
				<div className={clsx(className, 'flex items-center', props.size === 'xs' ? 'gap-x-2' : 'gap-x-3')}>
					<Button size="sm" appearance="primary" onClick={onClickUpgradeButton} {...props}>
						<CircleUpLineIcon />
						{label}
					</Button>
					{showPricingPlan && (
						<a target="_blank" href={PRICING_WEBSITE_URL} rel="noreferrer">
							<Button
								size="sm"
								onClick={() =>
									mixpanel.track('click', { type: 'link', element: 'upgrade_plan', url: PRICING_WEBSITE_URL })
								}
								{...props}
							>
								See pricing plan
							</Button>
						</a>
					)}
				</div>
			)}
			{!isUpgradeNeeded && showPricingPlan && (
				<div className={clsx('flex justify-end')}>
					<button
						type="button"
						className={clsx('text-right text-body2 text-grey-700 underline')}
						onClick={() => {
							setOpenCancelModal(true)
							mixpanel.track('click', { type: 'button', element: 'cancel_enrollment' })
						}}
					>
						Cancel enrollment
					</button>
				</div>
			)}
			<UpgradePlanModal
				open={openModal}
				onClose={(): void => {
					setOpenModal(false)
				}}
			/>
			<CancelPlanModal
				open={openCancelModal}
				onClose={(): void => {
					setOpenCancelModal(false)
				}}
			/>
		</>
	)
}

export default withBoundary(UpgradePlanButton)
