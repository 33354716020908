import type { CSSObject, ThemeOptions } from '@mui/material/styles'

import { alertClasses } from '@mui/material/Alert'
import { checkboxClasses } from '@mui/material/Checkbox'
import { paginationItemClasses } from '@mui/material/PaginationItem'
import { radioClasses } from '@mui/material/Radio'
import { svgIconClasses } from '@mui/material/SvgIcon'
import { typographyClasses } from '@mui/material/Typography'
import { createTheme } from '@mui/material/styles'
import * as colors from '@twelvelabs/tds/colors'
import { theme } from '@twelvelabs/ui'
import merge from 'lodash/merge'
import pick from 'lodash/pick'

export default createTheme(
	merge<ThemeOptions, ThemeOptions>(theme, {
		palette: {
			confidence: {
				high: colors.turquoise[600],
				medium: colors.yellow[600],
				low: colors.grey[600],
				none: colors.grey[400]
			},
			confidenceLight: {
				high: colors.turquoise[500],
				medium: colors.yellow[500],
				low: colors.grey[500],
				none: colors.grey[300]
			},
			// @ts-expect-error copied over from dashboard
			neutral: {
				main: '#F4F4F3',
				light: '#F8F8FB',
				dark: '#DEDEE1',
				contrastText: '#222222'
			}
		},
		components: {
			MuiDialog: {
				defaultProps: {
					scroll: 'paper'
				},
				styleOverrides: {
					paper: { width: '100%' }
				}
			},
			MuiDialogTitle: {
				styleOverrides: {
					root: ({ theme: { typography } }) => ({
						...typography.subtitle1Bold,
						padding: 20
					})
				}
			},
			MuiDialogActions: {
				styleOverrides: {
					root: {
						padding: 20
					}
				}
			},
			MuiDialogContent: {
				styleOverrides: {
					root: {
						padding: '0 20px 20px 20px'
					}
				}
			},
			MuiPaginationItem: {
				styleOverrides: {
					root: ({ theme: { palette, typography } }) => ({
						...typography.body2,
						color: palette.text.primary,
						'&:hover': {
							background: 'none',
							border: `1px solid ${palette.grey[200]}`
						},
						'&:disabled': {
							color: palette.text.disabled
						},
						[`&.${paginationItemClasses.selected}`]: {
							fontWeight: 700,
							background: palette.grey[50],
							'&:hover': {
								background: palette.grey[50],
								border: 'none'
							}
						}
					})
				}
			},
			MuiSkeleton: {
				defaultProps: {
					animation: 'wave'
				}
			},
			MuiFilledInput: {
				styleOverrides: {
					root: ({ theme: { palette } }) => ({
						background: palette.grey[50]
					})
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: ({ theme: { palette, typography } }) => ({
						...typography.subtitle2,
						'&::placeholder': {
							color: palette.text.secondary
						},
						color: palette.text.primary
					})
				}
			},
			MuiLink: {
				defaultProps: {
					underline: 'none'
				}
			},
			MuiListItemIcon: {
				styleOverrides: {
					root: ({ theme: { palette } }) => ({
						[`& .${svgIconClasses.root}`]: {
							color: palette.text.secondary
						}
					})
				}
			},
			MuiListItemText: {
				styleOverrides: {
					root: ({ theme: { typography } }) => ({
						[`& .${typographyClasses.root}`]: {
							...typography.body2
						}
					})
				}
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						borderRadius: '0px',
						boxShadow: '0px 4px 16px #262C621F',
						margin: '0px !important'
					}
				}
			},
			MuiPopover: {
				defaultProps: {
					disableScrollLock: true
				}
			},
			MuiMenu: {
				defaultProps: {
					disableScrollLock: true
				}
			},
			MuiMenuItem: {
				styleOverrides: {
					root: ({ theme: { typography, palette } }) => ({
						...typography.body2,
						color: colors.grey[1200],
						'&:hover': {
							background: palette.grey[100]
						},
						'& .MuiListItemIcon-root': {
							minWidth: '24px !important'
						}
					})
				}
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: ({ theme: { typography } }) =>
						pick(typography.body2, ['fontSize', 'fontWeight', 'fontFamily', 'lineHeight'])
				}
			},
			MuiFormControlLabel: {
				styleOverrides: {
					root: ({ theme: { spacing } }) => ({
						marginLeft: `-${spacing(6)}`
					})
				}
			},
			MuiCheckbox: {
				styleOverrides: {
					root: ({ theme: { spacing, palette } }) => ({
						padding: spacing(4),
						marginRight: spacing(4),
						[`&.${checkboxClasses.checked}, &.${checkboxClasses.indeterminate}`]: {
							color: palette.secondary.main,
							[`&.${checkboxClasses.disabled}`]: {
								color: palette.secondary.light
							}
						},
						'& > svg': {
							width: spacing(20),
							height: spacing(20)
						}
					})
				}
			},
			MuiRadio: {
				styleOverrides: {
					root: ({ theme: { spacing, palette } }) => ({
						padding: spacing(4),
						marginRight: spacing(4),
						[`&.${radioClasses.checked}`]: {
							color: palette.secondary.main,
							[`&.${radioClasses.disabled}`]: {
								color: palette.secondary.light
							}
						},
						'& svg': {
							width: spacing(20),
							height: spacing(20)
						}
					})
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: { borderRadius: 0 },
					input: ({ theme: { palette, typography } }) => ({
						...typography.body2,
						color: palette.text.primary
					})
				}
			},
			MuiSelect: {
				styleOverrides: {
					select: ({ theme: { spacing } }) => ({
						padding: spacing(0, 11),
						height: spacing(48),
						display: 'flex',
						alignItems: 'center'
					})
				}
			},
			MuiAlert: {
				styleOverrides: {
					root: {
						boxShadow: 'none'
					}
				},
				variants: [
					{
						props: { severity: 'warning', variant: 'outlined' },
						style: ({ theme: { palette } }): CSSObject => ({
							background: palette.red[50],
							border: `1px solid ${palette.red[300]}`,
							[`& .${alertClasses.icon}`]: {
								color: palette.red[500]
							},
							[`& .${alertClasses.message}`]: {
								color: palette.text.primary
							}
						})
					}
				]
			},
			MuiSlider: {
				styleOverrides: {
					root: ({ theme: { palette } }) => ({
						color: palette.grey[600]
					}),
					rail: ({ theme: { palette, spacing } }) => ({
						background: palette.grey[400],
						height: spacing(8)
					}),
					thumb: ({ theme: { palette, spacing } }) => ({
						width: spacing(16),
						height: spacing(16),
						borderRadius: spacing(8),
						border: `1px solid ${palette.grey[700]}`,
						'&:hover': {
							boxShadow: 'none'
						},
						[`&:focus, &.Mui-active, &.Mui-focusVisible`]: {
							boxShadow: '0 0 0 10px rgba(212, 213, 210, 0.40)'
						},
						'&:before, &:after': {
							display: 'none'
						}
					}),
					valueLabel: ({ theme: { spacing } }) => ({
						paddingLeft: spacing(6),
						paddingRight: spacing(6)
					}),
					mark: ({ theme: { palette, spacing } }) => ({
						width: spacing(8),
						height: spacing(8),
						borderRadius: spacing(4),
						background: palette.grey[400],
						'&[data-index="0"]': {
							transform: `translate(0, -50%)`
						},
						'&[data-index="1"]': {
							transform: `translate(-50%, -50%)`
						},
						'&[data-index="2"]': {
							transform: `translate(-100%, -50%)`
						}
					}),
					markLabel: ({ theme: { spacing } }) => ({
						top: spacing(28),
						'&[data-index="0"]': {
							transform: 'unset'
						},
						'&[data-index="2"]': {
							transform: 'translateX(-100%)'
						}
					})
				}
			}
		}
	})
)
