import { useClearCompletedTasks, useCompletedTasks, useIndexIdsByStatus, useUploadTasks } from 'apis/tasks'
import clsx from 'clsx'
import noop from 'lodash/noop'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import Image from 'next/image'
import { useEffect, useRef } from 'react'

import TaskStatusAccordion from './TaskStatusAccordion'
import EmptyTaskStatusImg from './empty-task-status.png'

const EmptyTaskStatus = () => (
	<div className={clsx('w-full px-6 py-8', 'flex flex-col items-center')}>
		<Image
			priority
			src={EmptyTaskStatusImg}
			alt="Empty task status image"
			width={0}
			height={0}
			className={clsx('h-auto w-full')}
		/>
		<h5 className={clsx('mt-6')}>No indexing in progress</h5>
		<p className={clsx('text-body1', 'mt-3')}>Start indexing by uploading a video</p>
	</div>
)

const TaskStatusPanel = () => {
	const uploadIndexIds = useUploadTasks((state) => uniqBy(state, 'indexId').map((task) => task.indexId))
	const completedIndexIds = useCompletedTasks((state) => Object.keys(state).sort().reverse())

	const { data } = useIndexIdsByStatus({ statuses: ['validating', 'queued', 'pending', 'indexing'] })
	const indexIds = uniq([...uploadIndexIds, ...(data?.index_ids.sort().reverse() ?? []), ...completedIndexIds])

	const clearCompletedTasks = useClearCompletedTasks()
	const isMountRef = useRef(false)
	useEffect(() => {
		if (!isMountRef.current) {
			isMountRef.current = true
			return noop
		}

		return clearCompletedTasks
	}, [clearCompletedTasks])

	return (
		<div className={clsx('h-full w-full', 'bg-white')}>
			<div className={clsx('w-full', 'px-6 pb-5 pt-6', 'border-b border-grey-200')}>
				<h6 className={clsx('hidden tablet:block')}>Indexing status</h6>
				<p className={clsx('tablet:hidden', 'text-subtitle1 !text-black')}>Indexing status</p>
			</div>
			{indexIds.length > 0 ? (
				indexIds.map((indexId, index) => <TaskStatusAccordion key={indexId} indexId={indexId} open={index === 0} />)
			) : (
				<EmptyTaskStatus />
			)}
		</div>
	)
}

export default TaskStatusPanel
