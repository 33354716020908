import clsx from 'clsx'

const NavbarMobileButton = ({ className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
	<button
		type="button"
		className={clsx(
			className,
			'w-full py-3',
			'flex items-center gap-x-2',
			'text-start text-subtitle1',
			'[&>svg]:h-6 [&>svg]:w-6',
			'text-grey-1000'
		)}
		{...props}
	/>
)

export default NavbarMobileButton
