import type { SuspenseProps } from 'react'
import type { ErrorBoundaryPropsWithRender, FallbackProps } from 'react-error-boundary'

import { datadogLogs } from '@datadog/browser-logs'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { Button } from '@twelvelabs/tds'
import clsx from 'clsx'
import ReplayIcon from 'components/svg/Replay'
import ErrorIcon from 'public/icons/error.svg'
import React, { Suspense, createElement, forwardRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { getTLApiErrorData } from 'utils/error'
import { capitalizeFirstLetter } from 'utils/helpers'

const getErrorMessage = (error: Error, customMessage: string | undefined) => {
	if (customMessage != null) return customMessage

	const { message } = getTLApiErrorData({
		error,
		customErrorMessage: error.message,
		use500ErrorMessage: true,
		custom500ErrorMessage: 'Oops! Something went wrong. Please try again.'
	})

	return message
}

interface ErrorFallbackProps extends FallbackProps, Pick<React.HTMLAttributes<HTMLDivElement>, 'className'> {
	message?: string
	resetButton?: JSX.Element | null
}

export const ErrorFallback = ({
	className,
	error,
	resetErrorBoundary,
	message: customMessage,
	resetButton = (
		<Button className="mt-2" appearance="secondary">
			<ReplayIcon color="inherit" /> Retry
		</Button>
	)
}: ErrorFallbackProps) => {
	const message = getErrorMessage(error, customMessage)
	const resetButtonWithOnClick =
		resetButton &&
		React.cloneElement(resetButton, {
			onClick: resetErrorBoundary
		})

	return (
		<div className={clsx(className, 'h-full w-full', 'flex items-center justify-center')}>
			<div className={clsx('flex flex-col items-center', 'max-w-[660px]', 'p-3')}>
				<ErrorIcon className="h-10 w-10" />
				<p className={clsx('text-center text-body1 !text-grey-800', 'mt-1')}>{capitalizeFirstLetter(message)}</p>
				{resetButtonWithOnClick}
			</div>
		</div>
	)
}

const SuspenseFallback = (): JSX.Element | null => null

function withBoundary<Props extends object>(
	component: React.ComponentType<Props>,
	errorBoundaryProps?: Omit<ErrorBoundaryPropsWithRender, 'fallbackRender' | 'resetKeys'> & {
		fallbackRender?: (props: Props & FallbackProps) => ReturnType<ErrorBoundaryPropsWithRender['fallbackRender']>
		resetKeys?: (props: Props) => any[]
	},
	suspenseProps?: Omit<SuspenseProps, 'fallback'> &
		Partial<Pick<SuspenseProps, 'fallback'>> & { fallbackRender?: (props: Props) => JSX.Element }
): React.ForwardRefExoticComponent<React.PropsWithoutRef<Props> & React.RefAttributes<any>> {
	const Wrapped = forwardRef<React.ComponentType<Props>, Props>(
		(props: Props, ref: React.ForwardedRef<React.ComponentType<Props>>) => {
			const { reset } = useQueryErrorResetBoundary()

			const {
				fallbackRender: errorFallbackRender = ErrorFallback,
				resetKeys,
				...optionalErrorBoundaryProps
			} = errorBoundaryProps ?? {}

			const {
				fallback: suspenseFallback = <SuspenseFallback />,
				fallbackRender: suspenseFallbackRender,
				...optionalSuspenseProps
			} = suspenseProps ?? {}

			const element = createElement(component, { ...props, ref })
			return createElement(
				ErrorBoundary,
				{
					onReset: reset,
					fallbackRender: (errorProps) => errorFallbackRender({ ...props, ...errorProps }),
					...optionalErrorBoundaryProps,
					onError: (error, info) => {
						optionalErrorBoundaryProps?.onError?.(error, info)
						datadogLogs.logger.error(error.message, undefined, error)
					},
					resetKeys: resetKeys?.(props)
				},
				createElement(
					Suspense,
					{
						fallback: suspenseFallbackRender ? suspenseFallbackRender(props) : suspenseFallback,
						...optionalSuspenseProps
					},
					element
				)
			)
		}
	)

	const name = component.displayName || component.name || 'Unknown'
	Wrapped.displayName = `withBoundary(${name})`

	return Wrapped
}

export default withBoundary
