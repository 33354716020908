'use client'

import type { DialogProps } from 'components/Dialog'

import CancelIcon from '@mui/icons-material/Cancel'
import WarningIcon from '@mui/icons-material/Warning'
import Alert from '@mui/material/Alert'
import { Link } from '@twelvelabs/tds'
import { useChangePlan } from 'apis/billing'
import { PRICING_WEBSITE_URL } from 'app/dashboard/billing/utils/const'
import clsx from 'clsx'
import Dialog from 'components/Dialog'
import { useEffect, useState } from 'react'

import CancelCompleteModal from './CancelCompleteModal'

const content = ['Index expires 90 days from index creation date', 'Total duration indexed videos will not be reset']

const CancelConfirmationModal = ({
	open,
	onClose,
	reason,
	description,
	...props
}: Omit<DialogProps, 'action'> & { reason?: string; description?: string }): JSX.Element => {
	const changePlan = useChangePlan()
	const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)

	useEffect(() => {
		if (changePlan.isSuccess) {
			setIsCompleteModalOpen(true)
		}
	}, [changePlan.isSuccess])

	return (
		<>
			<Dialog
				open={open && !isCompleteModalOpen}
				onClose={(e, r): void => {
					if (changePlan.isPending) {
						return
					}
					onClose?.(e, r)
				}}
				keepMounted
				title="Cancel enrollment?"
				{...props}
				action={{
					label: 'Done',
					loading: changePlan.isPending,
					onClick: async (): Promise<void> => {
						try {
							await changePlan.mutateAsync({
								plan_name: 'free',
								cancel_reason: reason,
								cancel_description: description
							})
						} catch (error) {
							// console.error(error)
						}
						onClose?.({}, 'backdropClick')
					}
				}}
			>
				<div className={clsx('flex flex-col gap-y-4')}>
					<p className={clsx('text-body1 !text-grey-1000')}>
						Cancelling enrollment will revert you back to the <span className={clsx('text-subtitle2')}>Free Plan.</span>{' '}
						This reinstates certain limitations such as but not limited to,
					</p>
					<div className={clsx('flex flex-col gap-y-2', 'p-4', 'bg-grey-50')}>
						{content.map((item) => (
							<div key={item.toString()} className={clsx('flex items-center gap-x-2')}>
								<CancelIcon className={clsx('text-red-500')} />
								<p className={clsx('text-body1 !text-grey-800')}>{item}</p>
							</div>
						))}
						<p className={clsx('text-body1 !text-grey-800')}>
							For more details, see the{' '}
							<Link href={PRICING_WEBSITE_URL} target="__blank">
								pricing page
							</Link>
							.
						</p>
					</div>
					<Alert
						variant="outlined"
						severity="warning"
						icon={<WarningIcon fontSize="inherit" />}
						className={clsx('items-center')}
					>
						<p className={clsx('text-body2 !text-grey-900')}>
							{`Once an index expires, you `}
							<span className={clsx('text-subtitle3')}>cannot recover the video embeddings and the metadata.</span>
						</p>
					</Alert>
					<span className={clsx('text-body2 !text-grey-900')}>
						By clicking &quot;Done&quot; below, you acknowledge and agree to the changes in the terms.
					</span>
				</div>
			</Dialog>
			<CancelCompleteModal
				action={{
					label: 'Complete',
					onClick: (): void => {
						onClose?.({}, 'backdropClick')
						setIsCompleteModalOpen(false)
					}
				}}
				open={isCompleteModalOpen}
				onClose={(e, r): void => {
					onClose?.(e, r)
					setIsCompleteModalOpen(false)
				}}
			/>
		</>
	)
}

export default CancelConfirmationModal
