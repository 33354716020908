import type { InfiniteData } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'
import type {
	DefaultResponse,
	IndexErrorCode,
	PageResponseFormat,
	Sample,
	TwelveLabsApiError,
	UseCommonInfiniteQueryOptions,
	UseCommonQueryOptions
} from 'utils/response'

import { isPast } from 'date-fns'

import { getIndexKey, getIndexesKey } from './keys'
import { ApiType, getInstance, getInstanceByIndexId } from 'utils/network'

export enum EngineFamily {
	MARENGO = 'marengo',
	PEGASUS = 'pegasus'
}

export enum Engine {
	MARENGO_2_6 = 'marengo2.6',
	MARENGO_2_5 = 'marengo2.5',
	MARENGO_2 = 'marengo2',
	PEGASUS_1 = 'pegasus1',
	PEGASUS_1_1 = 'pegasus1.1'
}

enum EngineNumeric {
	'pegasus1.1',
	'pegasus1',
	'marengo2.6',
	'marengo2.5',
	'marengo2'
}
export function sortEngines(x: Engine, y: Engine): number {
	return EngineNumeric[x] - EngineNumeric[y]
}

type MarengoData = {
	engine_name: Engine.MARENGO_2 | Engine.MARENGO_2_5 | Engine.MARENGO_2_6
	engine_options: EngineOption[]
}
type PegasusData = {
	engine_name: Engine.PEGASUS_1 | Engine.PEGASUS_1_1
	engine_options: (EngineOption.visual | EngineOption.conversation)[]
	finetuned?: boolean
}
export type EngineData = MarengoData | PegasusData

export function isMarengo(engineData: EngineData): engineData is MarengoData {
	return engineData.engine_name.includes(EngineFamily.MARENGO)
}

export function isPegasus(engineData: EngineData): engineData is PegasusData {
	return engineData.engine_name.includes(EngineFamily.PEGASUS)
}

export enum EngineOption {
	'visual' = 'visual',
	'conversation' = 'conversation',
	'text_in_video' = 'text_in_video',
	'logo' = 'logo'
}

export enum GenerateToken {
	PEGASUS_INPUT_TOKENS = 'pegasus_input_tokens',
	PEGASUS_OUTPUT_TOKENS = 'pegasus_output_tokens'
}

export enum IndexAddon {
	'thumbnail' = 'thumbnail'
}

export enum GroupBy {
	'clip' = 'clip',
	'video' = 'video'
}

enum EngineOptionNumeric {
	'visual',
	'conversation',
	'text_in_video',
	'logo',
	'thumbnail'
}
export function sortEngineOptions<T extends EngineOption | IndexAddon>(x: T, y: T): number {
	return EngineOptionNumeric[x] - EngineOptionNumeric[y]
}

export interface IndexesParams {
	/**
	 * @default 0
	 */
	page?: number
	/**
	 * @default 10
	 * @max 100
	 */
	page_limit?: number
	/**
	 * @default 'created_at'
	 */
	sort_by?: 'created_at' | 'updated_at' | 'video_count'
	/**
	 * @default 'desc
	 */
	sort_option?: 'asc' | 'desc'
	index_name?: string
	engine_id?: Engine
	engine_family?: EngineFamily
	deleted?: boolean
	expired?: boolean
	/**
	 * @default 'playground'
	 */
	params?: string
}

export interface IndexResponse extends DefaultResponse {
	index_name: string
	addons: IndexAddon[]
	engines: EngineData[]
	video_count: number
	total_duration: number
	deleted?: boolean
	expires_at?: string | null
	// aggregated field from client side
	isExpired?: boolean
}

type InfinitePageIndexesPageParam = number | undefined
export function infiniteIndexesOptions(
	params?: Omit<IndexesParams, 'page'>,
	sample?: boolean
): UseCommonInfiniteQueryOptions<
	AxiosResponse<PageResponseFormat<IndexResponse>>,
	TwelveLabsApiError<IndexErrorCode>,
	InfiniteData<PageResponseFormat<IndexResponse>, InfinitePageIndexesPageParam>,
	AxiosResponse<PageResponseFormat<IndexResponse>>,
	ReturnType<typeof getIndexesKey>,
	InfinitePageIndexesPageParam
> {
	const aggregatedParams: IndexesParams = { deleted: false, expired: false, ...params }

	return {
		queryKey: getIndexesKey({ ...aggregatedParams, sample }),
		queryFn: async ({ pageParam: page }) =>
			getInstance(sample ? ApiType.SAMPLE : ApiType.TWELVE_LABS)
				.get<PageResponseFormat<IndexResponse> | (Omit<PageResponseFormat<never>, 'data'> & { data: undefined })>(
					'/indexes',
					{ params: { ...aggregatedParams, page } }
				)
				.then((res): AxiosResponse<PageResponseFormat<IndexResponse>> => {
					if (!res.data) {
						return {
							...res,
							data: {
								data: [] as IndexResponse[],
								page_info: {
									page: 1,
									limit_per_page: params?.page_limit ?? 10,
									total_page: 0,
									total_results: 0
								}
							}
						}
					}

					return {
						...res,
						data: {
							...res.data,
							data: (res.data.data ?? []).map((item) => ({
								...item,
								isExpired: item.expires_at ? isPast(new Date(item.expires_at)) : false
							}))
						}
					}
				}),
		initialPageParam: 1,
		getNextPageParam: ({ data: { page_info } }) =>
			page_info.page < page_info.total_page ? page_info.page + 1 : undefined
	}
}

export function indexOptions<Response extends IndexResponse>(
	indexId: string
): UseCommonQueryOptions<
	AxiosResponse<Response> & Sample,
	TwelveLabsApiError<IndexErrorCode>,
	Response & Sample,
	ReturnType<typeof getIndexKey>
> {
	return {
		queryKey: getIndexKey(indexId),
		queryFn: async () => {
			const instance = await getInstanceByIndexId(indexId)
			const response = await instance.get<Response>(`/indexes/${indexId}`).then((res) => ({
				...res,
				data: { ...res.data, isExpired: res.data.expires_at ? isPast(new Date(res.data.expires_at)) : false }
			}))
			if (instance.defaults.baseURL?.includes('/samples')) return { ...response, sample: true }
			return response
		},
		select: ({ data, sample }) => ({ ...data, sample })
	}
}
