import type { VideoType } from 'apis/tasks'

import clsx from 'clsx'
import Tooltip from 'components/Tooltip'
import React from 'react'
import { getVideosTotalDuration } from 'utils/helpers'

interface Props {
	className?: string
	videos: VideoType[]
	exceedUsageLimit: boolean
}

const VideoDurationMessage = ({ className, videos, exceedUsageLimit }: Props): JSX.Element | null => {
	const [totalDuration, formattedTotalDuration] = getVideosTotalDuration(videos)

	// Some video codecs are not supported by browser to extract duration information
	const isThereVideoWithoutDuration = videos.some((video) => video.duration == null)

	if (videos.length === 0 || totalDuration === 0) {
		return null
	}

	return exceedUsageLimit ? (
		<p className={clsx(className, 'text-body2 !text-red-500')} data-testid="usage-limit-error-message">
			Total video duration exceeds index limit. Please remove videos.
		</p>
	) : (
		<div className={clsx('flex items-center gap-1', className)}>
			<p className="text-body2 !text-grey-1000">Total video duration is {formattedTotalDuration}</p>
			{isThereVideoWithoutDuration && (
				<Tooltip
					name="video_upload_duration"
					content={
						<div className="text-body2 md:text-white">
							Duration may be inaccurate due to video formats not supported by browser (.avi files etc.)
						</div>
					}
					title="Inaccurate duration"
				/>
			)}
		</div>
	)
}

export default VideoDurationMessage
