import type { UserProfile } from '@auth0/nextjs-auth0/client'
import type { UseMutationResult } from '@tanstack/react-query'

import { useMutation } from '@tanstack/react-query'
import { ApiType, getInstance } from 'utils/network'

// eslint-disable-next-line import/prefer-default-export
export function useUpdateUserMetadata(): UseMutationResult<UserProfile, unknown, Record<string, unknown>> {
	return useMutation({
		mutationFn: (metadata: Record<string, unknown>) =>
			getInstance(ApiType.INTERNAL)
				.patch<UserProfile>(`/user/metadata`, metadata)
				.then((res) => res.data)
	})
}
