import type { Theme } from '@mui/material'
import type { MUIStyledCommonProps } from '@mui/system'
import type { SVGProps } from 'react'

import ImageIcon from '@mui/icons-material/Image'
import { IndexAddon, EngineOption } from 'apis/indexes'
import Conversation from 'components/svg/Conversation'
import LogoDetection from 'components/svg/LogoDetection'
import TextInVideo from 'components/svg/TextInVideo'
import Thumbnail from 'components/svg/Thumbnail'
import VisualSound from 'components/svg/VisualSound'
import { memo } from 'react'

const OptionIcon = ({
	option,
	...props
}: { option: EngineOption | IndexAddon } & SVGProps<SVGSVGElement> &
	MUIStyledCommonProps<Theme>): JSX.Element | null => {
	switch (option) {
		case EngineOption.visual:
			return <VisualSound {...props} />
		case EngineOption.text_in_video:
			return <TextInVideo {...props} />
		case EngineOption.conversation:
			return <Conversation {...props} />
		case EngineOption.logo:
			return <LogoDetection {...props} />
		case IndexAddon.thumbnail:
			return <Thumbnail {...props} />
		default:
			return <ImageIcon />
	}
}

export default memo(OptionIcon)
