import { Dialog, DialogActions, DialogContent, DialogTitle, dialogClasses } from '@mui/material'
import { Button } from '@twelvelabs/tds'
import { useAddUploadQueue } from 'apis/tasks'
import clsx from 'clsx'
import VideoDurationMessage from 'components/VideoDurationMessage'
import noop from 'lodash/noop'
import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import useRouter from 'utils/hooks/useRouter'
import useUsageLimit from 'utils/hooks/useUsageLimit'
import useVideosToUpload from 'utils/hooks/useVideosToUpload'
import { NavbarMenuType, useOpenNavbarMenu } from 'widgets/Navbar'

import SupportedVideoInfo from './common/SupportedVideoInfo'
import VideoDropzone from './common/VideoDropzone'
import { DIALOG_OPEN_QUERY_KEY } from './hooks'

type IndexTaskCreateDialogParams = {
	[DIALOG_OPEN_QUERY_KEY]: string
}

const IndexTaskCreateDialog = (): JSX.Element => {
	const router = useRouter()
	const searchParams = useSearchParams<IndexTaskCreateDialogParams>()
	const indexId = searchParams?.get(DIALOG_OPEN_QUERY_KEY)

	const { videos, cleanUpVideos, onVideoFilesAdded, onVideoRemoved } = useVideosToUpload()
	const { exceedUsageLimit, shouldDisableUpload } = useUsageLimit(indexId ?? undefined, videos)

	const addUploadQueue = useAddUploadQueue()
	const openNavbarMenu = useOpenNavbarMenu()

	useEffect(
		function clearFileAndUrlAfterClose(): () => void {
			if (indexId) return noop

			// Wait for 1 second before cleaning the state
			const timeout = setTimeout(() => {
				cleanUpVideos()
			}, 1000)

			return () => clearTimeout(timeout)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[indexId]
	)

	const onClose = () => router.replace<IndexTaskCreateDialogParams>({ params: { [DIALOG_OPEN_QUERY_KEY]: undefined } })

	const onUpload = async () => {
		if (!indexId) return
		addUploadQueue(indexId, videos)
		cleanUpVideos()
		onClose()
		openNavbarMenu(NavbarMenuType.TaskStatus)
	}

	return (
		<Dialog fullWidth sx={{ [`& .${dialogClasses.paper}`]: { maxWidth: 720 } }} open={!!indexId} onClose={onClose}>
			<DialogTitle>Upload videos</DialogTitle>
			<DialogContent
				className={clsx('flex flex-col', 'min-h-[450px] md:min-h-[auto]', 'max-h-[500px] md:max-h-[auto]', 'pb-0')}
			>
				{indexId && <SupportedVideoInfo indexId={indexId} className="mb-5 md:mb-6" />}
				<VideoDropzone
					className="flex-1 md:basis-[290px]"
					videos={videos}
					onDrop={onVideoFilesAdded}
					onRemove={onVideoRemoved}
				/>
				<VideoDurationMessage
					className={clsx('tablet:hidden', 'pt-3', 'max-w-[270px]', 'self-end')}
					videos={videos}
					exceedUsageLimit={exceedUsageLimit}
				/>
			</DialogContent>
			<DialogActions className="justify-between">
				<Button appearance="subtle" onClick={onClose} type="button">
					Close
				</Button>
				<div className="flex items-center gap-x-2">
					<VideoDurationMessage className="hidden tablet:flex" videos={videos} exceedUsageLimit={exceedUsageLimit} />
					<Button appearance="primary" type="button" onClick={onUpload} disabled={shouldDisableUpload}>
						Upload
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	)
}

export default IndexTaskCreateDialog
