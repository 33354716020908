import type { IndexType } from './IndexTypeTabs'

import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { tooltipClasses } from '@mui/material/Tooltip'
import clsx from 'clsx'
import Section from 'components/Section'
import Tooltip from 'components/Tooltip'
import mixpanel from 'mixpanel-browser'
import { useSearchParams } from 'next/navigation'
import useRouter from 'utils/hooks/useRouter'

import FreePlanAlert from './FreePlanAlert'
import IndexGrid from './IndexGrid'
import IndexTypeTabs from './IndexTypeTabs'

const IndexSectionLabel = ({ title = 'Indexes' }: { title?: string }) => (
	<div className={clsx('flex items-center gap-x-1')}>
		<p className={clsx('text-heading7 !text-grey-900 tablet:text-heading5')}>{title}</p>
		<Tooltip
			name="indexes"
			content="Your index is where it all begins. An index is a basic unit in which you store, organize, and retrieve your video data whenever you need it."
			title="What is an index?"
			placement="right"
			className={clsx(`[&>.${tooltipClasses.tooltip}]:max-w-[500px]`)}
		>
			<InfoOutlined fontSize="small" color="inherit" className={clsx('text-grey-500')} />
		</Tooltip>
	</div>
)

export interface IndexSectionProps {
	/**
	 * @default 12
	 */
	limit?: number
	footer?: React.ReactNode
	showInfinite?: boolean
}

export type IndexSectionQueryParam = {
	type: IndexType
}

const IndexSection = ({ limit, footer, showInfinite }: IndexSectionProps) => {
	const router = useRouter()
	const searchParams = useSearchParams<IndexSectionQueryParam>()
	const indexType = searchParams?.get('type') ?? 'my'

	return (
		<Section
			className={clsx('px-5 tablet:px-0', 'mt-5 tablet:mt-0')}
			label={
				<div className={clsx('flex flex-col gap-y-5')}>
					<IndexSectionLabel />
					<IndexTypeTabs
						type={indexType}
						onTypeChange={(type) => {
							router.replace<IndexSectionQueryParam>({ params: { type } })
							mixpanel.track('click', { type: 'tab', element: 'indexes_type', value: type })
						}}
					/>
				</div>
			}
		>
			{indexType !== 'sample' && <FreePlanAlert className={clsx('mb-5')} />}
			<IndexGrid limit={limit} showInfinite={showInfinite} sample={indexType === 'sample'} />
			{footer}
		</Section>
	)
}

export default IndexSection
