import clsx from 'clsx'

interface Props {
	className?: string
	children: React.ReactNode
	redBg: boolean
	greenBg: boolean
}

const IconWrapper = ({ className, children, redBg, greenBg }: Props): JSX.Element => (
	<div
		className={clsx(
			className,
			'h-10 w-10',
			'flex items-center justify-center',
			'text-[24px] text-white',
			'rounded-full',
			{
				'!bg-red-500': redBg,
				'bg-green-700': greenBg,
				'bg-grey-600': !greenBg && !redBg
			}
		)}
	>
		{children}
	</div>
)

export default IconWrapper
