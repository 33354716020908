import type { IndexesParams, EngineData, IndexAddon, IndexResponse } from './options'
import type { PageResponseFormat, TwelveLabsApiError } from 'utils/response'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useClearIndexFromTasks } from 'apis/tasks'
import { useCallback } from 'react'
import { ApiType, getInstance } from 'utils/network'

import { getIndexesKey, getSampleIndexesKey } from './keys'

export interface IndexParams {
	index_name: string
	engines: EngineData[]
	addons?: IndexAddon[]
}

export function useCreateIndex<
	Response extends {
		type: 'create_index'
		_id: string
		message: string
	},
	Params extends IndexParams
>() {
	const queryClient = useQueryClient()

	return useMutation<Response, TwelveLabsApiError, Params>({
		mutationFn: (params) =>
			getInstance(ApiType.TWELVE_LABS)
				.post<Response>('/indexes', params)
				.then((res) => res.data),
		onSuccess: () => queryClient.resetQueries({ queryKey: getIndexesKey() })
	})
}

export function useUpdateIndex<
	Response extends { type: 'update_index'; data: string },
	Params extends { index_id: string } & Pick<IndexParams, 'index_name'>
>() {
	const queryClient = useQueryClient()

	return useMutation<Response, TwelveLabsApiError, Params>({
		mutationFn: ({ index_id, index_name }: Params) =>
			getInstance(ApiType.TWELVE_LABS)
				.put<Response>(`/indexes/${index_id}`, { index_name })
				.then((res) => res.data),
		onSuccess: () => queryClient.resetQueries({ queryKey: getIndexesKey() })
	})
}

export function useDeleteIndex<
	Response extends {
		type: 'delete_index'
		message: string
	},
	Params extends { index_id: string }
>() {
	const queryClient = useQueryClient()
	const clearIndexFromTasks = useClearIndexFromTasks()

	return useMutation<Response, TwelveLabsApiError, Params>({
		mutationFn: ({ index_id }: Params) =>
			getInstance(ApiType.TWELVE_LABS)
				.delete<Response>(`/indexes/${index_id}`)
				.then((res) => res.data),
		onSuccess: (_, { index_id }) => {
			clearIndexFromTasks(index_id)
			queryClient.resetQueries({ queryKey: getIndexesKey() })
		}
	})
}

export function useFetchIndexHasVideo() {
	const retrieveIndex = useCallback(
		async (params: IndexesParams, sample?: boolean, page = 1): Promise<IndexResponse | undefined> => {
			const {
				data: { data, page_info: pageInfo }
			} = await (sample ? getInstance(ApiType.SAMPLE) : getInstance(ApiType.TWELVE_LABS)).get<
				PageResponseFormat<IndexResponse>
			>('/indexes', {
				params: { ...params, deleted: false, expired: false }
			})

			const index = data.find(({ isExpired, video_count }) => !isExpired && video_count > 0)
			if (index != null) {
				return index
			}

			return page < pageInfo.total_page ? retrieveIndex(params, sample, page + 1) : undefined
		},
		[]
	)

	return useMutation<[IndexResponse | undefined, boolean], TwelveLabsApiError, IndexesParams>({
		mutationFn: async (params) => {
			const myIndex = await retrieveIndex(params)
			if (myIndex != null) {
				return [myIndex, false]
			}

			const sampleIndex = await retrieveIndex(params, true)
			return [sampleIndex, true]
		}
	})
}

export function useIndexWithVideoQuery(customParams: IndexesParams): IndexResponse | undefined {
	const commonParams: IndexesParams = { deleted: false, expired: false, sort_by: 'created_at' }
	const sampleIndexParams: IndexesParams = { ...commonParams, ...customParams, page_limit: 1 }
	const indexParams: IndexesParams = { ...commonParams, ...customParams, page_limit: 8 }

	const { data: indexWithVideo } = useQuery({
		queryKey: getIndexesKey(indexParams),
		queryFn: () =>
			getInstance(ApiType.TWELVE_LABS).get<PageResponseFormat<IndexResponse>>('/indexes', { params: indexParams }),
		select: ({ data }) => data?.data?.find(({ video_count }) => video_count > 0)
	})

	const { data: sampleIndex } = useQuery({
		queryKey: getSampleIndexesKey(sampleIndexParams),
		queryFn: () =>
			getInstance(ApiType.SAMPLE).get<PageResponseFormat<IndexResponse>>('/indexes', { params: sampleIndexParams }),
		select: ({ data }) => data?.data?.[0],
		enabled: !indexWithVideo,
		staleTime: Infinity
	})

	return indexWithVideo || sampleIndex
}
