'use client'

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import clsx from 'clsx'
import Wrap from 'components/Wrap'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React, { useEffect, useState } from 'react'

import HideMotionDiv from './HideMotionDiv'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	active?: boolean
	expanded?: boolean
	href?: string
	Icon: React.FC<React.SVGProps<SVGSVGElement>>
	pattern?: RegExp
	target?: '_self' | '_blank'
	tooltipLabel?: string
}

const SideMenuItem = ({
	active,
	className,
	children,
	expanded,
	href,
	Icon,
	pattern,
	target,
	tooltipLabel,
	...restProps
}: Props) => {
	const pathname = usePathname()
	const isActive = active || (pattern ? pathname?.match(pattern) : false)

	// The tooltip can be displayed in the wrong position during sidebar's collapsing animation,
	// hence delay the tooltip display until the animation is finished
	const [shouldTooltipOpen, setShouldTooltipOpen] = useState(false)
	useEffect(() => {
		setShouldTooltipOpen(false)

		const timeout = setTimeout(() => {
			setShouldTooltipOpen(true)
		}, 1000)

		return () => {
			clearTimeout(timeout)
		}
	}, [expanded])

	return (
		<Wrap
			if={Boolean(href)}
			with={Link}
			wrapperProps={{
				target,
				href: href as string,
				rel: target === '_blank' ? 'noreferrer' : undefined
			}}
		>
			<Tooltip
				title={!expanded && shouldTooltipOpen && tooltipLabel}
				placement="right"
				TransitionProps={{ timeout: { appear: 300, enter: 300, exit: 0 } }}
				PopperProps={{
					sx: {
						[`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
							marginLeft: '4px'
						}
					}
				}}
			>
				<div
					className={clsx(
						'flex items-center gap-x-2',
						'h-10 px-2',
						'cursor-pointer select-none truncate rounded text-subtitle2',
						{
							'bg-moss_green-200 text-moss_green-800': isActive,
							'text-grey-800 hover:bg-grey-100': !isActive
						},
						className
					)}
					{...restProps}
				>
					<Icon className={clsx('h-5 min-w-[23px]', isActive ? 'text-moss_green-800' : 'text-grey-700')} />
					<HideMotionDiv width="100%" show={expanded}>
						{children}
					</HideMotionDiv>
				</div>
			</Tooltip>
		</Wrap>
	)
}

export default SideMenuItem
