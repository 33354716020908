const Routes = {
	ACCOUNT_SETTINGS: '/dashboard/account-settings',
	API_KEY: '/dashboard/api-key',
	BILLING: '/dashboard/billing',
	LOGIN: '/api/auth/login',
	LOGOUT: '/api/auth/logout',
	ONBOARDING: '/onboarding',
	PLAYGROUND: '/',
	INTEGRATIONS: '/dashboard/integrations',
	USAGE: '/dashboard/usage',
	DASHBOARD: '/dashboard/api-key'
}

export default Routes
