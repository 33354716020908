'use client'

import { useIsFreePlan } from 'apis/billing'
import UpgradePlanButton from 'app/dashboard/billing/components/Usage/UpgradePlanButton'
import { StatusCodes } from 'http-status-codes'
import mixpanel from 'mixpanel-browser'
import ErrorPage from 'pages/_error'
import { getRateLimitMessage } from 'utils/rateLimit'

const upgradePlanMessage = 'Or upgrade your plan to increase the rate limit per day.'

interface Props {
	className?: string
	resetDate?: Date
}

const RateLimitErrorPage = ({ className, resetDate }: Props) => {
	const { data: isFreePlan } = useIsFreePlan()

	const rateLimitMessage = getRateLimitMessage(resetDate)

	const onUpgradeButtonClick = () => {
		mixpanel.track('click', { type: 'button', element: 'upgrade_plan' })
	}

	return (
		<ErrorPage
			className={className}
			hideContactButton
			statusCode={StatusCodes.TOO_MANY_REQUESTS}
			title={null}
			message={`${rateLimitMessage} ${upgradePlanMessage}`}
			action={isFreePlan ? <UpgradePlanButton show size="xs" onClick={onUpgradeButtonClick} /> : undefined}
		/>
	)
}

export default RateLimitErrorPage
