import muiSvg from './muiSvg'

const CreateIndex = muiSvg(
	(props): JSX.Element => (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M13.5032 6.79999L16.1912 9.20008H23.3592C24.7928 9.20008 25.4499 10.6001 25.5992 11.5334V28.0001H5.43922C4.79922 28.0001 3.19922 27.2001 3.19922 25.6667V6.79999H13.5032Z"
				fill="#9AED59"
			/>
			<path
				d="M9.03472 24.2C9.03472 26.48 7.10398 27.5778 5.19922 28H25.3706C26.8945 28 28.6609 27.5778 28.7358 26C28.8785 22.9917 28.7358 16.79 28.7358 15.65C28.7358 13.2 26.8361 12.8 25.7378 12.8H12.0327C9.03472 12.8 8.97946 14.8 9.03472 16.125V24.2Z"
				fill="#F7FEF2"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.03472 16.125V24.2C9.03472 24.9093 8.84784 25.5042 8.533 26C7.83587 27.0977 6.51138 27.7091 5.19922 28H25.3706C26.8945 28 28.6609 27.5778 28.7358 26C28.846 23.6776 28.786 19.4519 28.753 17.1213C28.7432 16.4329 28.7358 15.9099 28.7358 15.65C28.7358 13.2 26.8361 12.8 25.7378 12.8H12.0327C9.03472 12.8 8.97946 14.8 9.03472 16.125ZM10.7397 26H25.3706C25.6637 26 25.9461 25.9794 26.1963 25.9372C26.4514 25.8942 26.631 25.8358 26.7428 25.783C26.7431 25.7829 26.7426 25.7831 26.7428 25.783C26.8419 23.5134 26.7869 19.5658 26.7542 17.2534C26.7438 16.5182 26.7358 15.9482 26.7358 15.65C26.7358 15.2989 26.669 15.1363 26.6409 15.0813C26.6158 15.0321 26.589 15.0034 26.5428 14.9727C26.4843 14.9339 26.3827 14.8867 26.2252 14.8508C26.0688 14.8151 25.8975 14.8 25.7378 14.8H12.0327C11.4964 14.8 11.2509 14.8905 11.1701 14.93C11.1439 14.9428 11.1312 14.9519 11.1269 14.9554C11.1262 14.9564 11.1252 14.958 11.1238 14.9604C11.1097 14.985 11.0689 15.0743 11.0444 15.2816C11.0196 15.4908 11.0201 15.7337 11.033 16.0416C11.0341 16.0694 11.0347 16.0972 11.0347 16.125V24.2C11.0347 24.8552 10.9288 25.4556 10.7397 26ZM11.1258 14.9563C11.1247 14.9572 11.1249 14.9571 11.1258 14.9563Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.2004 21.6V24.4H20.2004V21.6H23.0004V19.6L20.2004 19.6V16.8H18.2004V19.6L15.4004 19.6V21.6H18.2004Z"
				fill="black"
			/>
		</svg>
	)
)

export default CreateIndex
