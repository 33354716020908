import Videocam from '@mui/icons-material/Videocam'
import { isFileType, isUrlType, UploadStatus, type UploadTask, type VideoType } from 'apis/tasks'
import clsx from 'clsx'

import UploadingStatus from './UploadingStatus'
import ValidatingStatus from './ValidatingStatus'
import { UploadErrorMessage } from '../common'

interface Props {
	task: UploadTask
}

const getVideoTitle = (video: VideoType) => {
	if (isFileType(video)) return video.file.name
	if (isUrlType(video)) return video.title
	throw new Error('Invalid video type')
}

const TaskStatus = ({ task }: Props) => {
	switch (task.status) {
		case UploadStatus.Queued:
		case UploadStatus.Uploading:
			return <UploadingStatus task={task} />
		case UploadStatus.Done:
			return <ValidatingStatus task={task} />
		case UploadStatus.Failed:
			return <UploadErrorMessage error={{ code: task.code, message: task.message }} />
		default:
			return null
	}
}

const UploadProgressItem = ({ task }: Props) => (
	<div className={clsx('flex items-center', 'w-full pr-0.5')}>
		<Videocam fontSize="small" color="inherit" className="mr-2 !text-grey-600" />
		<p className="flex-1 truncate pr-6 text-body1 !text-grey-1000">{getVideoTitle(task)}</p>
		<TaskStatus task={task} />
	</div>
)

export default UploadProgressItem
