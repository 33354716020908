import type { DialogProps } from 'components/Dialog'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import clsx from 'clsx'
import Dialog from 'components/Dialog'

const content = [
	'Index up to 60,000 minutes of video',
	'5 times increase in concurrent indexing tasks',
	'Access your indexes with no limit'
]

const UpgradeSuccessModal = ({ open, onClose, ...props }: DialogProps): JSX.Element => {
	const now = new Date()
	const firstNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)

	return (
		<Dialog open={open} onClose={onClose} keepMounted title="Thank you for upgrading to Developer plan" {...props}>
			<div className={clsx('flex flex-col gap-y-4')}>
				<p className={clsx('text-body1 !text-grey-1000')}>Congratulations! Now you can,</p>
				<div className={clsx('flex flex-col gap-y-2', 'p-4', 'bg-grey-50')}>
					{content.map((item) => (
						<div key={item.toString()} className={clsx('flex items-center gap-x-2')}>
							<CheckCircleIcon className={clsx('text-secondary')} />
							<p className={clsx('text-body1 !text-grey-900')}>{item}</p>
						</div>
					))}
				</div>
				<p className={clsx('text-body2')}>
					{`Your plan will be charged every first of the month starting from `}
					<span className={clsx('text-subtitle3 !text-grey-900')}>
						{`${firstNextMonth.toLocaleDateString('en-US', {
							month: 'long',
							day: 'numeric',
							year: 'numeric'
						})}. `}
					</span>
					It may take 2-3 business days for the payment to be processed.
				</p>
			</div>
		</Dialog>
	)
}

export default UpgradeSuccessModal
