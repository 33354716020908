import * as colors from '@twelvelabs/tds/colors'

// eslint-disable-next-line import/prefer-default-export
export const getBgColors = (isSuccess: boolean, isError: boolean, isHovering: boolean, disabled?: boolean) => {
	let pair: [string, string]

	if (disabled) {
		pair = [colors.grey[200], colors.grey[300]]
	} else if (isError) {
		pair = [colors.red[50], colors.red[500]]
	} else if (isSuccess || isHovering) {
		pair = [colors.green[50], colors.green[500]]
	} else {
		pair = ['none', colors.grey[400]]
	}

	// # in URL starts a fragment identifier, hence escape it with %23 instead
	return pair.map((color) => color.replace('#', '%23'))
}
