'use client'

import type { TooltipProps as MUITooltipProps } from '@mui/material/Tooltip'

import CloseIcon from '@mui/icons-material/Close'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Dialog, IconButton } from '@mui/material'
import TooltipBase from '@mui/material/Tooltip'
import { styled, ThemeProvider } from '@mui/material/styles'
import { clsx } from 'clsx'
import theme from 'constants/theme'
import debounce from 'lodash/debounce'
import mixpanel from 'mixpanel-browser'
import { isValidElement } from 'react'
import { createRoot } from 'react-dom/client'
import useIsMobile from 'utils/hooks/useIsMobile'

interface AlertDialogProps {
	title: React.ReactNode
	content: React.ReactNode
	onClose?: () => void
}

const StyledIconButton = styled(IconButton)`
	padding: 0 !important;
`

const AlertDialog = ({ title, content, onClose }: AlertDialogProps): JSX.Element => (
	<ThemeProvider theme={theme}>
		<Dialog open onClose={onClose}>
			<div className={clsx('flex justify-between', 'p-5')}>
				<p className={clsx('text-subtitle1-bold')}>{title}</p>
				<StyledIconButton onClick={onClose}>
					<CloseIcon fontSize="inherit" />
				</StyledIconButton>
			</div>
			<div className={clsx('px-5 pb-5')}>{typeof content === 'string' ? <p>{content}</p> : content}</div>
		</Dialog>
	</ThemeProvider>
)

export const alertDialog = {
	open: (props: AlertDialogProps): void => {
		const container = document.createElement('div')
		container.className = 'alert-dialog-overlay'
		document.body.appendChild(container)

		const root = createRoot(container)

		const handleClose = (): void => {
			root.unmount()
			document.body.removeChild(container)
		}

		root.render(<AlertDialog {...props} onClose={handleClose} />)
	}
}

export interface TooltipProps extends Omit<MUITooltipProps, 'children' | 'title' | 'content'> {
	content: React.ReactNode
	name?: string
	title?: React.ReactNode
	children?: JSX.Element
}

const Tooltip = ({
	content,
	name,
	title,
	children = <InfoOutlined className={clsx('text-grey-700', 'h-4 w-4')} />,
	onMouseEnter,
	...props
}: TooltipProps): JSX.Element => {
	const isMobile = useIsMobile()

	const logHoverEvent = debounce(() => {
		if (!name) return
		let tooltipContent: string | undefined
		if (typeof content === 'string') {
			tooltipContent = content
		} else if (isValidElement(content) && typeof content.props.children === 'string') {
			tooltipContent = content.props.children
		} else if (typeof title === 'string') {
			tooltipContent = title
		}

		mixpanel.track('hover', {
			type: 'tooltip',
			element: name,
			value: tooltipContent
		})
	}, 500)

	return isMobile ? (
		<IconButton
			onClick={(): void => {
				if (!title || !content) return
				alertDialog.open({ title, content })
				logHoverEvent()
			}}
			sx={{ padding: 0 }}
		>
			{children}
		</IconButton>
	) : (
		<TooltipBase
			title={content}
			onMouseEnter={(e) => {
				onMouseEnter?.(e)
				logHoverEvent()
			}}
			{...props}
		>
			{children}
		</TooltipBase>
	)
}
export default Tooltip
