'use client'

import * as Intercom from '@intercom/messenger-js-sdk'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import { useIndexWithVideoQuery, EngineFamily } from 'apis/indexes'
import clsx from 'clsx'
import { NAVBAR_HEIGHT } from 'constants/measurements'
import { pathName } from 'constants/paths'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import BookIcon from 'public/icons/book.svg'
import ClassifyIcon from 'public/icons/classify.svg'
import ContactSupportIcon from 'public/icons/contact-support.svg'
import DiscordOutlineIcon from 'public/icons/discord-outline.svg'
import ExamplesIcon from 'public/icons/examples.svg'
import GenerateIcon from 'public/icons/generate.svg'
import HomeIcon from 'public/icons/home.svg'
import IndexesIcon from 'public/icons/indexes.svg'
import OutlinkIcon from 'public/icons/outlink.svg'
import SearchIcon from 'public/icons/search.svg'
import SettingsIcon from 'public/icons/settings.svg'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'
import useAuth0 from 'utils/hooks/useAuth0'
import { UsageVertical } from 'widgets/Usage'

import UserInfo from './UserInfo'
import NewMenuButton from '../../components/NewMenuButton'

const menuItemClassName = clsx(
	'flex items-center',
	'h-14 px-5 py-1 w-full',
	'text-subtitle2 text-grey-800',
	'active:bg-grey-100'
)

const tryOnDesktopTag = (
	<p className={clsx('text-subtitle3 !text-moss_green-800', 'ml-auto bg-moss_green-50 px-2 py-0.5')}>Try on desktop</p>
)

const MobileNavMenu = () => {
	const [isOpen, setIsOpen] = useState(false)
	const openMenu = () => setIsOpen(true)
	const closeMenu = () => setIsOpen(false)

	const { logout, user } = useAuth0()
	const handleLogout = () => {
		mixpanel.track('click', { type: 'button', element: 'signout' })
		logout()
	}
	const handleContactSupportClick = () => {
		Intercom.show()
		mixpanel.track('click', { type: 'button', element: 'intercom' })
		closeMenu()
	}

	const marengoIndexWithVideo = useIndexWithVideoQuery({ engine_family: EngineFamily.MARENGO })
	const pegasusIndexWithVideo = useIndexWithVideoQuery({ engine_family: EngineFamily.PEGASUS })
	const searchPath = pathName.search(marengoIndexWithVideo?._id)
	const generatePath = pathName.generate(pegasusIndexWithVideo?._id)

	const MenuIconComponent = isOpen ? CloseIcon : MenuIcon
	const handleNavMenuClick = () => {
		if (isOpen) {
			return closeMenu()
		}

		mixpanel.track('click', { type: 'button', element: 'hamburger_menu' })
		return openMenu()
	}

	const createLinkMenuHandler = (url: string) => () => {
		mixpanel.track('click', { type: 'link', element: 'navbar', url })
		closeMenu()
	}

	const handlePricingMenuClick = () => {
		mixpanel.track('click', {
			type: 'link',
			element: 'user_menu',
			url: `${process.env.NEXT_PUBLIC_COMPANY_SITE_URL}/pricing`
		})
	}

	return (
		<>
			<IconButton size="medium" className="text-grey-700" onClick={handleNavMenuClick}>
				<MenuIconComponent className="h-7 w-7" />
			</IconButton>
			{isOpen &&
				createPortal(
					<RemoveScroll
						className={clsx('fixed inset-0 z-mobile-nav-menu', 'bg-white', 'overflow-y-auto')}
						style={{ top: NAVBAR_HEIGHT, height: `calc(100vh - ${NAVBAR_HEIGHT}px)` }}
					>
						<div className={clsx('px-5 pb-10 pt-5', 'border-b border-grey-200')}>
							<UserInfo className="mb-5" user={user} />
							<UsageVertical />
							<NewMenuButton className="mt-5 w-full" expanded />
						</div>
						<div className={clsx('pb-5', 'border-b border-grey-200')}>
							<Link
								className={menuItemClassName}
								href={pathName.OVERVIEW}
								onClick={createLinkMenuHandler(pathName.OVERVIEW)}
							>
								<HomeIcon className="mr-2 h-5 w-5" /> Overview
							</Link>
							<Link
								className={menuItemClassName}
								href={pathName.indexes()}
								onClick={createLinkMenuHandler(pathName.indexes())}
							>
								<IndexesIcon className="mr-2 h-5 w-5" /> Indexes
							</Link>
							<Link
								className={menuItemClassName}
								href={pathName.EXAMPLES}
								onClick={createLinkMenuHandler(pathName.EXAMPLES)}
							>
								<ExamplesIcon className="mr-2 h-5 w-5" /> Examples
							</Link>
						</div>
						<div className={clsx('pb-5', 'border-b border-grey-200')}>
							<p className={clsx('px-5 pb-1 pt-3', 'text-xs font-medium leading-5 tracking-[1.92px] !text-grey-800')}>
								PLAYGROUND
							</p>
							<Link className={menuItemClassName} href={searchPath} onClick={createLinkMenuHandler(searchPath)}>
								<SearchIcon className="mr-2 h-5 w-5" /> Search
							</Link>
							<Link className={menuItemClassName} href={generatePath} onClick={createLinkMenuHandler(generatePath)}>
								<GenerateIcon className="mr-2 h-5 w-5" /> Generate
							</Link>
							<div className={clsx('!bg-transparent', menuItemClassName)}>
								<ClassifyIcon className="mr-2 h-5 w-5" /> Classify {tryOnDesktopTag}
							</div>
						</div>
						<div className={clsx('pb-5', 'border-b border-grey-200')}>
							<div className={clsx('!bg-transparent', menuItemClassName)}>
								<SettingsIcon className="mr-2 h-5 w-5" /> Settings {tryOnDesktopTag}
							</div>
							<a
								className={menuItemClassName}
								href={process.env.NEXT_PUBLIC_DOCS_URL}
								target="_blank"
								rel="noreferrer"
								onClick={createLinkMenuHandler(process.env.NEXT_PUBLIC_DOCS_URL)}
							>
								<BookIcon className="mr-2 h-5 w-5" /> API docs
							</a>
							<button className={menuItemClassName} onClick={handleContactSupportClick} type="button">
								<ContactSupportIcon className="mr-2 h-5 w-5" /> Contact support
							</button>
							<a
								className={menuItemClassName}
								href={process.env.NEXT_PUBLIC_DISCORD_URL}
								target="_blank"
								rel="noreferrer"
								onClick={createLinkMenuHandler(process.env.NEXT_PUBLIC_DISCORD_URL)}
							>
								<DiscordOutlineIcon className="mr-2 h-5 w-5" /> Ask the community
							</a>
						</div>
						<div className="pb-10">
							<div className={clsx('!bg-transparent', menuItemClassName)}>API key {tryOnDesktopTag}</div>
							<a
								className={menuItemClassName}
								href={`${process.env.NEXT_PUBLIC_COMPANY_SITE_URL}/pricing`}
								target="_blank"
								rel="noreferrer"
								onClick={handlePricingMenuClick}
							>
								Pricing <OutlinkIcon className="ml-2 h-5 w-5" />
							</a>
							<button className={menuItemClassName} onClick={handleLogout} type="button">
								Sign out
							</button>
						</div>
					</RemoveScroll>,
					document.body
				)}
		</>
	)
}

export default MobileNavMenu
