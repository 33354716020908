import mixpanel from 'mixpanel-browser'
import { useState } from 'react'
import { type DropzoneOptions, useDropzone, ErrorCode } from 'react-dropzone'

import { getBgColors } from './utils'

const MAX_VIDEO_SIZE = 1024 * 1024 * 1024 * 2 // 2GB
export const MAX_VIDEO_SIZE_TEXT = '2GB'

const acceptedVideoType = {
	'video/*': [
		'.mp4',
		'.webm',
		'.3gp',
		'.mpeg',
		'.avi',
		'.mov',
		'.flv',
		'.wmv',
		'.asf',
		/* List of video formats whose types are unrecognized while dragging, setting isDragRejected true */
		'.mkv',
		'.h265',
		'.h264',
		'.ts',
		'.vob'
		/* end of list */
	],
	'application/mxf': ['.mxf'],
	'audio/ogg': ['.ogg'],
	'audio/x-m4a': ['.m4a']
}

interface RejectedFile {
	fileName: string
	reason: string
}

const useVideoDropzoneState = ({ onDropAccepted, onDropRejected, ...options }: DropzoneOptions) => {
	const [isHovering, setIsHovering] = useState(false)
	const [rejectedFiles, setRejectedFiles] = useState<Array<RejectedFile>>([])

	const dropzoneState = useDropzone({
		accept: acceptedVideoType,
		maxSize: MAX_VIDEO_SIZE,
		onDragEnter: () => {
			setRejectedFiles([])
		},
		onDropAccepted: (files, event) => {
			onDropAccepted?.(files, event)
			setIsHovering(false)
			setRejectedFiles([])
			mixpanel.track('upload', {
				type: 'dropzone',
				element: 'video_upload',
				value: { status: 'accepted', video_count: files.length }
			})
		},
		onDropRejected: (fileRejections, event) => {
			console.error('onDropRejected', fileRejections, event)
			onDropRejected?.(fileRejections, event)
			setRejectedFiles((f) => [
				...f,
				...fileRejections.map(({ file, errors }) => ({
					fileName: file.name,
					reason: errors[0].code
				}))
			])
			mixpanel.track('upload', {
				type: 'dropzone',
				element: 'video_upload',
				value: { status: 'rejected', video_count: fileRejections.length }
			})
		},
		...options
	})

	const { isDragAccept, isDragActive } = dropzoneState
	const isOverMaxSize = rejectedFiles.some((f) => f.reason === ErrorCode.FileTooLarge)
	const isDragFailed = rejectedFiles.length > 0
	const bgColors = getBgColors(isDragAccept, isDragFailed, isHovering && !isDragActive, options.disabled)

	return {
		...dropzoneState,
		isHovering: isHovering && !isDragActive,
		setIsHovering,
		rejectedFiles,
		setRejectedFiles,
		isOverMaxSize,
		isDragFailed,
		bgColors
	}
}

export default useVideoDropzoneState
