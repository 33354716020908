import type { BoxProps } from '@mui/material/Box'

import InfoOutlined from '@mui/icons-material/InfoOutlined'
import Warning from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

export interface AlertProps extends BoxProps {
	title: string
	/**
	 * @default 'info'
	 */
	variant?: 'info' | 'warning'
	/**
	 * @default 'medium'
	 */
	size?: 'small' | 'medium' | 'large'
	icon?: JSX.Element | null
}

const AlertArea = styled((props: BoxProps & Pick<AlertProps, 'variant' | 'size'>) => <Box {...props} />, {
	shouldForwardProp: (prop) => prop !== 'size' && prop !== 'variant'
})(({ theme, size, variant }) => ({
	padding: `${theme.spacing(2)} ${theme.spacing(size === 'small' ? 4 : 8)}`,
	background: variant === 'warning' ? theme.palette.orange[100] : theme.palette.mossGreen[100],
	color: variant === 'warning' ? theme.palette.error.main : theme.palette.secondary.main
}))

const Alert = ({ children, icon, title, size = 'medium', variant = 'info', ...props }: AlertProps): JSX.Element => (
	<AlertArea size={size} variant={variant} {...props}>
		<Box display="flex" alignItems="center" gap={4}>
			{icon !== null &&
				(icon || (
					<>
						{variant === 'info' && <InfoOutlined fontSize="small" color="inherit" />}
						{variant === 'warning' && <Warning fontSize="small" color="inherit" />}
					</>
				))}
			<Typography
				variant={size === 'small' ? 'body3' : 'body2'}
				fontWeight={children != null ? 500 : 400}
				color="inherit"
				sx={{ userSelect: 'none' }}
			>
				{title}
			</Typography>
		</Box>
		{children}
	</AlertArea>
)

export default Alert
