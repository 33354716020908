import type { IndexResponse } from 'apis/indexes'
import type { FileId, VideoType } from 'apis/tasks'

import { Button } from '@twelvelabs/tds'
import clsx from 'clsx'
import UploadVideoItem from 'components/UploadVideoItem'
import React from 'react'
import { pluralize } from 'utils/helpers'
import useVideoDropzoneState from 'utils/hooks/useVideoDropzoneState'

import DropzoneCenterText from './DropzoneCenterText'
import DropzoneSubText from './DropzoneSubText'

interface Props {
	onDrop: (files: Array<File>) => void
	onRemove: (id: FileId) => void
	videos: Array<VideoType>
	index?: IndexResponse
	disabled?: boolean
	className?: string
}

const VideoDropzone = ({ className, onDrop, onRemove, videos, index, disabled }: Props): JSX.Element => {
	const {
		isDragAccept,
		isDragFailed,
		getRootProps,
		getInputProps,
		isDragActive,
		open: openFileDialog,
		setIsHovering,
		isOverMaxSize,
		bgColors: [bgColor, bgBorderColor]
	} = useVideoDropzoneState({ onDrop, noClick: videos.length > 0, disabled })

	return (
		<div
			className={clsx(className, 'overflow-auto', 'flex items-stretch', 'bg-white')}
			style={{
				// Native CSS properties don't support the customization of border-style.
				// Therefore, we use a trick with an SVG image inside background-image property.
				// Reference: https://kovart.github.io/dashed-border-generator/
				backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='${bgColor}' stroke='${bgBorderColor}' stroke-width='4' stroke-dasharray='4%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
			}}
			data-testid="video-dropzone"
			{...getRootProps()}
		>
			<input {...getInputProps()} />
			{videos.length > 0 && !isDragActive ? (
				<div className={clsx('w-full', 'px-[14px]')}>
					<div className={clsx('flex items-center justify-between', 'py-1')}>
						<p className="text-body2 text-grey-600">{pluralize(videos.length, 'video')}</p>
						<Button
							className={clsx('hover:!bg-transparent hover:underline', '!pr-1')}
							size="sm"
							appearance="secondary"
							onClick={openFileDialog}
						>
							Browse files
						</Button>
					</div>
					<div className={clsx('flex flex-wrap gap-1', 'pb-3')}>
						{videos.map((video) => (
							<UploadVideoItem key={video.id} video={video} onRemove={onRemove} />
						))}
					</div>
				</div>
			) : (
				<button
					className={clsx('w-full', 'flex flex-col items-center justify-center')}
					type="button"
					onMouseEnter={(): void => setIsHovering(true)}
					onMouseLeave={(): void => setIsHovering(false)}
					disabled={disabled}
				>
					<DropzoneCenterText
						isOverMaxSize={isOverMaxSize}
						isDragAccept={isDragAccept}
						isDragFailed={isDragFailed}
						disabled={disabled}
					/>
					<DropzoneSubText index={index} disabled={disabled} />
				</button>
			)}
		</div>
	)
}

export default VideoDropzone
