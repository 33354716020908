import type { NavbarMenuProps } from './NavbarMenu'

import clsx from 'clsx'
import { NAVBAR_HEIGHT } from 'constants/measurements'
import { pathName } from 'constants/paths'
import Link from 'next/link'
import React from 'react'

import LogoButton from './LogoButton'
import NavbarMenu from './NavbarMenu'

const BetaMark = (): JSX.Element => (
	<div className={clsx('mr-auto px-1 py-0.5', 'bg-green-50')}>
		<p className={clsx('text-subtitle3 !text-green-900')}>Beta</p>
	</div>
)

const Navbar = ({
	className,
	hideTabs,
	hideUsage,
	disableFetch,
	...props
}: NavbarMenuProps & React.HTMLAttributes<HTMLDivElement>): JSX.Element => (
	<nav
		className={clsx(className, 'sticky top-0 z-navbar bg-white', 'transition-all duration-300 ease-in-out')}
		{...props}
	>
		<div
			className={clsx(
				'flex items-center',
				'relative w-full bg-white px-5',
				'border-0 border-b border-solid border-grey-200'
			)}
			style={{ height: NAVBAR_HEIGHT, minHeight: NAVBAR_HEIGHT }}
		>
			<Link className={clsx('flex items-center justify-center', 'mr-4')} href={pathName.OVERVIEW}>
				<LogoButton />
			</Link>
			<BetaMark />
			<NavbarMenu hideTabs={hideTabs} hideUsage={hideUsage} disableFetch={disableFetch} />
		</div>
	</nav>
)

export default Navbar
