import muiSvg from './muiSvg'

const Classify = muiSvg(
	(props): JSX.Element => (
		<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7ZM13 8.87398C14.7252 8.42994 16 6.86384 16 5C16 2.79086 14.2091 1 12 1C9.79086 1 8 2.79086 8 5C8 6.86384 9.27477 8.42994 11 8.87398V10H6C4.89543 10 4 10.8954 4 12V14.2826C2.27477 14.7266 1 16.2927 1 18.1566C1 20.3657 2.79086 22.1566 5 22.1566C7.20914 22.1566 9 20.3657 9 18.1566C9 16.2927 7.72523 14.7266 6 14.2826V12H18V14.2826C16.2748 14.7266 15 16.2927 15 18.1566C15 20.3657 16.7909 22.1566 19 22.1566C21.2091 22.1566 23 20.3657 23 18.1566C23 16.2927 21.7252 14.7266 20 14.2826V12C20 10.8954 19.1046 10 18 10H13V8.87398ZM5 20.1566C6.10457 20.1566 7 19.2612 7 18.1566C7 17.052 6.10457 16.1566 5 16.1566C3.89543 16.1566 3 17.052 3 18.1566C3 19.2612 3.89543 20.1566 5 20.1566ZM21 18.1566C21 19.2612 20.1046 20.1566 19 20.1566C17.8954 20.1566 17 19.2612 17 18.1566C17 17.052 17.8954 16.1566 19 16.1566C20.1046 16.1566 21 17.052 21 18.1566Z"
				fill="currentColor"
			/>
		</svg>
	)
)

export default Classify
