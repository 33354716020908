import type { DialogProps } from 'components/Dialog'

import Dialog from 'components/Dialog'

const CancelDescriptionModal = ({
	nextStep,
	children,
	...props
}: Omit<DialogProps, 'action'> & { nextStep: (description: string) => void }): JSX.Element => (
	<Dialog
		{...props}
		action={{
			label: 'Proceed',
			onClick: (): void => {
				nextStep('description')
			}
		}}
	>
		{children}
	</Dialog>
)

export default CancelDescriptionModal
