import type { Usage } from 'apis/billing'
import type { VideoType } from 'apis/tasks'

import { useQuery } from '@tanstack/react-query'
import { isFreePlan, usageOptions } from 'apis/billing'
import { indexOptions } from 'apis/indexes'
import { getVideosTotalDuration } from 'utils/helpers'

// Checks whether the usage exceeds its limit.
const checkIfExceedUsageLimit = (usage: Usage | undefined, duration: number) => {
	if (!usage) return false

	return usage.total_infra_usage_used + duration > usage.infra_usage_limit
}

/**
 * Checks if the given videos will exceed the upload limit.
 */
const useUsageLimit = (indexId: string | undefined, videos: VideoType[]) => {
	const { data: usage } = useQuery(usageOptions())
	const { data: engineCount = 0 } = useQuery({
		...indexOptions(indexId ?? ''),
		enabled: !!indexId, // Do not run when indexId is not available.
		select: (res) => res.data.engines.length
	})

	const [totalDuration] = getVideosTotalDuration(videos)

	const exceedUsageLimit = checkIfExceedUsageLimit(usage, totalDuration * engineCount)
	const shouldDisableUpload =
		!indexId ||
		videos.length === 0 ||
		// Upload usage limit is enforced on free plan users
		(exceedUsageLimit && isFreePlan(usage))

	return {
		exceedUsageLimit,
		shouldDisableUpload
	}
}

export default useUsageLimit
