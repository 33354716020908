import type { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime'

import { usePathname, useRouter as useNextRouter, useSearchParams } from 'next/navigation'
import { useCallback } from 'react'

interface RouterData<T extends Record<string, string | string[]>> {
	pathname?: string | null
	params?: { [P in keyof T]?: T[P] | undefined }
}

interface RouterOptions extends NavigateOptions {
	/**
	 * Preserve the existing search params
	 * @default false
	 */
	preserve?: boolean
}

/**
 * This hook is a extension of the Next.js useRouter hook that allows for easier manipulation of search params.
 */
export default function useRouter() {
	const searchParams = useSearchParams()
	const path = usePathname() ?? '/'
	const router = useNextRouter()

	const createSearchParams = useCallback(
		<T extends Record<string, string | string[]>>(params?: RouterData<T>['params'], preserve?: boolean) => {
			const p = new URLSearchParams(preserve ? searchParams?.toString() : undefined)
			if (params != null) {
				Object.entries(params).forEach(([key, value]) => {
					if (value == null) {
						p.delete(key)
						return
					}

					if (Array.isArray(value)) {
						if (p.has(key)) p.delete(key)
						value.forEach((v) => p.append(key, v))
					} else {
						p.set(key, value)
					}
				})
			}

			const stringifiedSearchParams = p.toString()
			return stringifiedSearchParams ? `?${stringifiedSearchParams}` : ''
		},
		[searchParams]
	)

	return {
		...router,
		push<T extends Record<string, string | string[]>>(
			{ pathname = path, params }: RouterData<T>,
			{ preserve, ...navigateOptions }: RouterOptions = {}
		) {
			return router.push(`${pathname}${createSearchParams<T>(params, preserve)}`, navigateOptions)
		},
		replace<T extends Record<string, string | string[]>>(
			{ pathname = path, params }: RouterData<T>,
			{ preserve, ...navigateOptions }: RouterOptions = {}
		) {
			return router.replace(`${pathname}${createSearchParams<T>(params, preserve)}`, navigateOptions)
		}
	}
}
