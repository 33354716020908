import type { CreateTaskParams, CreateTaskResponse, UploadingTask } from './types'
import type { AxiosResponse } from 'axios'

import { isFileType, isUrlType } from './utils'
import { ApiType, getInstance } from 'utils/network'

// eslint-disable-next-line import/prefer-default-export
export async function createTask(
	task: UploadingTask,
	onUploadProgress?: (progress: { loaded: number; total: number }) => void
) {
	if (isFileType(task)) {
		return getInstance(ApiType.TWELVE_LABS).post<
			CreateTaskResponse,
			AxiosResponse<CreateTaskResponse>,
			CreateTaskParams
		>(
			'/tasks',
			{ index_id: task.indexId, video_file: task.file, language: 'en' },
			{
				headers: { 'Content-Type': 'multipart/form-data' },
				signal: task.signal,
				onUploadProgress
			}
		)
	}
	if (isUrlType(task)) {
		return getInstance(ApiType.TWELVE_LABS).post<
			Pick<CreateTaskResponse, '_id'>,
			AxiosResponse<Pick<CreateTaskResponse, '_id'>>,
			Pick<CreateTaskParams, 'index_id'> & { url: string }
		>('/tasks/external-provider', { index_id: task.indexId, url: task.url }, { signal: task.signal })
	}

	throw new Error('Invalid task type')
}
