import { EngineOption } from 'apis/indexes'
import clsx from 'clsx'
import OptionIcon from 'components/OptionIcon'
import React from 'react'
import { capitalize } from 'utils/formatString'

const engineOptionDescriptions: Record<EngineOption, React.ReactNode> = {
	[EngineOption.visual]:
		'Index what has seen and heard, including actions, objects, and sound, while excluding human speech.',
	[EngineOption.conversation]: 'Index the speech within your videos to enable semantic conversation understanding.',
	[EngineOption.text_in_video]: 'Extract the text (OCR) within your videos.',
	[EngineOption.logo]: 'Identify brand logos within your videos.'
}
const SelectEngineTooltip = () => (
	<div className="text-body2 text-grey-800 tablet:text-inherit">
		<span className="text-subtitle3-bold">Engines</span> are our video foundation models that power embedding-based or
		generative tasks like search, classification, and text generation.
		<div className="mt-5">
			<span className="text-subtitle3-bold">Engine options</span> determine which types of information within your
			videos will be processed by the engine you selected.
		</div>
		{(Object.keys(engineOptionDescriptions) as Array<EngineOption>).map((option) => (
			<div key={option} className={clsx('block tablet:hidden', 'mt-3', 'flex gap-x-[6px]')}>
				<OptionIcon className="mt-[2px]" fontSize={20} option={option} />
				<div className="flex-1">
					<span className="text-subtitle3-bold">{capitalize(option)}</span>
					<div className="text-body2">{engineOptionDescriptions[option]}</div>
				</div>
			</div>
		))}
	</div>
)

export default SelectEngineTooltip
