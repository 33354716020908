'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

/**
 * Copied the source code from react-query repo (https://github.com/TanStack/query/tree/main/packages/react-query-next-experimental)
 * Modify a bit to be able to pass CSP nonce as a prop that is added to inline script tag,
 * so that the script gets executed and the query result gets hydrated on the client side properly
 */
import { ReactQueryStreamedHydration } from './react-query-next-experimental'

// Experimental streaming without prefetching in Next.js
// https://tanstack.com/query/v5/docs/framework/react/guides/advanced-ssr#experimental-streaming-without-prefetching-in-nextjs

function makeQueryClient() {
	return new QueryClient({
		defaultOptions: {
			queries: {
				// With SSR, we usually want to set some default staleTime
				// above 0 to avoid refetching immediately on the client
				staleTime: 60 * 1000 /* 1 min */,
				retry: false,
				refetchOnMount: true,
				refetchOnWindowFocus: false,
				refetchIntervalInBackground: false
			}
		}
	})
}

let browserQueryClient: QueryClient | undefined

/**
 * TODO: Not expose this function to the outside world. It's way much safer to use hook to get query client instead.
 * https://tkdodo.eu/blog/react-query-fa-qs#why-should-i-usequeryclient
 */
export function getQueryClient() {
	if (typeof window === 'undefined') {
		// Server: always make a new query client
		return makeQueryClient()
	}

	// Browser: make a new query client if we don't already have one
	// This is very important so we don't re-make a new client if React
	// suspends during the initial render. This may not be needed if we
	// have a suspense boundary BELOW the creation of the query client
	if (!browserQueryClient) browserQueryClient = makeQueryClient()
	return browserQueryClient
}

interface Props {
	children: React.ReactNode
	nonce?: string
}

const QueryProvider = ({ children, nonce }: Props): JSX.Element => {
	// NOTE: Avoid useState when initializing the query client if you don't
	//       have a suspense boundary between this and the code that may
	//       suspend because React will throw away the client on the initial
	//       render if it suspends and there is no boundary
	const client = getQueryClient()

	return (
		<QueryClientProvider client={client}>
			<ReactQueryStreamedHydration nonce={nonce}>{children}</ReactQueryStreamedHydration>
			<ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" position="bottom" styleNonce={nonce} />
		</QueryClientProvider>
	)
}

export default QueryProvider
