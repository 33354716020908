import CircularProgress from '@mui/material/CircularProgress'
import { useSuspenseQueries } from '@tanstack/react-query'
import { Button } from '@twelvelabs/tds'
import { indexOptions } from 'apis/indexes'
import { UploadStatus, useUploadTasks, useCompletedTasks, useClearUploadTasks, taskStatusOptions } from 'apis/tasks'
import clsx from 'clsx'
import { pathName } from 'constants/paths'
import Link from 'next/link'
import ChevronDownIcon from 'public/icons/chevron-down.svg'
import ErrorRoundIcon from 'public/icons/error-round.svg'
import UploadIcon from 'public/icons/upload.svg'
import { useState } from 'react'
import withBoundary from 'utils/hocs/withBoundary'

import TaskStatusAccordionContent from './TaskStatusAccordionContent'
import { useCloseNavbarMenu } from '../navbarStore'

export interface TaskStatusAccordionProps {
	indexId: string
	open?: boolean
}

const TaskStatusCount = ({
	label,
	count,
	icon,
	className
}: {
	label: string
	count: number
	icon: JSX.Element
	className?: string
}) =>
	count > 0 && (
		<div className={clsx('flex items-center gap-x-1', 'py-1 pl-2 pr-4', className)}>
			{icon}
			<p className={clsx('whitespace-nowrap text-body2', label === 'failed' ? '!text-red-700' : '!text-grey-900')}>
				{count} {label}
			</p>
		</div>
	)

const TaskStatusAccordion = ({ indexId, open: openProp = false }: TaskStatusAccordionProps) => {
	const [{ data: index }, { data: taskStatus }] = useSuspenseQueries({
		queries: [indexOptions(indexId), taskStatusOptions(indexId)]
	})
	const indexingTaskCount = taskStatus.validating + taskStatus.queued + taskStatus.pending + taskStatus.indexing

	const [open, setOpen] = useState<boolean>(openProp)

	const { uploading: uploadingTaskCount, failed: uploadingFailedTaskCount } = useUploadTasks((state) => ({
		[UploadStatus.Uploading]: state.filter(
			(task) => task.indexId === indexId && [UploadStatus.Queued, UploadStatus.Uploading].includes(task.status)
		).length,
		[UploadStatus.Failed]: state.filter((task) => task.indexId === indexId && task.status === UploadStatus.Failed)
			.length
	}))

	const { ready: readyTaskCount, failed: indexingFailedTaskCount } = useCompletedTasks(
		(state) =>
			state[indexId]?.reduce(
				(acc, task) => {
					switch (task.status) {
						case 'ready':
							acc.ready++
							break
						case 'failed':
							acc.failed++
							break
						default:
							break
					}
					return acc
				},
				{ ready: 0, failed: 0 }
			) ?? { ready: 0, failed: 0 }
	)

	const failedTaskCount = indexingFailedTaskCount + uploadingFailedTaskCount

	const hasTasks = indexingTaskCount + uploadingTaskCount + readyTaskCount + failedTaskCount > 0

	const clearUploadTasks = useClearUploadTasks()

	const onToggle = () => setOpen((prev) => !prev)

	const closeMenu = useCloseNavbarMenu()

	return (
		<>
			<div className={clsx('w-full', 'border-b border-grey-200 bg-grey-50')}>
				<button
					type="button"
					disabled={!hasTasks}
					onClick={onToggle}
					className={clsx('w-full', 'pb-6 pl-6 pr-7 pt-4', 'flex items-center')}
				>
					<ChevronDownIcon className={clsx('h-6 w-6', 'mr-2', 'text-grey-600', open && 'rotate-180')} />
					<p className={clsx('truncate text-subtitle2-bold !text-grey-1000')}>{index.index_name}</p>
					<p className={clsx('text-subtitle3', 'whitespace-nowrap', 'ml-auto pl-2')}>
						<span className={clsx('text-subtitle3-bold')}>{readyTaskCount}</span> Videos ready
					</p>
				</button>
				<div className={clsx('w-full px-6 pb-5', 'flex items-center gap-x-2.5')}>
					<Link href={pathName.index(indexId)} className={clsx('flex-1')}>
						<Button rounded size="sm" type="button" appearance="primary" onClick={closeMenu} className={clsx('w-full')}>
							Go to index
						</Button>
					</Link>
					{uploadingTaskCount > 0 && (
						<Button
							rounded
							size="sm"
							type="button"
							appearance="default"
							onClick={() => clearUploadTasks({ indexId })}
							className={clsx('flex-1')}
						>
							Cancel uploads
						</Button>
					)}
				</div>
				{hasTasks && (
					<div className={clsx('px-6 py-2', 'grid grid-flow-col grid-cols-3 gap-x-2')}>
						<TaskStatusCount
							label="uploading"
							count={uploadingTaskCount}
							icon={<UploadIcon className={clsx('h-5 w-5 shrink-0 text-grey-600')} />}
						/>
						<TaskStatusCount
							label="indexing"
							count={indexingTaskCount}
							icon={<CircularProgress size={16} className={clsx('shrink-0')} />}
						/>
						<TaskStatusCount
							label="failed"
							count={failedTaskCount}
							icon={<ErrorRoundIcon className={clsx('h-5 w-5 shrink-0')} />}
							className={clsx('!text-red-500')}
						/>
					</div>
				)}
			</div>
			{open && <TaskStatusAccordionContent indexId={indexId} />}
		</>
	)
}

export default withBoundary(TaskStatusAccordion)
