import clsx from 'clsx'
import { NAVBAR_HEIGHT } from 'constants/measurements'
import React from 'react'

import MobileNavMenu from './components/MobileNavMenu'
import UserAccountMenu from './components/UserAccountMenu'
import UserUsageWithBorder from './components/UserUsageWithBorder'
import LogoButtonWithBeta from '../PageLayoutSide/components/LogoButtonWithBeta'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
	className?: string
}

const PageLayoutTop = ({ className, ...props }: Props) => (
	<div
		className={clsx(
			'sticky top-0 z-navbar bg-white',
			'transition-all duration-300',
			'border-b border-solid border-grey-200',
			'pl-5 pr-[14px] tablet:px-10',
			className
		)}
		style={{ height: NAVBAR_HEIGHT, minHeight: NAVBAR_HEIGHT }}
		{...props}
	>
		{/* Desktop view */}
		<div className={clsx('h-full', 'hidden items-center justify-end tablet:flex')}>
			<UserUsageWithBorder />
			<UserAccountMenu />
		</div>
		{/* Mobile view */}
		<div className={clsx('h-full', 'flex items-center justify-between tablet:hidden')}>
			<LogoButtonWithBeta expanded />
			<MobileNavMenu />
		</div>
	</div>
)

export default PageLayoutTop
