import type { TaskParams, TaskResponse } from './types'
import type { InfiniteData } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'
import type {
	IndexStatus,
	PageResponseFormat,
	TaskErrorCode,
	TwelveLabsApiError,
	UseCommonInfiniteQueryOptions,
	UseCommonQueryOptions
} from 'utils/response'

import { HIGH_RATE_REFETCH_INTERVAL, LOW_RATE_REFETCH_INTERVAL } from 'constants/task'
import omit from 'lodash/omit'
import { getInstanceByIndexId } from 'utils/network'

import { getTaskKey, getTasksKey, getTasksStatusKey } from './keys'

export function tasksOptions(
	params?: TaskParams
): UseCommonQueryOptions<
	AxiosResponse<PageResponseFormat<TaskResponse>>,
	TwelveLabsApiError<TaskErrorCode>,
	PageResponseFormat<TaskResponse>
> {
	const aggregatedParams: TaskParams = { deleted: false, ...params }
	return {
		queryKey: params?.index_id
			? getTasksKey(params.index_id, omit(aggregatedParams, 'index_id'))
			: getTasksKey(aggregatedParams),
		queryFn: async () => {
			const instance = await getInstanceByIndexId(params?.index_id)
			return instance.get<PageResponseFormat<TaskResponse>>('/tasks', { params: aggregatedParams })
		},
		select: (res) => res.data
	}
}

type InfiniteTasksPageParam = number | undefined
export function infiniteTasksOptions(params?: Omit<TaskParams, 'page'>): UseCommonInfiniteQueryOptions<
	AxiosResponse<PageResponseFormat<TaskResponse>>,
	TwelveLabsApiError<TaskErrorCode>,
	InfiniteData<PageResponseFormat<TaskResponse>, InfiniteTasksPageParam>,
	AxiosResponse<PageResponseFormat<TaskResponse>>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	any,
	InfiniteTasksPageParam
> {
	const aggregatedParams: TaskParams = { deleted: false, ...params }
	return {
		queryKey: params?.index_id
			? getTasksKey(params.index_id, omit(aggregatedParams, 'index_id'))
			: getTasksKey(aggregatedParams),
		queryFn: async ({ pageParam: page }) => {
			const instance = await getInstanceByIndexId(params?.index_id)
			return instance.get<PageResponseFormat<TaskResponse>>('/tasks', { params: { ...aggregatedParams, page } })
		},
		initialPageParam: 1,
		getPreviousPageParam: ({ data: { page_info } }) => (page_info.page > 1 ? page_info.page - 1 : undefined),
		getNextPageParam: ({ data: { page_info } }) =>
			page_info.page < page_info.total_page ? page_info.page + 1 : undefined
	}
}

export interface TaskStatusResponse extends Record<IndexStatus, number> {
	index_id: string
	total_result: number
}

export function taskStatusOptions<Response extends TaskStatusResponse>(
	indexId: string
): UseCommonQueryOptions<
	AxiosResponse<Response>,
	TwelveLabsApiError<TaskErrorCode>,
	Response,
	ReturnType<typeof getTasksStatusKey>
> {
	return {
		queryKey: getTasksStatusKey(indexId),
		queryFn: async (): Promise<AxiosResponse<Response>> => {
			const instance = await getInstanceByIndexId(indexId)
			const res = await instance.get<Response>('/tasks/status', { params: { index_id: indexId } })
			return {
				...res,
				data: res.data ?? {
					index_id: indexId,
					total_result: 0,
					validating: 0,
					queued: 0,
					pending: 0,
					indexing: 0,
					ready: 0,
					failed: 0
				}
			}
		},
		select: (res) => res.data
	}
}

export interface TaskHLS {
	status: 'COMPLETE' | 'ERROR' | 'CANCELED'
	video_url: string
	thumbnail_urls: string[]
	vtt_url?: string
	updated_at: Date
}

export interface SingleTaskResponse extends TaskResponse {
	hls?: TaskHLS
}

export function taskOptions<Response extends SingleTaskResponse>(
	taskId: string,
	indexId?: string
): UseCommonQueryOptions<
	AxiosResponse<Response>,
	TwelveLabsApiError<TaskErrorCode>,
	Response,
	ReturnType<typeof getTaskKey>
> {
	return {
		queryKey: getTaskKey(taskId),
		queryFn: async () => {
			const instance = await getInstanceByIndexId(indexId)
			return instance.get<Response>(`/tasks/${taskId}`)
		},
		refetchInterval({ state: { data } }) {
			if (data?.data.hls?.thumbnail_urls == null) return HIGH_RATE_REFETCH_INTERVAL
			if (['validating', 'queued', 'pending'].includes(data?.data.status)) return LOW_RATE_REFETCH_INTERVAL
			return undefined
		},
		select: (res) => res.data
	}
}
