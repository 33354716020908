import { datadogLogs } from '@datadog/browser-logs'
import { useEffect } from 'react'
import useAuth0 from 'utils/hooks/useAuth0'
import useMount from 'utils/hooks/useMount'

const DatadogWizard = (): null => {
	const { user } = useAuth0()

	useMount(() =>
		datadogLogs.init({
			clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
			site: 'datadoghq.com',
			service: 'tl-platform',
			version: '1.0.0',
			forwardErrorsToLogs: false,
			sessionSampleRate: 100,
			// Only send log in production environment
			beforeSend: () => process.env.NEXT_PUBLIC_ENVIRONMENT === 'io'
		})
	)

	useEffect(() => {
		if (user) {
			datadogLogs.setGlobalContext({ user })
		}
	}, [user])

	return null
}

export default DatadogWizard
