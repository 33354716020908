import type { IndexResponse } from 'apis/indexes'

import { DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Dialog, { dialogClasses, type DialogProps } from '@mui/material/Dialog'
import { Button } from '@twelvelabs/tds'
import { useAddUploadQueue } from 'apis/tasks'
import clsx from 'clsx'
import VideoDurationMessage from 'components/VideoDurationMessage'
import React, { useState } from 'react'
import useUsageLimit from 'utils/hooks/useUsageLimit'
import useVideosToUpload from 'utils/hooks/useVideosToUpload'
import { useOpenNavbarMenu, NavbarMenuType } from 'widgets/Navbar'

import SelectMyIndex from './common/SelectMyIndex'
import VideoDropzone from './common/VideoDropzone'

const UploadVideosDialog = (props: DialogProps) => {
	const [index, setIndex] = useState<IndexResponse | undefined>()

	const { videos, cleanUpVideos, onVideoFilesAdded, onVideoRemoved } = useVideosToUpload()
	const { exceedUsageLimit, shouldDisableUpload } = useUsageLimit(index?._id, videos)

	const addUploadQueue = useAddUploadQueue()
	const openNavbarMenu = useOpenNavbarMenu()

	const onClose = (e: React.MouseEvent) => {
		// eslint-disable-next-line react/destructuring-assignment
		props.onClose?.(e, 'backdropClick')
		cleanUpVideos()
	}

	const onUpload = async () => {
		if (!index) return
		addUploadQueue(index._id, videos)
		cleanUpVideos()
		openNavbarMenu(NavbarMenuType.TaskStatus)
	}

	const isDropzoneDisabled = !index

	return (
		<Dialog
			{...props}
			className="tablet:transparent-scroll"
			sx={{ [`& .${dialogClasses.paper}`]: { maxWidth: 690 } }}
			onClose={onClose}
		>
			<DialogTitle>Upload videos</DialogTitle>
			<DialogContent className="!pb-4">
				<div className={clsx('flex flex-col gap-2 tablet:flex-row tablet:items-center', 'pb-5')}>
					<p className="text-subtitle2 text-grey-1000">Select index</p>
					<SelectMyIndex index={index} onIndexChange={setIndex} />
				</div>
				<VideoDropzone
					className={clsx('min-h-[322px]', 'max-h-[319px] tablet:max-h-[428px]')}
					index={index}
					videos={videos}
					disabled={isDropzoneDisabled}
					onDrop={onVideoFilesAdded}
					onRemove={onVideoRemoved}
				/>
				<VideoDurationMessage
					className={clsx(
						'text-right',
						'max-w-[270px] tablet:max-w-none',
						'flex justify-end tablet:hidden',
						'ml-auto mt-3'
					)}
					videos={videos}
					exceedUsageLimit={exceedUsageLimit}
				/>
			</DialogContent>
			<DialogActions className="!pt-0">
				<Button appearance="subtle" className="mr-auto" onClick={onClose} type="button">
					Cancel
				</Button>
				<VideoDurationMessage
					className={clsx('max-w-[270px] tablet:max-w-none', 'hidden tablet:flex')}
					videos={videos}
					exceedUsageLimit={exceedUsageLimit}
				/>
				<Button
					className="w-[80px]"
					appearance="primary"
					type="button"
					onClick={onUpload}
					disabled={shouldDisableUpload}
				>
					Upload
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default UploadVideosDialog
