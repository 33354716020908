import type { IndexCreateSchema } from './utils'

import { EngineFamily, EngineOption } from 'apis/indexes'
import clsx from 'clsx'
import EngineLogo from 'components/EngineLogo'
import BlankRounded from 'components/svg/BlankRounded'
import CheckedRounded from 'components/svg/CheckedRounded'
import { Controller, useFormContext } from 'react-hook-form'

import EngineOptionCheckbox from './EngineOptionCheckbox'

const textsByEngine = {
	[EngineFamily.MARENGO]: {
		description: 'Powers Search and Classify',
		durationDetail: 'Supported video duration: 4sec-2hr'
	},
	[EngineFamily.PEGASUS]: {
		description: 'Powers Video-to-Text Generation',
		durationDetail: 'Supported video duration: 4sec-30min'
	}
}

const isDefaultEngineOption = (value: EngineOption) =>
	value === EngineOption.visual || value === EngineOption.conversation

interface Props {
	engineFamily: EngineFamily
	engineName: string
	options: Array<{ value: EngineOption; disabled?: boolean }>
}

const EngineItem = ({ engineFamily, engineName, options }: Props): JSX.Element => {
	const { control } = useFormContext<IndexCreateSchema>()
	const isThereExtraEngineOptions = options.some(({ value }) => !isDefaultEngineOption(value))

	return (
		<div>
			<Controller
				name={`${engineFamily}.selected`}
				control={control}
				render={({ field }): JSX.Element => (
					<input
						className={clsx('peer/selected', 'hidden')}
						id={engineFamily}
						value={engineFamily}
						type="checkbox"
						checked={field.value}
						onChange={() => {
							field.onChange(!field.value)
						}}
					/>
				)}
			/>
			<label
				htmlFor={engineFamily}
				className={clsx(
					'p-4 tablet:p-8',
					'flex flex-col gap-y-3',
					'tablet:grid tablet:grid-cols-2',
					'border border-solid',
					'hover:cursor-pointer',
					'border-grey-300 bg-grey-50 peer-checked/selected:border-green-300 peer-checked/selected:bg-green-50',
					'group'
				)}
			>
				<div className="flex gap-2">
					<div className="mt-1">
						<CheckedRounded
							className={clsx('text-secondary', 'h-5 w-5', 'hidden peer-checked/selected:group-[]:block')}
						/>
						<BlankRounded className={clsx('text-grey-500', 'h-5 w-5', 'block peer-checked/selected:group-[]:hidden')} />
					</div>
					<div>
						<div className="flex items-center">
							<EngineLogo
								engine_name={engineFamily}
								className={clsx('h-7 w-7', `text-grey-500 peer-checked/selected:group-[]:text-primary`)}
							/>
							<p className={clsx('my-0 ml-1', 'text-subtitle2 text-grey-900', 'capitalize')}>{engineName}</p>
						</div>
						<p className={clsx('mb-2 mt-1', 'text-body2 text-grey-1000')}>{textsByEngine[engineFamily].description}</p>
						<p className={clsx('my-0 text-body3 text-grey-700', 'hidden peer-checked/selected:group-[]:block')}>
							{textsByEngine[engineFamily].durationDetail}
						</p>
					</div>
				</div>
				<Controller
					name={`${engineFamily}.engine_options`}
					control={control}
					render={({ field, formState }): JSX.Element => (
						<div className={clsx('flex-col', 'hidden peer-checked/selected:group-[]:flex', 'ml-8 tablet:ml-0', 'mt-1')}>
							<p className={clsx('mb-[7px] mt-0', 'text-body3 font-medium text-grey-1000')}>
								Engine options
								{engineFamily === EngineFamily.MARENGO && (
									<span className={clsx('text-body3 italic text-grey-1000', 'ml-2')}>Recommended</span>
								)}
							</p>
							<div className={clsx('grid grid-cols-[80px_1fr] gap-x-5 gap-y-2')}>
								{options
									.filter(({ value }) => isDefaultEngineOption(value))
									.map(({ value: option, disabled }) => (
										<EngineOptionCheckbox
											key={option}
											checked={field.value[option]}
											disabled={disabled}
											value={option}
											onChange={(checked: boolean) => {
												const newValue = { ...field.value, [option]: checked }
												field.onChange(newValue)
											}}
										/>
									))}
							</div>
							{isThereExtraEngineOptions && (
								<>
									<p className={clsx('mb-[7px] mt-5', 'text-body3 font-medium text-grey-1000')}>More options</p>
									<div className={clsx('grid grid-cols-[80px_1fr] gap-x-5 gap-y-2')}>
										{options
											.filter(({ value }) => !isDefaultEngineOption(value))
											.map(({ value: option, disabled }) => (
												<EngineOptionCheckbox
													key={option}
													checked={field.value[option]}
													disabled={disabled}
													value={option}
													onChange={(checked: boolean) => {
														const newValue = { ...field.value, [option]: checked }
														field.onChange(newValue)
													}}
												/>
											))}
									</div>
								</>
							)}
							<p className={clsx('mb-0 mt-1 text-body3 font-normal text-red-500')}>
								{formState.errors[`${engineFamily}`]?.engine_options?.message}
							</p>
						</div>
					)}
				/>
			</label>
		</div>
	)
}

export default EngineItem
