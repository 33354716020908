'use client'

import type { NavbarButtonType } from './buttons'

import React, { memo } from 'react'
import useIsMobile from 'utils/hooks/useIsMobile'

import NavbarButton from './NavbarButton'
import buttons from './buttons'

export interface NavbarButtonsProps {
	closeMobileMenu?: () => void
}

const NavbarButtons = ({ closeMobileMenu }: NavbarButtonsProps): JSX.Element => {
	const isMobile = useIsMobile()

	const items: NavbarButtonType[] = isMobile
		? buttons.flatMap((button) => (button.menus ? button.menus : button))
		: buttons

	return (
		<>
			{items.map(
				(itemProps, index): JSX.Element => (
					<NavbarButton key={itemProps.label ?? index.toString()} closeMobileMenu={closeMobileMenu} {...itemProps} />
				)
			)}
		</>
	)
}

export default memo(NavbarButtons)
