import type { IndexStatus } from 'utils/response'

export enum EventType {
	INDEXING_STATUS = 'indexing_status'
}

interface EventResponseFormat<EV extends EventType, Data> {
	type: EV
	data: Data
}

export type EventResponse = EventResponseFormat<
	EventType.INDEXING_STATUS,
	{ index_id: string; video_id: string; task_id: string; status: Extract<IndexStatus, 'ready' | 'failed'> }
>
