'use client'

import { useEffect, useRef } from 'react'

const useMount = (callback: () => void | Promise<void>) => {
	const mounted = useRef(false)
	useEffect(() => {
		if (mounted.current) return
		mounted.current = true
		callback()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
}

export default useMount
