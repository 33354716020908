'use client'

import type { UserMetadata } from 'utils/auth'

import { useUser, type UserContext } from '@auth0/nextjs-auth0/client'
import { getUserMetadata } from 'app/src/utils'
import { Cache } from 'axios-extensions'
import Routes from 'constants/routes'
import useRouter from 'utils/hooks/useRouter'
import { ApiType, getInstance } from 'utils/network'

type UseAuth0ReturnType = UserContext & {
	isAuthenticated: boolean
	logout: (options?: {
		/**
		 * The full URL where Auth0 will redirect your browser to after the logout.
		 * **Note**: If the `client_id` parameter is included, the
		 * `returnTo` URL that is provided must be listed in the
		 * Application's "Allowed Logout URLs" in the Auth0 dashboard.
		 */
		returnTo: string
	}) => void
	loginWithRedirect: (options?: {
		/**
		 * The relative URL to redirect after login
		 */
		returnTo: string
	}) => void
	getAccessTokenSilently: (options?: {
		/**
		 * When `off`, ignores the cache and always sends a request to Auth0.
		 * When `cache-only`, only reads from the cache and never sends a request to Auth0.
		 * Defaults to `on`, where it both reads from the cache and sends a request to Auth0 as needed.
		 */
		cacheMode?: 'on' | 'off' | 'cache-only'
	}) => Promise<string>
	userMetadata: UserMetadata | undefined
}

const cache = new Cache({ ttl: 1000 * 60 * 30 /* 30 mins */, max: 10 })

const useAuth0 = (): UseAuth0ReturnType => {
	const userContext = useUser()
	const router = useRouter()

	const { user } = userContext

	const logout: UseAuth0ReturnType['logout'] = (options) =>
		router.push<NonNullable<Parameters<UseAuth0ReturnType['logout']>[0]>>({ pathname: Routes.LOGOUT, params: options })

	const loginWithRedirect: UseAuth0ReturnType['loginWithRedirect'] = (options) =>
		router.push<NonNullable<Parameters<UseAuth0ReturnType['loginWithRedirect']>[0]>>({
			pathname: Routes.LOGIN,
			params: options
		})

	const getAccessTokenSilently: UseAuth0ReturnType['getAccessTokenSilently'] = async (options) => {
		const cacheMode = options?.cacheMode || 'on'
		const fetchAccessToken = () =>
			getInstance(ApiType.ROUTE)
				.get<{ accessToken: string }>('/access-token', {
					headers: { 'Cache-Control': 'no-cache' }
				})
				.then((res) => {
					cache.set('token', res.data.accessToken)

					return res.data.accessToken
				})

		if (cacheMode === 'on') {
			if (cache.has('token')) {
				fetchAccessToken()
				return cache.get('token') as string
			}

			return fetchAccessToken()
		}

		if (cacheMode === 'cache-only') {
			if (cache.has('token')) {
				return cache.get('token') as string
			}

			return fetchAccessToken()
		}

		// when cacheMode is 'off
		return fetchAccessToken()
	}

	const userMetadata = user ? getUserMetadata(user) : undefined

	return {
		...userContext,
		isAuthenticated: Boolean(user),
		logout,
		loginWithRedirect,
		getAccessTokenSilently,
		userMetadata
	}
}

export default useAuth0
