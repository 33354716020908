import { UploadStatus, useUploadTasks } from 'apis/tasks'
import clsx from 'clsx'
import { NAVBAR_HEIGHT } from 'constants/measurements'
import Link from 'next/link'
import { useState } from 'react'
import { RemoveScroll } from 'react-remove-scroll'
import { useCloseNavbarMenu } from 'widgets/Navbar/navbarStore'

import CancelAllUploadDialog from './CancelAllUploadDialog'
import UploadProgressItem from './UploadProgressItem'

const UploadProgressSnackbarMobile = () => {
	const uploadTasks = useUploadTasks()

	const closeNavbarMenu = useCloseNavbarMenu()

	const [isCancelAllDialogOpen, setOpenCancelAllDialog] = useState(false)
	const openCancelAllDialog = () => setOpenCancelAllDialog(true)
	const closeCancelAllDialog = () => setOpenCancelAllDialog(false)

	const uploadTasksInProgress = uploadTasks.filter((task) =>
		[UploadStatus.Queued, UploadStatus.Uploading].includes(task.status)
	)
	const hasUploadTasksInProgress = uploadTasksInProgress.length > 0

	return (
		<RemoveScroll>
			<div
				className={clsx('fixed bottom-0 left-0 right-0 z-mobile-nav-menu', 'flex flex-col', 'bg-white', 'p-6')}
				style={{ top: NAVBAR_HEIGHT }}
			>
				<div className="flex items-center justify-between">
					<p className="text-subtitle1-bold !text-grey-1000">
						{hasUploadTasksInProgress
							? `Uploading ${uploadTasksInProgress.length} video${uploadTasksInProgress.length > 1 ? 's' : ''}`
							: 'Upload complete'}
					</p>

					{hasUploadTasksInProgress && (
						<button
							className={clsx('text-subtitle3-bold text-moss_green-800', 'border-none bg-transparent')}
							type="button"
							onClick={openCancelAllDialog}
						>
							Cancel all
						</button>
					)}
				</div>
				<div className="text-body2 text-grey-700">Do not close the browser tab while uploading</div>

				<Link href="/examples">
					<button
						className={clsx(
							'px-3 py-[10px]',
							'mt-5',
							'w-full',
							'bg-green-50',
							'border border-solid border-green-500',
							'text-left text-body2 text-grey-1000'
						)}
						type="button"
						onClick={closeNavbarMenu}
					>
						Check out the example searches
					</button>
				</Link>

				<div className={clsx('flex flex-col gap-2', 'mt-6', 'flex-1 overflow-auto')}>
					{uploadTasks.map((task) => (
						<UploadProgressItem key={task.id} task={task} />
					))}
				</div>
			</div>
			<CancelAllUploadDialog open={isCancelAllDialogOpen} onClose={closeCancelAllDialog} />
		</RemoveScroll>
	)
}

export default UploadProgressSnackbarMobile
