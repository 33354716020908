'use client'

import type { CardValidation } from '../../utils/schema'
import type { DialogProps } from 'components/Dialog'

import { useUser } from '@auth0/nextjs-auth0/client'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getPaymentMethodsKey, stripeClientSecretOptions } from 'apis/billing'
import { getIsCardSavedKey } from 'apis/user'
import clsx from 'clsx'
import Dialog from 'components/Dialog'
import Routes from 'constants/routes'
import { useFormik } from 'formik'
import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { getStripe } from 'utils/stripe'

import RegisterCard from './RegisterCard'
import { getCountryCode } from '../../utils/countries'
import { cardValidationSchema } from '../../utils/schema'

interface RegisterCardModalProps extends Omit<DialogProps, 'action'> {
	title?: string
	actionText: string
	header?: React.ReactNode
}

const RegisterCardModal = ({
	open,
	title = 'Register payment method',
	clientSecret,
	actionText,
	header,
	onClose,
	...props
}: RegisterCardModalProps & { clientSecret: string }): JSX.Element => {
	const queryClient = useQueryClient()
	const { user, checkSession } = useUser()
	const stripe = useStripe()
	const elements = useElements()

	const onSubmit = useCallback(
		async (values: CardValidation): Promise<void> => {
			if (!stripe || !elements || !clientSecret) return

			try {
				const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
					return_url: `${window.location.origin}${Routes.BILLING}`,
					payment_method: {
						card: elements.getElement('card') ?? elements.create('card'),
						billing_details: {
							name: values.cardHolderName,
							address: {
								country: getCountryCode(values.address.country)
							}
						}
					}
				})

				if (error != null) {
					if (error.param === 'payment_method_data[billing_details][address][country]') {
						toast('Please select a country', { type: 'warning' })
					} else {
						toast(error.message, { type: 'error' })
					}
				} else if (setupIntent?.status === 'succeeded') {
					toast('Payment saved')
					if (onClose) {
						onClose({}, 'backdropClick')
					}

					await Promise.all([
						queryClient.refetchQueries({ queryKey: getPaymentMethodsKey() }),
						queryClient.refetchQueries({ queryKey: getIsCardSavedKey() })
					])
				}
			} catch (e) {
				toast('Something went wrong :(', { type: 'error' })
			} finally {
				checkSession()
			}
		},
		[checkSession, clientSecret, elements, onClose, queryClient, stripe]
	)

	const formik = useFormik<CardValidation>({
		initialValues: {
			cardHolderName: user?.name ?? '',
			address: {
				country: ''
			}
		},
		validationSchema: cardValidationSchema,
		validateOnChange: true,
		validateOnMount: true,
		onSubmit
	})

	return (
		<Dialog
			open={open}
			fullWidth
			title={title}
			onClose={onClose}
			{...props}
			onSubmit={formik.handleSubmit}
			action={{
				label: actionText,
				disabled: !formik.isValid,
				loading: formik.isSubmitting
			}}
		>
			<div className={clsx('flex flex-col gap-y-4')}>
				{header}
				<RegisterCard formik={formik} />
				<p className={clsx('text-body2')}>
					By clicking the button below, I agree to authorize Twelve Labs to charge the amount according to the terms of
					Developer Plan
				</p>
			</div>
		</Dialog>
	)
}

const RegisterCardModalFallback = ({ open, ...props }: RegisterCardModalProps): JSX.Element | null => {
	const { data: clientSecret } = useQuery(stripeClientSecretOptions())

	if (!open || !clientSecret) return null

	return (
		<Elements stripe={getStripe()} options={{ clientSecret }}>
			<RegisterCardModal open={open} clientSecret={clientSecret} {...props} />
		</Elements>
	)
}

export default RegisterCardModalFallback
