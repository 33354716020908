import clsx from 'clsx'
import { capitalizeFirstLetter } from 'utils/helpers'

const CapitalizedLabel = ({ className, children, ...props }: React.HTMLAttributes<HTMLParagraphElement>) => (
	<p className={clsx('text-body2 !text-grey-600', 'mr-2', className)} {...props}>
		{typeof children === 'string' ? capitalizeFirstLetter(children) : children}
	</p>
)

export default CapitalizedLabel
