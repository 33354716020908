const BILLING = 'billing' as const
export function getBillingKey(): [typeof BILLING] {
	return [BILLING]
}

const TOTAL_DUE = 'total_due' as const
export function getTotalDueKey(): [typeof BILLING, typeof TOTAL_DUE] {
	return [...getBillingKey(), TOTAL_DUE]
}

const HISTORY = 'history' as const
export function getBillingHistoryKey(): [typeof BILLING, typeof HISTORY] {
	return [...getBillingKey(), HISTORY]
}

const USAGE = 'usage' as const
export function getUsageKey(): [typeof BILLING, typeof USAGE] {
	return [...getBillingKey(), USAGE]
}

const CURRENT_MONTH_USAGE = 'current_month_usage' as const
export function getCurrentMonthUsageKey(): [typeof BILLING, typeof CURRENT_MONTH_USAGE] {
	return [...getBillingKey(), CURRENT_MONTH_USAGE]
}

const UPCOMING_INVOICE = 'upcoming_invoice' as const
export function getUpcomingInvoiceKey(): [typeof BILLING, typeof UPCOMING_INVOICE] {
	return [...getBillingKey(), UPCOMING_INVOICE]
}

const FREE_CREDIT = 'free_credit' as const
export function getFreeCreditKey(): [typeof BILLING, typeof FREE_CREDIT] {
	return [...getBillingKey(), FREE_CREDIT]
}

const PAYMENTS = 'payments' as const
export function getPaymentsKey(): [typeof BILLING, typeof PAYMENTS]
export function getPaymentsKey(type: 'setup'): [typeof BILLING, typeof PAYMENTS, typeof type]
export function getPaymentsKey(type?: string) {
	if (!type) return [BILLING, PAYMENTS]
	return [BILLING, PAYMENTS, type]
}

const PAYMENT_METHODS = 'payment_methods' as const
export function getPaymentMethodsKey(): [typeof BILLING, typeof PAYMENT_METHODS] {
	return [BILLING, PAYMENT_METHODS]
}
