import IconButton from '@mui/material/IconButton'
import { Spinner } from '@twelvelabs/tds'
import { UploadStatus, useUploadTasks } from 'apis/tasks'
import clsx from 'clsx'
import Close from 'components/svg/Close'
import { createPortal } from 'react-dom'
import useIsMobile from 'utils/hooks/useIsMobile'
import { UploadProgressSnackbarMobile } from 'widgets/UploadProgressSnackbar'

interface Props {
	open: boolean
	onOpen: () => void
	onClose: () => void
}

const UploadingStatusButton = ({ open, onOpen, onClose }: Props) => {
	const isMobile = useIsMobile()
	const uploadTasksInProgressCount = useUploadTasks(
		(tasks) => tasks.filter((task) => [UploadStatus.Queued, UploadStatus.Uploading].includes(task.status)).length
	)

	return (
		<div className={clsx('flex items-center gap-x-3')}>
			{uploadTasksInProgressCount > 0 && (
				<>
					<Spinner size="sm" color="primary" />
					<button
						type="button"
						className={clsx('text-body2 text-grey-1000')}
						onClick={onOpen}
					>{`${uploadTasksInProgressCount} Uploading`}</button>
				</>
			)}
			{open && (
				<IconButton size="small" color="inherit" className={clsx('text-grey-900')} onClick={onClose}>
					<Close fontSize={24} />
				</IconButton>
			)}
			{isMobile && open && createPortal(<UploadProgressSnackbarMobile />, document.body)}
		</div>
	)
}

export default UploadingStatusButton
