const TASKS = 'tasks'

export function getTasksKey(): [typeof TASKS]
export function getTasksKey<Params>(params: Params): [typeof TASKS, Params]
export function getTasksKey(indexId: string): [typeof TASKS, string]
export function getTasksKey<Params>(indexId: string, params: Params): [typeof TASKS, string, Params]
export function getTasksKey(...params: unknown[]): unknown {
	return [TASKS, ...params]
}

export const getTaskKey = (taskId: string): [typeof TASKS, string] => [TASKS, taskId]

export function getTasksStatusKey(): [typeof TASKS, 'status']
export function getTasksStatusKey(indexId: string): [typeof TASKS, 'status', string]
export function getTasksStatusKey(indexId?: string) {
	if (!indexId) return [TASKS, 'status']
	return [TASKS, 'status', indexId]
}

export function getIndexIdsByStatusKey(): [typeof TASKS, 'index_id_by_status']
export function getIndexIdsByStatusKey<Params>(params: Params): [typeof TASKS, 'index_id_by_status', Params]
export function getIndexIdsByStatusKey<Params>(params?: Params) {
	if (params == null) return [TASKS, 'index_id_by_status']
	return [TASKS, 'index_id_by_status', params]
}
