import type { FileId, VideoType } from 'apis/tasks'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import LinkIcon from '@mui/icons-material/Link'
import { Button } from '@twelvelabs/tds'
import clsx from 'clsx'
import UploadVideoItem from 'components/UploadVideoItem'
import React from 'react'
import { pluralize } from 'utils/helpers'
import useVideoDropzoneState from 'utils/hooks/useVideoDropzoneState'

import DropzoneCenterText from './DropzoneCenterText'
import IconWrapper from './IconWrapper'

type Props = {
	className?: string
	onDrop: (files: Array<File>) => void
	onRemove: (id: FileId) => void
	videos: Array<VideoType>
}

const VideoDropzone = ({ className, onDrop, onRemove, videos }: Props): JSX.Element => {
	const {
		isDragAccept,
		getRootProps,
		getInputProps,
		isDragActive,
		open: openFileDialog,
		isHovering,
		setIsHovering,
		isOverMaxSize,
		isDragFailed,
		bgColors: [bgColor, bgBorderColor]
	} = useVideoDropzoneState({ onDrop, noClick: videos.length > 0 })

	return (
		<div
			className={clsx(className, 'overflow-auto', 'flex items-stretch')}
			style={{
				// Native CSS properties don't support the customization of border-style.
				// Therefore, we use a trick with an SVG image inside background-image property.
				// Reference: https://kovart.github.io/dashed-border-generator/
				backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='${bgColor}' stroke='${bgBorderColor}' stroke-width='4' stroke-dasharray='4%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
			}}
			{...getRootProps()}
		>
			<input {...getInputProps()} />
			{videos.length > 0 && !isDragActive ? (
				<div className={clsx('w-full', 'px-4 pb-4 pt-2')}>
					<div className="flex items-center justify-between">
						<p className="text-body2 text-grey-600">{pluralize(videos.length, 'video')}</p>
						<Button size="sm" appearance="secondary" onClick={openFileDialog} className={clsx('!px-1')}>
							Browse files
						</Button>
					</div>
					<div className={clsx('flex flex-wrap gap-2', 'pb-3 pt-1')}>
						{videos.map((video) => (
							<UploadVideoItem key={video.id} video={video} onRemove={onRemove} />
						))}
					</div>
				</div>
			) : (
				<div
					className={clsx('w-full', 'flex flex-col items-center justify-center', 'hover:cursor-pointer', 'mt-6')}
					onMouseEnter={(): void => setIsHovering(true)}
					onMouseLeave={(): void => setIsHovering(false)}
				>
					<div className={clsx('flex gap-1', 'mb-2')}>
						<IconWrapper greenBg={isDragAccept || isHovering} redBg={isDragFailed}>
							<CreateNewFolderIcon />
						</IconWrapper>
						<IconWrapper className="relative bottom-6" greenBg={isDragAccept || isHovering} redBg={isDragFailed}>
							<CloudUploadIcon />
						</IconWrapper>
						<IconWrapper greenBg={isDragAccept || isHovering} redBg={isDragFailed}>
							<LinkIcon />
						</IconWrapper>
					</div>
					<DropzoneCenterText isOverMaxSize={isOverMaxSize} isDragAccept={isDragAccept} isDragFailed={isDragFailed} />
				</div>
			)}
		</div>
	)
}

export default VideoDropzone
