export function formatTime(
	seconds: number,
	options?: { floor?: 'h' | 'm'; digit?: boolean; trim?: boolean; placeholder?: string }
): string {
	let times: { value: number; unit: 'h' | 'm' | 's' }[] = [
		{ value: Math.floor(seconds / 3600), unit: 'h' },
		{ value: Math.floor((seconds % 3600) / 60), unit: 'm' },
		{ value: Math.floor(seconds % 60), unit: 's' }
	]

	if (options?.floor) {
		times = times.slice(0, options.floor === 'h' ? 1 : 2)
	}

	if (options?.digit) {
		return times.map(({ value }) => value.toString().padStart(2, '0')).join(':')
	}

	if (options?.trim) {
		times = times.filter((t) => t.value > 0)
		if (times[0]?.unit === 'h') {
			times = times.filter(({ unit }) => unit !== 's')
		}
		if (times.length === 0) {
			return options.placeholder || '0s'
		}
	}

	if (options?.placeholder && seconds < 1) {
		return options.placeholder
	}

	return times.map(({ value, unit }) => `${value}${unit}`).join(' ')
}

export function secondsToCeiledMinutes(seconds = 0): string {
	return `${Math.ceil(seconds / 60).toLocaleString('en-US')} min`
}
