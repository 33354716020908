import muiSvg from './muiSvg'

const Checked = muiSvg((props) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.6 1.6C2.04772 1.6 1.6 2.04771 1.6 2.6V13.4C1.6 13.9523 2.04772 14.4 2.6 14.4H13.4C13.9523 14.4 14.4 13.9523 14.4 13.4V2.6C14.4 2.04771 13.9523 1.6 13.4 1.6H2.6ZM6.8 11.2766L12.5426 5.97568L11.4574 4.8L6.8 9.09911L4.54262 7.01538L3.45738 8.19107L6.8 11.2766Z"
			fill="currentColor"
		/>
	</svg>
))

export default Checked
