import type { Stripe } from '@stripe/stripe-js'

import { loadStripe } from '@stripe/stripe-js'

let stripePromise: Promise<Stripe | null>
export const getStripe = (): Promise<Stripe | null> => {
	if (stripePromise == null) {
		stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISH_KEY)
	}
	return stripePromise
}
