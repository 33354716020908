import { CircularProgress, Drawer, drawerClasses, modalClasses } from '@mui/material'
import { Button } from '@twelvelabs/tds'
import { EventType, useEvents } from 'apis/events'
import { refetchIndexTaskData, useAddCompletedTask, useIndexIdsByStatus } from 'apis/tasks'
import clsx from 'clsx'
import { NAVBAR_HEIGHT } from 'constants/measurements'
import NotificationImportantIcon from 'public/icons/notification-important.svg'
import NotificationIcon from 'public/icons/notification.svg'
import { createPortal } from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'
import withBoundary from 'utils/hocs/withBoundary'
import useIsMobile from 'utils/hooks/useIsMobile'

import TaskStatusPanel from './TaskStatusPanel'

interface TaskStatusButtonProps {
	open: boolean
	onOpen: () => void
	onClose: () => void
}

const TaskStatusButton = ({ open, onOpen, onClose }: TaskStatusButtonProps) => {
	const isMobile = useIsMobile()
	const { data } = useIndexIdsByStatus({ statuses: ['validating', 'queued', 'pending', 'indexing'] })

	const addCompletedTask = useAddCompletedTask()
	useEvents(async (event) => {
		if (event.type !== EventType.INDEXING_STATUS) return
		addCompletedTask(event.data.index_id, {
			taskId: event.data.task_id,
			videoId: event.data.video_id,
			status: event.data.status
		})
		refetchIndexTaskData(event.data.index_id)
	})

	const totalCount = data?.count
		? data.count.validating + data.count.queued + data.count.pending + data.count.indexing
		: 0

	return (
		<>
			<Button
				size="sm"
				type="button"
				appearance="secondary"
				onClick={open ? onClose : onOpen}
				className={clsx(
					open ? '!text-secondary' : '!text-grey-1000 hover:!text-secondary',
					'hover:!bg-moss_green-50',
					'!min-w-0 !px-2',
					'rounded'
				)}
			>
				{totalCount > 0 ? (
					<>
						<div className={clsx('relative', 'justify-content flex')}>
							<CircularProgress size={30} />
							<NotificationImportantIcon
								className={clsx('absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2', 'h-5 w-5')}
							/>
						</div>
						<span>{totalCount} indexing</span>
					</>
				) : (
					<NotificationIcon />
				)}
			</Button>
			{isMobile ? (
				open &&
				createPortal(
					<RemoveScroll
						className={clsx('fixed inset-0 z-mobile-nav-menu', 'bg-white', 'overflow-y-auto')}
						style={{ top: NAVBAR_HEIGHT }}
					>
						<TaskStatusPanel />
					</RemoveScroll>,
					document.body
				)
			) : (
				<Drawer
					open={open}
					onClose={onClose}
					anchor="right"
					sx={{
						[`&.${drawerClasses.root}`]: {
							top: NAVBAR_HEIGHT
						},
						[`& .${modalClasses.backdrop}`]: {
							top: NAVBAR_HEIGHT
						},
						[`& .${drawerClasses.paper}`]: {
							top: NAVBAR_HEIGHT,
							bottom: 0,
							height: 'unset',
							width: 422
						}
					}}
				>
					<TaskStatusPanel />
				</Drawer>
			)}
		</>
	)
}

export default withBoundary(TaskStatusButton)
