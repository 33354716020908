import 'react-toastify/dist/ReactToastify.css'
import '@twelvelabs/tds/css'

import type { EmotionCache } from '@emotion/cache'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'

import { CacheProvider } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import GlobalErrorBoundary from 'components/GlobalErrorBoundary'
import theme from 'constants/theme'
import Head from 'next/head'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { RecoilRoot, RecoilEnv } from 'recoil'
import createEmotionCache from 'utils/createEmotionCache'
import { ViewportListener } from 'utils/hooks/useIsMobile'
import MixpanelWizard from 'utils/providers/MixpanelWizard'
import QueryProvider from 'utils/providers/QueryProvider'
import UnleashProvider from 'utils/providers/UnleashProvider'
import UserProvider from 'utils/providers/UserProvider'
import IndexTaskCreate from 'widgets/IndexTaskCreate'
import { AuthErrorInterceptorProvider } from 'widgets/InterceptorProvider'
import PageNavigation from 'widgets/PageNavigation'
import { DatadogWizard, IntercomWizard } from 'widgets/Wizard'

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false
const RecoilProvider = ({ children }: { children: React.ReactNode }): JSX.Element => <RecoilRoot>{children}</RecoilRoot>

const UIProvider = ({
	children,
	emotionCache
}: {
	children: React.ReactNode
	emotionCache: EmotionCache
}): JSX.Element => (
	<CacheProvider value={emotionCache}>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	</CacheProvider>
)

interface MyAppProps extends AppProps {
	emotionCache?: EmotionCache
}

const clientSideEmotionCache = createEmotionCache()
const MyApp: NextPage<MyAppProps> = ({ Component, emotionCache = clientSideEmotionCache, pageProps }: MyAppProps) => (
	<UIProvider emotionCache={emotionCache}>
		<GlobalErrorBoundary>
			<RecoilProvider>
				<QueryProvider>
					<UserProvider>
						<UnleashProvider>
							<ViewportListener>
								<AuthErrorInterceptorProvider>
									<Head>
										<meta
											name="viewport"
											content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
										/>
									</Head>
									<IntercomWizard />
									<MixpanelWizard />
									<DatadogWizard />
									<ToastContainer newestOnTop position="top-center" theme="light" />
									<IndexTaskCreate /> {/* globally used widget */}
									<PageNavigation>
										<Component {...pageProps} /> {/* components by pages */}
									</PageNavigation>
								</AuthErrorInterceptorProvider>
							</ViewportListener>
						</UnleashProvider>
					</UserProvider>
				</QueryProvider>
			</RecoilProvider>
		</GlobalErrorBoundary>
	</UIProvider>
)

export default MyApp
