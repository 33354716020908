import type { ClassifyQueryParam } from 'widgets/Classify'
import type { GenerateQueryParam } from 'widgets/Generate'
import type { IndexSectionQueryParam } from 'widgets/IndexSection'
import type { SimpleSearchQueryParam } from 'widgets/SimpleSearch'

const INDEXES = '/indexes'

export enum Play {
	SEARCH = 'search',
	CLASSIFY = 'classify',
	GENERATE = 'generate'
}

function pathWithQuery(path: string, params?: Record<string, string | string[]>): string {
	if (params) {
		const paramValuePairs: Array<[string, string]> = []

		Object.keys(params).forEach((param) => {
			const value = params[param]
			/* Address duplicate search parameters ex. ["so", "logo"], ["so", "text_in_video"] */
			if (Array.isArray(value)) {
				;(value as string[]).forEach((v) => {
					paramValuePairs.push([param, v])
				})
			} else if (typeof value === 'string') {
				paramValuePairs.push([param, value as string])
			}
		})

		return `${path}?${new URLSearchParams(paramValuePairs).toString()}`
	}

	return path
}

export const pathName = {
	OVERVIEW: '/',
	indexes(params?: Partial<IndexSectionQueryParam>): string {
		return pathWithQuery(INDEXES, params)
	},
	EXAMPLES: '/examples',
	CLASS_GROUPS: '/class-groups',
	index: (indexId?: string): string => (indexId ? `${INDEXES}/${indexId}` : INDEXES),
	search: (indexId?: string, params?: Partial<SimpleSearchQueryParam>): string =>
		indexId ? pathWithQuery(`${INDEXES}/${indexId}/${Play.SEARCH}`, params) : INDEXES,
	classify: (indexId?: string, params?: Partial<ClassifyQueryParam>): string =>
		indexId ? pathWithQuery(`${INDEXES}/${indexId}/${Play.CLASSIFY}`, params) : INDEXES,
	generate: (indexId?: string, params?: Partial<GenerateQueryParam>): string =>
		indexId ? pathWithQuery(`${INDEXES}/${indexId}/${Play.GENERATE}`, params) : INDEXES
}

export enum PathParamKey {
	INDEX_ID = 'index_id'
}

export type PathParams = {
	[PathParamKey.INDEX_ID]: string
}
