import type { DialogProps as MUIDialogProps } from '@mui/material/Dialog'
import type { ButtonProps } from '@twelvelabs/tds'

import MUIDialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Button } from '@twelvelabs/tds'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { capitalize } from 'utils/formatString'

export interface DialogProps extends Omit<MUIDialogProps, 'title' | 'onSubmit'> {
	action: { label: string } & Omit<ButtonProps, 'children'>
	closeText?: string
	title?: JSX.Element | string
	onSubmit?: React.FormEventHandler<HTMLFormElement>
}

const Dialog = ({
	action: { label: actionLabel, appearance = 'primary', ...actionProps },
	closeText = 'Cancel',
	title,
	children,
	onClose,
	onSubmit,
	...dialogProps
}: DialogProps): JSX.Element => {
	const dialogContent = useMemo(
		() => (
			<>
				<DialogTitle {...(React.isValidElement(title) && { component: 'div' })}>{title}</DialogTitle>
				<DialogContent className={clsx('!pb-2')}>{children}</DialogContent>
				<DialogActions className={clsx('!pt-4')}>
					{onClose && (
						<Button
							appearance="subtle"
							className={clsx('mr-auto')}
							onClick={(): void => onClose({ name: 'CancelButton' }, 'backdropClick')}
							type="button"
						>
							{closeText}
						</Button>
					)}
					<Button appearance={appearance} type={onSubmit ? 'submit' : 'button'} {...actionProps}>
						{capitalize(actionLabel)}
					</Button>
				</DialogActions>
			</>
		),
		[actionLabel, actionProps, appearance, children, closeText, onClose, onSubmit, title]
	)

	return (
		<MUIDialog onClose={onClose} {...dialogProps}>
			{onSubmit ? <form onSubmit={onSubmit}>{dialogContent}</form> : dialogContent}
		</MUIDialog>
	)
}

export default Dialog
