import type { InterceptorProviderProps } from '.'
import type { TwelveLabsApiError } from 'utils/response'

import axios from 'axios'
import { clsx } from 'clsx'
import { StatusCodes } from 'http-status-codes'
import { useState } from 'react'
import { useMount } from 'react-use'
import Navbar from 'widgets/Navbar'

import { UnauthorizedErrorPage } from './ErrorPage'

const ErrorPageLayout = ({ children }: { children: React.ReactNode }) => (
	<div className={clsx('fixed inset-0 z-10')}>
		<Navbar hideTabs disableFetch />
		{children}
	</div>
)

const AuthErrorInterceptorProvider = ({ children }: Omit<InterceptorProviderProps, 'instances'>) => {
	const [unauthorizedError, setUnauthorizedError] = useState<TwelveLabsApiError>()

	useMount(() => {
		const onRejectResponseInterceptor = async (err: TwelveLabsApiError): Promise<Error> => {
			if (err.isAxiosError && err.response?.status === StatusCodes.UNAUTHORIZED) setUnauthorizedError(err)
			return Promise.reject(err)
		}

		axios.interceptors.response.use(undefined, onRejectResponseInterceptor)
	})

	if (unauthorizedError != null) {
		return (
			<ErrorPageLayout>
				<UnauthorizedErrorPage />
			</ErrorPageLayout>
		)
	}

	return children
}

export default AuthErrorInterceptorProvider
