import UploadIcon from '@mui/icons-material/Upload'
import Skeleton from '@mui/material/Skeleton'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Button } from '@twelvelabs/tds'
import { indexOptions, isMarengo, isPegasus } from 'apis/indexes'
import clsx from 'clsx'
import IndexPlaySelect from 'components/IndexPlaySelect'
import { INDEX_CLASSIFY_BUTTON, INDEX_SEARCH_BUTTON } from 'constants/elementIds'
import { CLASSIFY_DURATION_LIMIT } from 'constants/env'
import { pathName } from 'constants/paths'
import mixpanel from 'mixpanel-browser'
import React from 'react'
import withBoundary from 'utils/hocs/withBoundary'
import useIsMobile from 'utils/hooks/useIsMobile'
import useRouter from 'utils/hooks/useRouter'

import { getCanUpload } from './utils'

interface PlayNavButtonsProps {
	indexId: string
	className?: string
	readonly?: boolean
	hasVideo?: boolean
	onOpenUploadDialog: (e: React.MouseEvent<HTMLButtonElement>) => void
	uploadTriggerType?: 'text' | 'button'
}

function stopPropagation(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
	e.stopPropagation()
}

const PlayNavButtons = ({
	indexId,
	readonly: readonlyProp,
	hasVideo: hasVideoProp,
	onOpenUploadDialog,
	className,
	uploadTriggerType = 'button'
}: Omit<PlayNavButtonsProps, 'isExpired'>): JSX.Element => {
	const { data: index } = useSuspenseQuery(indexOptions(indexId))
	const isMobile = useIsMobile()
	const isClassifiable = index.total_duration <= CLASSIFY_DURATION_LIMIT && !isMobile
	const readonly = readonlyProp || Boolean(index.sample)

	const hasVideo = hasVideoProp ?? index.video_count > 0
	const canIndexSearch = Boolean(index.engines.some(isMarengo))
	const canIndexGenerate = Boolean(index.engines.some(isPegasus))
	const canUpload = getCanUpload(index.engines)

	const router = useRouter()

	const moveToSearch = () => {
		const path = pathName.search(indexId)
		mixpanel.track('click', { type: 'link', url: path, value: index })
		router.push({ pathname: path })
	}
	const moveToClassify = () => {
		const path = pathName.classify(indexId)
		mixpanel.track('click', { type: 'link', url: path, value: index })
		router.push({ pathname: path })
	}
	const moveToGenerate = () => {
		const path = pathName.generate(indexId)
		mixpanel.track('click', { type: 'link', url: path, value: index })
		router.push({ pathname: path })
	}

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
		<div className={className} onClick={stopPropagation}>
			{hasVideo && (
				<>
					{canIndexSearch && canIndexGenerate && (
						<IndexPlaySelect indexId={indexId} label="Select action" button={<Button appearance="primary" />} />
					)}
					{canIndexSearch && !canIndexGenerate && (
						<div className="flex gap-2">
							<Button
								appearance="primary"
								className={clsx('flex-1')}
								onClick={moveToSearch}
								data-intercom-target={INDEX_SEARCH_BUTTON}
							>
								Search
							</Button>
							{isClassifiable && (
								<Button
									appearance="primary"
									className={clsx('flex-1')}
									onClick={moveToClassify}
									data-intercom-target={INDEX_CLASSIFY_BUTTON}
								>
									Classify
								</Button>
							)}
						</div>
					)}
					{!canIndexSearch && canIndexGenerate && (
						<Button appearance="primary" onClick={moveToGenerate}>
							Generate
						</Button>
					)}
				</>
			)}
			{!readonly && (
				<>
					{uploadTriggerType === 'button' && (
						<Button disabled={!canUpload} type="button" onClick={onOpenUploadDialog}>
							Upload videos
							<UploadIcon color="inherit" />
						</Button>
					)}
					{uploadTriggerType === 'text' && (
						<button
							className={clsx(
								'w-full',
								'text-subtitle3 text-secondary hover:text-moss_green-900',
								!canUpload && 'hidden'
							)}
							type="button"
							onClick={onOpenUploadDialog}
						>
							Upload more
						</button>
					)}
				</>
			)}
		</div>
	)
}

export default withBoundary(
	PlayNavButtons,
	{},
	{ fallbackRender: ({ className }) => <Skeleton variant="rectangular" className={clsx(className, 'w-30 h-9')} /> }
)
