import type { UploadingTask } from '../types'
import type { TwelveLabsApiError } from 'utils/response'

import isEqual from 'lodash/isEqual'
import { getTLApiErrorData } from 'utils/error'
import { TaskErrorCode } from 'utils/response'

import tasksStore from './store'
import { selectQueuedTasks } from './utils'
import { createTask } from '../handler'
import { UploadStatus } from '../types'
import { addCountToTaskStatus, addDataToIndexIdsByStatus, refetchIndexTaskData } from '../utils'

const { updateUploadTasks } = tasksStore.getState()

async function updateIndexTaskData(task: UploadingTask) {
	/**
	 * NOTE: Manual update for these data is required for several reasons:
	 * - Retrieving the data from the server every time could cause performance issues
	 * - The data is not updated in real-time
	 */
	addDataToIndexIdsByStatus(task.indexId, 'validating')
	addCountToTaskStatus(task.indexId, 'validating')
	await refetchIndexTaskData(task.indexId, ['index', 'tasks'])
}

tasksStore.subscribe(
	(state) =>
		selectQueuedTasks(state, { concurrencyNumberLimit: 4, concurrencyFileSizeLimit: 1024 * 1024 * 1024 /* 1GB */ }),
	async (queuedTasks) => {
		const fileIds = queuedTasks.map(({ id }) => id)
		updateUploadTasks(fileIds.map((id) => ({ fileId: id, status: UploadStatus.Uploading })))

		await Promise.all(
			fileIds
				.map((id) => tasksStore.getState().uploadTasks.find((task) => task.id === id))
				.filter((task): task is UploadingTask => task?.status === UploadStatus.Uploading)
				.map(async (task) => {
					try {
						const { data } = await createTask(task, ({ loaded, total }) =>
							updateUploadTasks([{ fileId: task.id, progress: Math.round((loaded / total) * 100) }])
						)

						await updateIndexTaskData(task)
						updateUploadTasks([{ fileId: task.id, status: UploadStatus.Done, taskId: data._id }])

						return await Promise.resolve()
					} catch (error) {
						if (error instanceof Error) {
							if (getTLApiErrorData<TaskErrorCode>({ error }).code === TaskErrorCode.UsageLimitExceeded) {
								tasksStore.setState({ isUsageAlertDialogOpen: true })

								const uploadingTasks = tasksStore
									.getState()
									.uploadTasks.filter(({ status }) => status !== UploadStatus.Failed)
								updateUploadTasks(
									uploadingTasks.map((item) => ({
										fileId: item.id,
										status: UploadStatus.Failed,
										error: error as TwelveLabsApiError<TaskErrorCode>
									}))
								)

								return Promise.reject(error)
							}

							updateUploadTasks([{ fileId: task.id, status: UploadStatus.Failed, error }])
						}

						return Promise.resolve()
					}
				})
		)
	},
	{
		equalityFn: (prev, next) => isEqual(prev.map((task) => task.id).sort(), next.map((task) => task.id).sort())
	}
)
