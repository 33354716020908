import muiSvg from './muiSvg'

const Thumbnail = muiSvg(
	(props): JSX.Element => (
		<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H6ZM19 7C19 8.10457 18.1046 9 17 9C15.8954 9 15 8.10457 15 7C15 5.89543 15.8954 5 17 5C18.1046 5 19 5.89543 19 7ZM11.4297 10.4888C11.0421 9.83706 10.0984 9.83706 9.71076 10.4888L6.14205 16.4892C5.7456 17.1558 6.22596 18.0004 7.00153 18.0004H11.9472L11.9499 18.0004H16.9967C17.7759 18.0004 18.2558 17.1486 17.8519 16.4821L15.477 12.5628C15.0998 11.9404 14.2054 11.9164 13.7955 12.5177L13.1765 13.4258L11.4297 10.4888Z"
				fill="currentColor"
			/>
		</svg>
	)
)

export default Thumbnail
