import { fromUnixTime, formatDistance } from 'date-fns'
import { StatusCodes } from 'http-status-codes'

import { isTLApiError } from './error'

export const getRateLimitMessage = (resetDate: Date | undefined) =>
	`You have exceeded the rate limit. Please try again ${
		resetDate ? `${formatDistance(resetDate, new Date(), { includeSeconds: true, addSuffix: true })}.` : 'later.'
	}`

export const checkRateLimitExceeded = <EC>(error: Error) => {
	if (isTLApiError<EC>(error) && error.response?.status === StatusCodes.TOO_MANY_REQUESTS) {
		const rateLimitResetUnix = Number(error.response.headers['x-ratelimit-reset'] || NaN)
		const rateLimitResetDate = Number.isNaN(rateLimitResetUnix) ? undefined : fromUnixTime(rateLimitResetUnix)

		return {
			rateLimitExceeded: true,
			rateLimitResetDate,
			rateLimitMessage: getRateLimitMessage(rateLimitResetDate)
		}
	}

	return { rateLimitExceeded: false }
}
