import muiSvg from './muiSvg'

const Conversation = muiSvg(
	(props): JSX.Element => (
		<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21 15.6667C21.6612 14.3577 22.0038 12.911 22 11.4445V10.8889C21.8722 8.57297 20.8947 6.38553 19.2546 4.74543C17.6145 3.10532 15.427 2.1278 13.1111 2.00003H12.5555C11.089 1.99621 9.64234 2.33884 8.33332 3.00003C6.76472 3.78399 5.44536 4.98919 4.52302 6.48064C3.60068 7.97208 3.11179 9.69086 3.11111 11.4445C3.10729 12.911 3.44992 14.3577 4.11111 15.6667L2 22L8.33332 19.8889C9.64234 20.5501 11.089 20.8927 12.5555 20.8889C14.3091 20.8882 16.0279 20.3993 17.5194 19.477C19.0108 18.5546 20.216 17.2353 21 15.6667ZM7.9375 13.5625C7.41973 13.5625 7 13.9823 7 14.5C7 15.0178 7.41973 15.4375 7.9375 15.4375H14.5C15.0178 15.4375 15.4375 15.0178 15.4375 14.5C15.4375 13.9823 15.0178 13.5625 14.5 13.5625H7.9375ZM7 11.375C7 10.8573 7.41973 10.4375 7.9375 10.4375H17.3125C17.8303 10.4375 18.25 10.8573 18.25 11.375C18.25 11.8928 17.8303 12.3125 17.3125 12.3125H7.9375C7.41973 12.3125 7 11.8928 7 11.375ZM7.9375 7.31255C7.41973 7.31255 7 7.73228 7 8.25005C7 8.76782 7.41973 9.18755 7.9375 9.18755H17.3125C17.8303 9.18755 18.25 8.76782 18.25 8.25005C18.25 7.73228 17.8303 7.31255 17.3125 7.31255H7.9375Z"
				fill="currentColor"
			/>
		</svg>
	)
)

export default Conversation
