import './font-awesome-color-brand.css'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { UserCardInfo } from 'apis/billing'

import {
	faCcAmex,
	faCcDinersClub,
	faCcDiscover,
	faCcJcb,
	faCcMastercard,
	faCcStripe,
	faCcVisa
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { useMemo } from 'react'

const CardDisplay = ({ last4, brand }: Pick<UserCardInfo, 'brand' | 'last4'>): JSX.Element => {
	const brandMapping = useMemo((): IconProp => {
		switch (brand) {
			case 'amex':
				return faCcAmex
			case 'visa':
				return faCcVisa
			case 'discover':
				return faCcDiscover
			case 'mastercard':
				return faCcMastercard
			case 'jcb':
				return faCcJcb
			case 'diners':
				return faCcDinersClub
			default:
				return faCcStripe
		}
	}, [brand])

	const faClassMapping = useMemo(() => {
		switch (brand) {
			case 'amex':
				return 'american-express'
			case 'visa':
				return 'visa'
			case 'discover':
				return 'discover'
			case 'mastercard':
				return 'mastercard'
			case 'jcb':
				return 'jcb'
			case 'diners':
				return 'diners-club'
			default:
				return 'stripe'
		}
	}, [brand])

	return (
		<div className={clsx('flex items-center gap-x-2', 'w-max')}>
			<FontAwesomeIcon size="1x" icon={brandMapping} className={clsx(faClassMapping)} />
			<p className={clsx('text-body1 !text-grey-1000')}>{`${brand.toUpperCase()} **** **** **** ${last4.padStart(
				4
			)}`}</p>
		</div>
	)
}

export default CardDisplay
