import type { Claims } from '@auth0/nextjs-auth0'
import type { UserMetadata } from 'utils/auth'

export const getUserMetadata = (user: Claims) => user[process.env.NEXT_PUBLIC_AUTH0_CLAIMS_METADATA] as UserMetadata

export const isUserOnBoarded = (user: Claims | undefined): boolean => {
	if (!user) return false

	const userMetadata = getUserMetadata(user)

	return userMetadata.onboarded ?? false
}
