import Intercom, { update } from '@intercom/messenger-js-sdk'
import { useEffect } from 'react'
import useAuth0 from 'utils/hooks/useAuth0'
import useMount from 'utils/hooks/useMount'

const IntercomWizard = (): null => {
	const { user } = useAuth0()

	useMount(() => {
		Intercom({
			api_base: 'https://api-iam.intercom.io',
			app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
			hide_default_launcher: true
		})
	})

	useEffect(() => {
		if (!user?.name || !user?.email) return
		update({
			name: user.name, // Full name
			email: user.email // Email address
		})
	}, [user?.email, user?.name])

	return null
}

export default IntercomWizard
