import type { IndexResponse } from 'apis/indexes'
import type { Sample } from 'utils/response'

import { MenuItem, type MenuItemProps } from '@mui/material'
import clsx from 'clsx'
import Alert from 'components/Alert'
import React from 'react'
import { formatTime } from 'utils/formatTime'
import { pluralize } from 'utils/helpers'

interface Props extends MenuItemProps, Sample {
	index: IndexResponse
	isExpired?: boolean
}

const IndexMenuItem = ({ index, sample, isExpired, className, ...menuItemProps }: Props): JSX.Element => (
	<MenuItem className={clsx(className, 'flex-col items-start')} {...menuItemProps}>
		<div className={clsx('flex items-center gap-x-1', 'w-full')}>
			<p className="truncate text-body2 !text-grey-1000">{index.index_name}</p>
			{sample && <Alert icon={null} title="Sample" size="small" />}
			{isExpired && <Alert variant="warning" title="Expired" size="small" />}
		</div>
		<p className="text-body3">
			{pluralize(index.video_count, 'video')} (Total {formatTime(index.total_duration, { trim: true })})
		</p>
	</MenuItem>
)

export default IndexMenuItem
