import { EngineOption, type IndexResponse, isPegasus, Engine } from 'apis/indexes'
import { type VideoType } from 'apis/tasks'

export const getVideoFileLimits = (index: IndexResponse | undefined) => {
	const conversationEnabled = index?.engines.some((engine) =>
		engine?.engine_options.includes(EngineOption.conversation)
	)

	const pegasusEngine = index?.engines.find(isPegasus)
	const getMaxDuration = () => {
		if (pegasusEngine?.engine_name === Engine.PEGASUS_1) return '20min'
		if (pegasusEngine?.engine_name === Engine.PEGASUS_1_1) return '30min'
		return '2hr'
	}

	return [
		[
			'Duration',
			`4sec-${getMaxDuration()}`,
			pegasusEngine != null &&
				'This limit applies to indexes containing the Pegasus engine. For videos up to 2 hours, create an index selecting only the Marengo engine.'
		],
		['Audio', conversationEnabled ? 'Required' : 'Not required'],
		['Resolution', '360p-4k'],
		['File size', '≤2GB per video']
	] as const
}

export const getVideoDurationByFile = (file: File): Promise<number | null> => {
	const video = document.createElement('video')
	video.preload = 'metadata'
	video.src = URL.createObjectURL(file)

	return new Promise((resolve) => {
		video.onloadedmetadata = () => {
			resolve(video.duration)
			URL.revokeObjectURL(video.src)
		}
		video.onerror = () => {
			resolve(null)
			console.error('Failed to get video duration', file)
		}
	})
}

// Returns the total duration of the given videos in human-friendly format.
export const getVideosTotalDuration = (videos: VideoType[]): [number, string] => {
	const durationInSec = Math.ceil(videos.reduce((acc, curr) => acc + (curr.duration || 0), 0))

	const min = Math.floor(durationInSec / 60)
	const sec = durationInSec % 60

	if (min === 0) {
		return [durationInSec, `${sec}sec`]
	}

	return [durationInSec, `${new Intl.NumberFormat().format(min)}min ${sec}sec`]
}
