import type { SingleTaskResponse } from './options'
import type { TasksStatusParams, IndexIdsByStatusResponse } from './types'
import type { TwelveLabsApiError } from 'utils/response'

import { useMutation, useQuery } from '@tanstack/react-query'
import { ApiType, getInstance } from 'utils/network'

import { getIndexIdsByStatusKey } from './keys'
import { useClearCompletedTasks } from './store'
import { refetchIndexTaskData } from './utils'

export function useGetTask<Response extends SingleTaskResponse, TaskId extends string>() {
	return useMutation<Response, TwelveLabsApiError, TaskId>({
		mutationFn: (taskId: TaskId) =>
			getInstance(ApiType.TWELVE_LABS)
				.get<Response>(`/tasks/${taskId}`)
				.then((res) => res.data)
	})
}

export function useIndexIdsByStatus(params?: TasksStatusParams) {
	return useQuery({
		queryKey: getIndexIdsByStatusKey(params),
		queryFn: () =>
			getInstance(ApiType.PLAYGROUND).get<IndexIdsByStatusResponse>(
				`/tasks/status?${params?.statuses ? `statuses=${params.statuses.join(',')}` : ''}`
			),
		select: ({ data }) => data
	})
}

export function useDeleteTask(indexId: string) {
	const clearCreatedTasks = useClearCompletedTasks()

	return useMutation<{ taskId: string }, TwelveLabsApiError, string>({
		mutationFn: (taskId: string) =>
			getInstance(ApiType.TWELVE_LABS)
				.delete<undefined>(`/tasks/${taskId}`)
				.then(() => ({ taskId })),
		onSuccess: async ({ taskId }) => {
			clearCreatedTasks(indexId, taskId)
			await refetchIndexTaskData(indexId)
		}
	})
}
