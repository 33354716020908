import { isFileType, type FileId, type VideoType, type VideoUrlType } from 'apis/tasks'
import produce from 'immer'
import { nanoid } from 'nanoid'
import { useState } from 'react'

const getVideoDurationByFile = (file: File): Promise<number | null> => {
	const video = document.createElement('video')
	video.preload = 'metadata'
	video.src = URL.createObjectURL(file)

	return new Promise((resolve) => {
		video.onloadedmetadata = () => {
			resolve(video.duration)
			URL.revokeObjectURL(video.src)
		}
		video.onerror = () => {
			resolve(null)
			console.error('Failed to get video duration', file)
		}
	})
}

const useVideosToUpload = () => {
	const [videos, setVideos] = useState<Array<VideoType>>([])

	const onVideoFilesAdded = async (acceptedFiles: Array<File>) => {
		const newFiles: Array<VideoType> = await Promise.all(
			acceptedFiles.map(async (file) => {
				const duration = await getVideoDurationByFile(file)

				return {
					id: nanoid() as FileId,
					file,
					blobUrl: URL.createObjectURL(file),
					duration
				}
			})
		)

		setVideos((prev) => newFiles.concat(prev))
	}

	const onVideoUrlAdded = (data: Omit<VideoUrlType, 'id'>): void => {
		setVideos((prev) => [{ id: nanoid() as FileId, ...data }, ...prev])
	}

	const onVideoRemoved = (id: FileId): void => {
		setVideos(
			produce(videos, (draft) => {
				const idx = draft.findIndex((video) => video.id === id)
				const video = videos[idx]
				if (isFileType(video)) URL.revokeObjectURL(video.blobUrl)
				draft.splice(idx, 1)
			})
		)
	}

	const cleanUpVideos = () => {
		// clean up resources before emptying the video list
		videos.forEach((v) => {
			if (isFileType(v)) URL.revokeObjectURL(v.blobUrl)
		})
		setVideos([])
	}

	return {
		videos,
		onVideoFilesAdded,
		onVideoUrlAdded,
		onVideoRemoved,
		cleanUpVideos
	}
}

export default useVideosToUpload
