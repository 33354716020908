import CircularProgress from '@mui/material/CircularProgress'
import { Spinner } from '@twelvelabs/tds'
import { clsx } from 'clsx'

import CapitalizedLabel from './CapitalizedLabel'

const UploadProgress = ({ value, className }: { value?: number | string; className?: string }) => (
	<div className={clsx('h-5 pr-0.5', 'flex items-center', className)}>
		{value != null && (
			<CapitalizedLabel>
				{value}
				{typeof value === 'number' ? '%' : ''}
			</CapitalizedLabel>
		)}
		{typeof value === 'number' ? (
			<CircularProgress size={20} variant="determinate" value={value} />
		) : (
			<Spinner size="sm" color="primary" />
		)}
	</div>
)

export default UploadProgress
