import { createStore, useStore } from 'zustand'

export enum NavbarMenuType {
	TaskStatus = 'taskStatus',
	Menu = 'menu',
	UserInfo = 'userInfo'
}

interface NavbarStore {
	menu: NavbarMenuType | undefined
	open: (menu: NavbarMenuType) => void
	close: () => void
}

const navbarStore = createStore<NavbarStore>()((set) => ({
	menu: undefined,
	open: (menu) => set({ menu }),
	close: () => set({ menu: undefined })
}))

export const useNavbarStore = () => useStore(navbarStore)

export const useOpenNavbarMenu = () => useStore(navbarStore, (state) => state.open)

export const useCloseNavbarMenu = () => useStore(navbarStore, (state) => state.close)
