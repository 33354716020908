import type { IndexSectionProps } from '../IndexSection'

import { useSuspenseInfiniteQuery } from '@tanstack/react-query'
import { infiniteIndexesOptions } from 'apis/indexes'
import { clsx } from 'clsx'
import { useInView } from 'react-intersection-observer'
import withBoundary from 'utils/hocs/withBoundary'

import IndexCard, { IndexCardSkeleton } from './IndexCard'
import IndexCreateButton from './IndexCreateButton'

const IndexGrid = ({
	limit = 12,
	sample,
	showInfinite
}: Pick<IndexSectionProps, 'limit' | 'showInfinite'> & { sample?: boolean }) => {
	const {
		data: indexes,
		hasNextPage,
		fetchNextPage
	} = useSuspenseInfiniteQuery({
		...infiniteIndexesOptions(
			{ page_limit: !showInfinite && !sample ? limit - 1 : limit, sort_by: 'created_at' },
			sample
		),
		select: ({ pages }) => pages.flatMap(({ data }) => data.data.map((item) => ({ ...item, sample })))
	})

	const { ref } = useInView({ rootMargin: '200px', onChange: (inView) => inView && fetchNextPage() })

	return (
		<div className={clsx('grid grid-cols-1 tablet:grid-cols-3', 'gap-5')}>
			{!sample && <IndexCreateButton />}
			{indexes.map((item) => (
				<IndexCard
					id={item._id}
					key={item._id}
					name={item.index_name}
					engines={item.engines}
					createdAt={item.created_at}
					isExpired={item.isExpired}
					sample={item.sample}
					totalDuration={item.total_duration}
				/>
			))}
			{showInfinite && hasNextPage && <div ref={ref} />}
		</div>
	)
}

export default withBoundary(
	IndexGrid,
	{},
	{
		fallbackRender: ({ sample }) => (
			<div className={clsx('grid grid-cols-1 tablet:grid-cols-3', 'gap-5')}>
				{!sample && <IndexCreateButton />}
				{Array.from(Array(sample ? 2 : 3).keys()).map((v) => (
					<IndexCardSkeleton key={v} />
				))}
			</div>
		)
	}
)
