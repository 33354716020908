import type { AlertProps } from './Alert'

import { useQuery } from '@tanstack/react-query'
import { indexOptions } from 'apis/indexes'
import { formatDistance, isAfter, subDays } from 'date-fns'
import { memo } from 'react'

import Alert from './Alert'

interface Props extends Omit<AlertProps, 'title'> {
	indexId: string
	warningStartDay?: number
}

const ExpirationAlert = ({ indexId, warningStartDay = 30, ...props }: Props): JSX.Element | null => {
	const { data: index } = useQuery(indexOptions(indexId))

	if (index == null || index.expires_at == null) return null

	const now = new Date()
	const expirationDate = new Date(index.expires_at)
	if (isAfter(subDays(expirationDate, warningStartDay), now)) return null

	return (
		<Alert
			variant="warning"
			title={index.isExpired ? 'Expired' : `Expires in ${formatDistance(expirationDate, now)}`}
			sx={{
				whiteSpace: 'nowrap'
			}}
			{...props}
		/>
	)
}

export default memo(ExpirationAlert)
