'use client'

import { useUser } from '@auth0/nextjs-auth0/client'
import { FlagProvider, useUnleashClient } from '@unleash/nextjs/client'
import { useEffect } from 'react'

const UpdateUnleashContextWizard = () => {
	const { user } = useUser()
	const unleash = useUnleashClient()

	useEffect(() => {
		if (!user || !user.email) return

		unleash.updateContext({ userId: user.email })
	}, [unleash, user])

	return null
}

const UnleashProvider = ({ children }: { children: JSX.Element }) => (
	<FlagProvider config={{ appName: 'playground', disableRefresh: true }}>
		<UpdateUnleashContextWizard />
		{children}
	</FlagProvider>
)

export default UnleashProvider
