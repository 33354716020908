import type { AxiosError, AxiosResponse } from 'axios'
import type { UseCommonQueryOptions } from 'utils/response'

import { getIsCardSavedKey } from './keys'
import { ApiType, getInstance } from 'utils/network'

// eslint-disable-next-line import/prefer-default-export
export function isCardSavedOptions<Response extends { has_card_info: boolean }>(): UseCommonQueryOptions<
	AxiosResponse<Response>,
	AxiosError,
	boolean,
	ReturnType<typeof getIsCardSavedKey>
> {
	return {
		queryKey: getIsCardSavedKey(),
		queryFn: () => getInstance(ApiType.INTERNAL).get<Response>('/user/is-card-saved'),
		select: ({ data }) => data.has_card_info
	}
}
