import * as yup from 'yup'

export const cardValidationSchema = yup.object().shape({
	cardHolderName: yup.string().required('Card holder name is required'),
	address: yup.object({
		country: yup.string().required('Country is required')
	})
})

export type CardValidation = yup.InferType<typeof cardValidationSchema>
