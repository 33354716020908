import { yupResolver } from '@hookform/resolvers/yup'
import { Engine, EngineOption } from 'apis/indexes'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const MAX_LENGTH = 100
const latestMarengo = Engine.MARENGO_2_6
const latestPegasus = Engine.PEGASUS_1_1

const engineOptionsSchema = yup
	.object()
	.shape({
		visual: yup.boolean().required(),
		conversation: yup.boolean().required(),
		text_in_video: yup.boolean(),
		logo: yup.boolean()
	})
	.test('is-at-least-one-option-selected', 'Please select at least one option', (obj, context) => {
		// Only validate when engine is selected
		if (!context.parent.selected) return true

		const isNonSelected = Object.values(obj).every((field) => field === false)
		return !isNonSelected
	})

const indexCreateParamsSchema = yup.object().shape({
	index_name: yup
		.string()
		.max(MAX_LENGTH, `The number of letters is limited to ${MAX_LENGTH}`)
		.required('Please enter name of the index'),
	marengo: yup.object().shape({
		selected: yup.boolean().required(),
		engine_name: yup.string().required(),
		engine_options: engineOptionsSchema
	}),
	pegasus: yup.object().shape({
		selected: yup.boolean().required(),
		engine_name: yup.string().required(),
		engine_options: engineOptionsSchema
	})
})

export type IndexCreateSchema = yup.InferType<typeof indexCreateParamsSchema>

/**
 *
 * Custom useForm hook from react-hook-form for creating index
 *
 * @returns UseFormReturn
 */
export const useIndexCreateForm = () => {
	const form = useForm<IndexCreateSchema>({
		mode: 'onChange',
		reValidateMode: 'onChange',
		resolver: yupResolver(indexCreateParamsSchema),
		defaultValues: {
			index_name: '',
			marengo: {
				selected: true,
				engine_name: latestMarengo,
				engine_options: {
					[EngineOption.visual]: true,
					[EngineOption.conversation]: true,
					[EngineOption.text_in_video]: false,
					[EngineOption.logo]: false
				}
			},
			pegasus: {
				selected: true,
				engine_name: latestPegasus,
				engine_options: {
					[EngineOption.visual]: true,
					[EngineOption.conversation]: true
				}
			}
		}
	})

	const marengoSelected = form.watch('marengo.selected')
	const pegasusSelected = form.watch('pegasus.selected')

	// .test validation can trigger errors but doesn't clear them
	// therefore clear the errors by manually revalidating
	useEffect(() => {
		form.trigger('marengo.engine_options')
		form.trigger('pegasus.engine_options')

		// Having .test validation at root can cause unexpected side effect
		// So set the error here
		if (!marengoSelected && !pegasusSelected) {
			form.setError('root', {
				message: 'Please select at least one engine'
			})
		} else {
			form.clearErrors('root')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [marengoSelected, pegasusSelected])

	return form
}
